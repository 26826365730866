import { Box, Typography } from "@mui/material";
import React from "react";
import { IconWithTextSimpleChipCmp } from "../../components/Chips";
import {
  AeroplaneIconSvg,
  CalendarIconCmp,
  GolfFlagIconCmp,
  LocationIconCmp,
} from "../../components/Icons";
import { ItineraryPageContext } from "./ItineraryPageContext";

export default function ItineraryBodyRightCmp() {
  const { retreat } = React.useContext(ItineraryPageContext);

  console.log(retreat);
  // const arr1 = [];
  // for (let i = 0; i < retreat.outdoor_activity_title.split("; ").length; i++) {
  //   arr1.push(i);
  // }

  // const arr2 = [];
  // for (let i = 0; i < retreat.indoor_activity_title.split("; ").length; i++) {
  //   arr2.push(i);
  // }

  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap={3}
      bgcolor="rgba(106, 133, 92, 0.04)"
      borderRadius={4}
      p={2}
    >
      <Box borderRadius={3} overflow="hidden" height={160}>
        <img
          src="https://static.independent.co.uk/2023/06/28/12/newFile-1.jpg"
          alt=""
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Box>
      <Typography variant="h5Alt" fontWeight={700} color="primary">
        Atlantic Surf Boutique Hotel
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        rowGap={1.5}
        p={2}
        bgcolor="secondary.main"
        borderRadius={4}
      >
        <IconWithTextSimpleChipCmp
          columnGap={1}
          icon={LocationIconCmp}
          label="Ericeira, Portugal"
        />
        <IconWithTextSimpleChipCmp columnGap={1} icon={GolfFlagIconCmp} label="Team Building" />
        <IconWithTextSimpleChipCmp columnGap={1} icon={CalendarIconCmp} label="Fri, 10 Apr 2024" />
        <IconWithTextSimpleChipCmp
          columnGap={1}
          icon={AeroplaneIconSvg}
          label="Lisbon Humberto Delgado (LIS)"
        />
      </Box>
      {/* <Box>
        <Typography fontWeight={500} color="primary">
          Outdoor
        </Typography>
        <Box display="flex" flexDirection="column" rowGap={2} mt={2}>
          {arr1.map((el) => {
            return (
              <IconWithTextSimpleChipCmp
                icon={CheckCircleIconCmp}
                label={retreat.outdoor_activity_title.split("; ")[el]}
                fontWeight={500}
                columnGap={1}
                textColor="text.main"
              />
            );
          })}
        </Box>
        <Box mt={3} />
        <Typography fontWeight={500} color="primary">
          Indoor
        </Typography>
        <Box display="flex" flexDirection="column" rowGap={2} mt={2}>
          {arr2.map((el) => {
            return (
              <IconWithTextSimpleChipCmp
                icon={CheckCircleIconCmp}
                label={retreat.indoor_activity_title.split("; ")[el]}
                fontWeight={500}
                columnGap={1}
                textColor="text.main"
              />
            );
          })}
        </Box>
      </Box> */}
    </Box>
  );
}
