import React from "react";
import { useParams } from "react-router-dom";
import { toolBoxCardData } from "../../temp/toolBoxDBTemp";

export const ToolBoxPageContext = React.createContext(null);

export default function ToolBoxPageContextProvider({ children }) {
  const params = useParams();
  const postId = Number(params.postId);
  // const [viewType, setViewType] = React.useState(itineraryViewEnum.list);

  const toolBox = toolBoxCardData.find((el) => el.id === postId);

  return (
    <ToolBoxPageContext.Provider
      value={{
        toolBox,
        //  viewType, setViewType
      }}
    >
      {children}
    </ToolBoxPageContext.Provider>
  );
}
