export const proposalDBTemp = [
  {
    id: 0,
    title: "Barcelona Retreat in the Spanish Countryside",
    desc: "This beautiful countryside retreat location is located 40 mins from Barcelona with a large garden, pool, and spa facilities.",
    images: [
      "https://assets.chateauform.com/medias/domain8909/media31/4789-17p4gn9b9j-whr.jpg",
      "https://assets.chateauform.com/medias/domain8909/media29/4540-pi6o5v0qft-whr.jpg",
      "https://assets.chateauform.com/medias/domain8909/media30/4645-ksdugd11vx-whr.jpg",
      "https://assets.chateauform.com/medias/domain8909/media31/4735-pin1usirjg-whr.jpg",
      "https://assets.chateauform.com/medias/domain8909/media31/4756-r6f2jp8hvw-whr.jpg",
      "https://assets.chateauform.com/medias/domain8909/media29/4501-6d0cbb1dvm-whr.jpg",
      "https://assets.chateauform.com/medias/domain8909/media31/4771-birveo7u9u-whr.jpg",
      "https://assets.chateauform.com/medias/domain8909/media30/4546-e7wyn1e1vm-whr.jpg",
      "https://assets.chateauform.com/medias/domain8909/media29/4513-gc7opqjau4-whr.jpg",
    ],
    Retreat_location: "Countryside location",
    Distance_from_airport: "40 mins (BCN)",
    Rooms_and_occupancy: "180 rooms",
    dates: "",
    What_you_will_love: [
      "Large collaborative workspaces",
      "Gala dinner in garden setup",
      "Seasonal food & beverage options",
    ],
    Sustainability_criteria: [
      "Renewable energy installations",
      "Sustainable sourcing of food",
      "On site recycling processes",
    ],
    food_and_beverage_desc:
      "The location offers around the clock food & beverage service with seasonal and healthy food options designed by a local chef.",
    food: [
      {
        type: "Breakfast Buffet",
        items: [
          "Continental buffet, including hot and healthy food options. Unlimited tea, coffee,and soft drinks included.",
        ],
      },
      {
        type: "Morning Coffee Break",
        items: ["Homemade savoury snacks and fruits."],
      },
      {
        type: "4-Course Lunch Buffet",
        items: [
          "Fresh salads, homemade soup, hot quiche, vegetarian and non-vegetarian main courses, desserts",
        ],
      },
      {
        type: "Afternoon Coffee Break",
        items: ["Homemade sweet snacks and fruits."],
      },
      {
        type: "Aperitif Snacks",
        items: ["Snacks to nibble on and drinks before going for enjoying dinner"],
      },
      {
        type: "4-Course Seated Dinner",
        items: [
          "Designed by Chef inspired by seasonal ingredients. Open bar with soft drinks and beer. (+45€ pp for an outdoor gala dinner)",
        ],
      },
    ],
    food_and_beverage_desc2:
      "Also, additional drink and liquor packages are available and can be added for one or several nights as per your request.",
    drinks: [
      {
        type: "Happy hour package",
        items: [
          "Open bar before dinner: 15€/person/day (excl. tax)",
          "Participants have free access to liquors and mocktail bar before the dinner. Chef’s savoury specials are also included. ",
        ],
      },
      {
        type: "After drinks package",
        items: [
          "Open bar after dinner: 25€/person/day (excl. tax)",
          "Participants have free access to liquors, the wine bar, cocktail bar, and mocktail bar. Chef’s savoury specials are also included.",
        ],
      },
      {
        type: "Spirits package",
        items: [
          "Open bar before and after dinner: 39€/person/day (excl. tax)",
          "Includes happy hour package + after drinks package.",
        ],
      },
      {
        type: "Evening wine package",
        items: [
          "Open bar before dinner 10€/person/day (excl. tax)",
          "Add wine to the free drink selection after 17:00.",
        ],
      },
    ],
    workspace_collaboration_desc:
      "Three spacious meeting rooms are included, each of which can be arranged to suit your preferences.",
    workspace_items: [
      {
        image:
          "https://www.myspacearchitects.com/image-uploads/60a1ed86bb740_1.%20Sacred%20Heart%20School%20Auditorium.jpg",
        title: "Red Auditorium",
        items: ["Room size: 450 sqm", "Capacity: cabaret (208), theater (400), classroom (250)"],
      },
      {
        image:
          "https://media.istockphoto.com/id/1191473912/photo/convention-center.jpg?s=612x612&w=0&k=20&c=q-LDGY7lUU_zCCpKam_qV-7Dm1oDr_M9bW3Zq8M23QI=",
        title: "Blue Conference Room",
        items: ["Room size: 290 sqm", "Capacity: cabaret (130), theater (180), classroom (140)"],
      },
      {
        image: "https://media-cdn.tripadvisor.com/media/photo-s/06/07/9f/57/fisher-theatre.jpg",
        title: "Yellow Theater",
        items: ["Room size: 180 sqm", "Capacity: cabaret (110), theater (120), classroom (90)"],
      },
    ],
    workspace_collaboration_desc2:
      "The location also offers a wide range of collaborative spaces to socialize or work in smaller groups during the day.",
    activity_desc:
      "The venue boasts a wide range of team building activities, a large garden, a pool for leisure time, and spa facilities.",
    activity_outdoor: [
      {
        image:
          "https://christchurchadventurepark.com/assets/bulkUpload/b56231bf54/Christchurch_Adventure_Park_Progression_Sessions_Mouanting_Biking_Gallery_Image_4.jpg",
        title: "Volleyball",
      },
      {
        image:
          "https://christchurchadventurepark.com/assets/bulkUpload/b56231bf54/Christchurch_Adventure_Park_Progression_Sessions_Mouanting_Biking_Gallery_Image_4.jpg",
        title: "badminton",
      },
      {
        image:
          "https://christchurchadventurepark.com/assets/bulkUpload/b56231bf54/Christchurch_Adventure_Park_Progression_Sessions_Mouanting_Biking_Gallery_Image_4.jpg",
        title: "mountain bike track",
      },
      {
        image:
          "https://christchurchadventurepark.com/assets/bulkUpload/b56231bf54/Christchurch_Adventure_Park_Progression_Sessions_Mouanting_Biking_Gallery_Image_4.jpg",
        title: "hiking trail",
      },
      {
        image:
          "https://christchurchadventurepark.com/assets/bulkUpload/b56231bf54/Christchurch_Adventure_Park_Progression_Sessions_Mouanting_Biking_Gallery_Image_4.jpg",
        title: "table tennis",
      },
      {
        image:
          "https://taylortripp.co.uk/wp-content/uploads/2020/12/large-country-garden-design.jpg",
        title: "Large garden",
      },
      {
        image: "https://assets.chateauform.com/medias/domain8909/media31/4735-pin1usirjg-whr.jpg",
        title: "Swimming pool",
      },
    ],
    activity_indoor: [
      {
        image: "https://www.dellaresorts.com/images/spa-philosophy-pic1.jpg",
        title: "Spa",
      },
      {
        image:
          "https://i0.wp.com/mittenbody.com/wp-content/uploads/2017/10/turkish-bath.jpg?fit=2000%2C1200&ssl=1",
        title: "Turkish Bath",
      },
      {
        image:
          "https://i1.wp.com/cdn.whatsuplife.in/delhi/blog/2016/11/Heated-swimming-pools.jpg?resize=823,411",
        title: "Indoor heated pool",
      },
    ],
    proposal_desc:
      "The full-service proposal includes the single occupancy of rooms, food & beverage, meeting rooms, and work spaces.",
    proposal_properties: [
      {
        property: "Accommodation with 140 rooms (single occupancy)",
        items: [
          "Breakfast buffet, four-course lunch, four-course seated dinner",
          "Morning and afternoon coffee break",
          "Unlimited soft & hot drinks all day",
          "Unlimited soft drinks & beer from 17:00h onwards",
        ],
      },
      {
        property: "Three large and fully equipped meeting rooms",
        items: [
          "Breakfast buffet, four-course lunch, four-course seated dinner",
          "Morning and afternoon coffee break",
          "Unlimited soft & hot drinks all day",
          "Unlimited soft drinks & beer from 17:00h onwards",
        ],
      },
      {
        property: "Free access to all on site facilities (pool, spa, etc.)",
        items: [
          "Breakfast buffet, four-course lunch, four-course seated dinner",
          "Morning and afternoon coffee break",
          "Unlimited soft & hot drinks all day",
          "Unlimited soft drinks & beer from 17:00h onwards",
        ],
      },
      {
        property: "On-site activities, including tennis, padel, karaoke, board games",
        items: [
          "Breakfast buffet, four-course lunch, four-course seated dinner",
          "Morning and afternoon coffee break",
          "Unlimited soft & hot drinks all day",
          "Unlimited soft drinks & beer from 17:00h onwards",
        ],
      },
    ],
    proposal_desc2: "The following outlines two date options proposed by the property.",
  },
  {
    id: 1,
    title: "Barcelona Retreat with a Modern Flair",
    desc: "This modern remote retreat, located near Barcelona, provides an excellent work and collaboration setup amidst nature.",
    images: [
      "https://assets.chateauform.com/medias/domain8909/media100212/63823-h7nawryo4x-whr.jpg",
      "https://assets.chateauform.com/medias/domain8909/media100212/63841-0ltwkp86nm-whr.jpg",
      "https://assets.chateauform.com/medias/domain8909/media100212/63862-u4qoanb7a1-whr.jpg",
      "https://assets.chateauform.com/medias/domain8909/media100219/65971-1lvsq1taew-whr.jpg",
      "https://assets.chateauform.com/medias/domain8909/media100219/65992-3ercd92rn3-whr.jpg",
      "https://assets.chateauform.com/medias/domain8909/media100212/63826-7in14dj9qw-whr.jpg",
      "https://assets.chateauform.com/medias/domain8909/media100212/63829-m3utjiweq1-whr.jpg",
      "https://assets.chateauform.com/medias/domain8909/media100212/63835-9kdwd932j9-whr.jpg",
      "https://assets.chateauform.com/medias/domain8909/media100212/63844-tdkypphsr6-whr.jpg",
    ],
    Retreat_location: "Countryside location",
    dates: "",
    Distance_from_airport: "40 mins (BCN)",
    Rooms_and_occupancy: "180 rooms",
    What_you_will_love: [
      "Gala dinner setup in garden",
      "On-site team sports activities",
      "Tennis and padel courts",
    ],
    Sustainability_criteria: [
      "Renewable energy installations",
      "Sustainable sourcing of food",
      "On site recycling processes",
    ],
    food_and_beverage_desc:
      "A comprehensive Food & Beverage package is included in the proposal for this property and offers large variety in cuisine.",
    food: [
      {
        type: "Breakfast Buffet",
        items: [
          "Continental buffet, including hot and healthy food options. Unlimited tea, coffee,and soft drinks included.",
        ],
      },
      {
        type: "Morning Coffee Break",
        items: ["Homemade savoury snacks and fruits."],
      },
      {
        type: "4-Course Lunch Buffet",
        items: [
          "Fresh salads, homemade soup, hot quiche, vegetarian and non-vegetarian main courses, desserts",
        ],
      },
      {
        type: "Afternoon Coffee Break",
        items: ["Homemade sweet snacks and fruits."],
      },
      {
        type: "Aperitif Snacks",
        items: ["Snacks to nibble on and drinks before going for enjoying dinner"],
      },
      {
        type: "4-Course Seated Dinner",
        items: [
          "Designed by Chef inspired by seasonal ingredients. Open bar with soft drinks and beer. (+45€ pp for an outdoor gala dinner)",
        ],
      },
    ],
    food_and_beverage_desc2:
      "Additional drinks packages are available and can be booked on top for an evening or your full stay.",
    drinks: [
      {
        type: "Happy hour package",
        items: [
          "Open bar before dinner: 15€/person/day (excl. tax)",
          "Participants have free access to liquors and mocktail bar before the dinner. Chef’s savoury specials are also included. ",
        ],
      },
      {
        type: "After drinks package",
        items: [
          "Open bar after dinner: 25€/person/day (excl. tax)",
          "Participants have free access to liquors, the wine bar, cocktail bar, and mocktail bar. Chef’s savoury specials are also included.",
        ],
      },
      {
        type: "Spirits package",
        items: [
          "Open bar before and after dinner: 39€/person/day (excl. tax)",
          "Includes happy hour package + after drinks package.",
        ],
      },
      {
        type: "Evening wine package",
        items: [
          "Open bar before dinner 10€/person/day (excl. tax)",
          "Add wine to the free drink selection after 17:00.",
        ],
      },
    ],
    workspace_collaboration_desc:
      "Three spacious meeting rooms are included, each of which can be arranged to suit your preferences and group sizes.",
    workspace_items: [
      {
        image:
          "https://thevendry.com/cdn-cgi/image/width=640,quality=75,fit=contain,metadata=none,format=auto/https%3A%2F%2Fs3.us-east-1.amazonaws.com%2Fuploads.thevendry.co%2F24986%2F1668436909780_special-events-brown-auditorium-1.jpg",
        title: "Brown Auditorium",
        items: [
          "Room size: 400 sqm",
          "Capacity: cabaret (160), theater (200), Mandatory technician (+475€))",
        ],
      },
      {
        image:
          "https://as1.ftcdn.net/v2/jpg/02/48/88/78/1000_F_248887837_wfW7ZNPVMxR2LOb6Q4km203zLRhoYyGK.jpg",
        title: "Green Conference Room",
        items: ["Room size: 300 sqm", "Capacity: cabaret (140), theater (180), classroom (80)"],
      },
      {
        image:
          "https://aspectinteriors.com.au/wp-content/uploads/2017/04/funky-breakout-spaces-_-aspect-commercial-interiors-34.jpg",
        title: "Breakout rooms",
        items: [
          "9 small meeting rooms: 45 sqm ○ Capacity: Theater (21), Cabaret (18) ",
          "6 medium meeting rooms: 60-130 sqm ○ Capacity: Theater (48), Cabaret (42)",
        ],
      },
    ],
    workspace_collaboration_desc2:
      "The location also offers a wide range of collaborative spaces to socialize or work in smaller groups, both indoor and outdoor.",
    activity_desc:
      "The venue boasts an expansive garden, tennis/padel courts, and a large range of team-building activities.",
    activity_outdoor: [
      {
        image:
          "https://assets.chateauform.com/medias/domain8909/media100212/63817-43fa1v3y65-whr.jpg",
        title: "Large garden",
      },
      {
        image:
          "https://5.imimg.com/data5/SELLER/Default/2023/7/329010794/SF/VQ/ER/1507768/tennis-courts-500x500.jpeg",
        title: "Tennis court",
      },
      {
        image:
          "https://assets.chateauform.com/medias/domain8909/media100212/63829-m3utjiweq1-whr.jpg",
        title: "2x padel courts",
      },
      {
        image:
          "https://www.rei.com/dam/content_033016_2487_mountain_biking_checklist_lg.jpg?t=ea16by9xs",
        title: "Mountain biking",
      },
      {
        image: "https://www.smsmpitr.edu.in/wp-content/uploads/2020/08/sport-1.jpg",
        title:
          "Team Sports (volleyball, badminton, basketball, table tennis, football, biking track, hiking trail)",
      },
    ],
    activity_indoor: [
      {
        image:
          "https://www.shutterstock.com/image-photo/protarascyprus-10-october-2020-fully-260nw-1832202808.jpg",
        title: "Fully equipped gym",
      },
      {
        image:
          "https://static.wixstatic.com/media/ac0342884fa247c39ff4e25d4ac652c4.jpg/v1/fill/w_640,h_520,al_t,q_80,usm_0.66_1.00_0.01,enc_auto/ac0342884fa247c39ff4e25d4ac652c4.jpg",
        title: "Karaoke",
      },
      {
        image: "https://www.chevrons.org.sg/resources/ck/images/darts_pool_room/mint-overview.jpg",
        title: "Board games, darts, billiard",
      },
      {
        image:
          "https://cdn.nwscdn.com/media/catalog/product/cache/h700xw700/f/o/football-table---main_1.jpg",
        title: "Table football",
      },
    ],
    proposal_desc:
      "The hospitality provider offers a full-service proposal including rooms, food & beverage, meeting rooms, and common spaces.",
    proposal_properties: [
      {
        property: "Accommodation with 140 rooms (single occupancy)",
        items: [
          "Breakfast buffet, four-course lunch, four-course seated dinner",
          "Morning and afternoon coffee break",
          "Unlimited soft & hot drinks all day",
          "Unlimited soft drinks & beer from 17:00h onwards",
        ],
      },
      {
        property: "Three large and fully equipped meeting rooms",
        items: [
          "Breakfast buffet, four-course lunch, four-course seated dinner",
          "Morning and afternoon coffee break",
          "Unlimited soft & hot drinks all day",
          "Unlimited soft drinks & beer from 17:00h onwards",
        ],
      },
      {
        property: "Free access to all on site facilities (pool, spa, etc.)",
        items: [
          "Breakfast buffet, four-course lunch, four-course seated dinner",
          "Morning and afternoon coffee break",
          "Unlimited soft & hot drinks all day",
          "Unlimited soft drinks & beer from 17:00h onwards",
        ],
      },
      {
        property: "On-site activities, including tennis, padel, karaoke, board games",
        items: [
          "Breakfast buffet, four-course lunch, four-course seated dinner",
          "Morning and afternoon coffee break",
          "Unlimited soft & hot drinks all day",
          "Unlimited soft drinks & beer from 17:00h onwards",
        ],
      },
    ],
    proposal_desc2: "The following outlines two date options proposed by the property.",
  },
  {
    id: 2,
    title: "Coastal Retreat Outside of Lisbon",
    desc: "Nestled between the Portuguese coast, hills, and the city of Lisbon, this location combines the best of all worlds.",
    images: [
      "https://lh3.googleusercontent.com/pw/AP1GczOCrxYB_D3raGIP_S8G90rGfWJeuTLoa_whhcCyomwcXnZ1IIO-sQGMU7UwflFrTrtWEqznigeX-r-7bgFC4SucHo2rAXjie8U8Tok45g-Dh0-HikDrlwY4l97oQFzA7v9jlw50Mrl5P7EaX1gI3dzw=w1346-h897-s-no-gm?authuser=0",
      "https://lh3.googleusercontent.com/pw/AP1GczOODNc0SVoAETStoLkqeL1ZgPfzAm_VzbE7EP-qdYjQacIVYz2TeLpE47RuUPbavNognPa_geR4HV4L_9FyWNLxRgPjw0jpSr-u7BQt4hHKPI03KGz_ygyy8NyT4RLPX33nJwxpw_3UXpnbOHkip8Np=w1346-h897-s-no-gm?authuser=0",
      "https://lh3.googleusercontent.com/pw/AP1GczMkn6KDgL9Xn0qM8zkIEBzSJzRLvv_zaAmV_jWVz5LekXGLnO6ck8OQ_bdj1pDkabou7iIPCdoynaIYQtkoR0Oo7qhjkXUS9nVApd7YNMR0vq6xKNkt9UoSG9qxzPOWWw6eJxH5jcG2H5XCuZwNUGvb=w1344-h897-s-no-gm?authuser=0",
      "https://lh3.googleusercontent.com/pw/AP1GczPUh8V2Et9tnBY_26Ngu1BOsVnMfjXSOnYk4hDZZP3bjDam9mt7PugKql46ehd-SF4AgG7KOA1v7wPCpYzMVFOZYdbTN9Z9c4YDGA4BmfihSxluNw-cb8tccyQyq-JJqMnF04rNI4YUHF8zJptFhZ8u=w1530-h897-s-no-gm?authuser=0",
      "https://lh3.googleusercontent.com/pw/AP1GczMYlCZYWT6S9FlM9X0-6Z3Ncx2T6yudkDaSDGV9Xet8SlL3sh5FJwr68iJT32_oMrxaHS5540-0CtmMg39aiB-dDZj0qnxbQtZpMnc1TKk1lN5C-4Qm1rZ5EcMNDi0J0_ED-P70M2XsQQkKPBEKTt6U=w1401-h897-s-no-gm?authuser=0",
      "https://lh3.googleusercontent.com/pw/AP1GczMm_apPqme7LD7geJQE8iBQu-wsMXz6PGP00HI_pZ_ddgspYh_BMAmbIoH1Kx9UVvmTnljL69k_fl7cv8Ddmdzz3ev6C0BrT4_mGG7udKnSoVhgjAbjd4mxzCKqiKd9qtHi9QbqUdETFzRHoM0qGWRF=w1000-h667-s-no-gm?authuser=0",
      "https://lh3.googleusercontent.com/pw/AP1GczOBzYcs3rZ7eJTXWxurhfE37CGiKirLWvH43vgcSWws3alDBB-wUKdB6kdwM7HOegqlcSmU8Pm1flPkw3Qgn03s8SSSxw7YDcIkreZeEpwKbho0cZ3stl9s2gPIZWJpYtp2FFfrogu2ol7Xs45sNKOs=w1344-h897-s-no-gm?authuser=0",
      "https://lh3.googleusercontent.com/pw/AP1GczMH7ciiVvqgf4qSUknxj74aEESdi-JipXv0ucJ0PjpMnPuJjiWQBkRe1lvRzw9BD86Zid6dzpm2x67X9QDlkTaWLEnvZwkJOpwBdV9UIbb-DhYZso-3YWh3Rqnx3sKasdzwjDASCToMHqgzMu0IA8j0=w1701-h897-s-no-gm?authuser=0",
    ],
    Retreat_location: "Cascais, 30 minutes from Lisbon city center",
    Distance_from_airport: "37 mins (LIS)",
    dates: "",
    Rooms_and_occupancy: "142 rooms; 110 occupied by Leapsom (80 single/30 double occupancy)",
    What_you_will_love: [
      "Ocean views and immediate proximity to Sintra national park",
      "5-star service with excellent amenities on-site",
    ],
    Sustainability_criteria: [
      "Sustainable & regional cuisine",
      "1,500 solar panels on-site",
      "... and much more!",
    ],
    food_and_beverage_desc:
      "4 restaurants and 3 terraces will allow you to dine with authentic Portuguese cuisine, with local, sustainable and seasonal products.",
    food: [
      {
        type: "Breakfast Buffet",
        items: [
          "Continental buffet, including hot and healthy food options. Unlimited tea, coffee,and soft drinks included.",
        ],
      },
      {
        type: "Afternoon Coffee Break",
        items: [
          "Snacks, tea, and coffee can be served between meetings, including fruit and pastry options.",
        ],
      },
      {
        type: "Varied Lunch Buffet",
        items: [
          "Fresh hot and cold salads, vegetarian and non-vegetarian main courses, and a dessert bar.",
        ],
      },
      {
        type: "In- or Outdoor Dinner Options",
        items: [
          "Chef-crafted with seasonal ingredients in different locations. Beverages are beer, wine, and soft drinks (add-ons available).",
        ],
      },
    ],
    food_and_beverage_desc2:
      "Additional drink packages or event-specific indoor and outdoor catering are available and can be requested to substitute a meal.",
    drinks: [
      {
        type: "Special wine & beverage service",
        items: [
          "3€/person/day (excl. tax)",
          "Specialty sommelier service at the table can be available upon request, making the dinner a night to remember.",
        ],
      },
      {
        type: "Additional location options for food & beverage consumption on-site",
        items: [
          "Option 1: A private restaurant is a perfect dining option for up to 300 people. Access to 2 terraces, and embraced by the surrounding nature.",
          "Option 2: Outdoor terraces can cater to theme nights or outdoor barbeques on warm summer nights.",
          "Option 3: A clubhouse near the hotel boasts an exceptional setting surrounded by umbrella pine trees and scenic views of the Atlantic Ocean for a special dining experience.",
        ],
      },
    ],
    workspace_collaboration_desc:
      "The venue has several meeting room options for larger and smaller groups, each of which can be arranged to suit your preferences.",
    workspace_items: [
      {
        image:
          "https://lh3.googleusercontent.com/pw/AP1GczOOq4HEZBkWKymaclQ-222CKd_QlQyL2qEAtkCZgSjFToTL95rurUJRZWzg1YmHaYqu1JEmVx7nkDiFUldJlYxMVYb9NTHamIWMS_7QYDVwPb1UF2N_IaqrhKr9tEcqSzonSV4aCLnQcdHlJy7PwMK5=w1352-h897-s-no-gm?authuser=0",
        title: "Conference Center (large)",
        items: ["Room size: 450 sqm", "Capacity: up to 234"],
      },
      {
        image:
          "https://lh3.googleusercontent.com/pw/AP1GczPiCaXPXtiOD6BBUccliwfI7coRpbnIljdBI2T_dEXQts4eMF3t2UBlNGEBanuDpx_1IIyewvZNF3Uw4DrnCn4oQuSr7rN8j61ojOo8Hfttt4k6ABGzVXRplXffVUfJqmuvWFBfn4FJWmONc2u8stOn2A=w1472-h897-s-no-gm?authuser=0",
        title: "3 Meeting Rooms (medium)",
        items: [
          "Capacity: Room 1 (70), Room 2 (60), Room 3 (60)",
          "Cabaret setup: allows for up to 426 people, Theater setup: allows for up to 326 people",
        ],
      },
      {
        image:
          "https://lh3.googleusercontent.com/pw/AP1GczPiCaXPXtiOD6BBUccliwfI7coRpbnIljdBI2T_dEXQts4eMF3t2UBlNGEBanuDpx_1IIyewvZNF3Uw4DrnCn4oQuSr7rN8j61ojOo8Hfttt4k6ABGzVXRplXffVUfJqmuvWFBfn4FJWmONc2u8stOn2A=w1472-h897-s-no-gm?authuser=0",
        title: "Atlantic Pool Bar (outdoor event space)",
        items: [
          "Partially or fully outdoor meeting and activity space",
          "Team activities and breakout groups possible on the patio",
        ],
      },
    ],
    workspace_collaboration_desc2:
      "This location’s proximity to nature makes outdoor spaces an excellent area for group collaboration and celebrations.",
    activity_desc:
      "With the proximity to nature and the ocean, this location has everything to relax and get active as a team.",
    activity_outdoor: [
      {
        image:
          "https://lh3.googleusercontent.com/pw/AP1GczNkzSAAFdAAgzj4ICTDU-djzhVVsYRfAHw6mrZTBZk_TEViIg-zCbsOB2UB8jc2v35rQVOP_JdJb-L00Ymos4Nk9hxTfrnShKik5CEqV10AeiG_uVWHbiIK8S3sBikv5bbNg6ya7pWrdRu3mSLxydaD=w1346-h897-s-no-gm?authuser=0",
        title: "Golf course",
      },
      {
        image:
          "https://lh3.googleusercontent.com/pw/AP1GczO4AgVPrZ6SVsm9EUm3UIBax2FQ2zIWZ2wF1h7IBMwmw4lZi1CP_mNpq0YZ9zdSmZGk5_gLRMyJ9HkR6Quwy65pMUz9hnNDVK47GwaeudjhU1RU77V09XxmF-6r0d-z_I3ClqELgMAo5uDzjlEazp3W=w1346-h897-s-no-gm?authuser=0",
        title: "Horseback riding in Sintra National Park",
      },
      {
        image:
          "https://christchurchadventurepark.com/assets/bulkUpload/b56231bf54/Christchurch_Adventure_Park_Progression_Sessions_Mouanting_Biking_Gallery_Image_4.jpg",
        title: "Team Sports (padel, tennis, volleyball, pilates, bike riding, etc.)",
      },
      {
        image:
          "https://lh3.googleusercontent.com/pw/AP1GczNtcEwSJvteMykP3SWlC1CRmNUleOSBuyK_-3cVzD8eSUNqzSr1rzofLdNuweF3L7xFEKtQRsyDGBU58jvpJAQ9Z2GvTww7PWM6bAw9R-qcA5GuTbNv2x1un_x4LcnGN48UMQ9t79yB16P4U-QkkFsT=w1298-h897-s-no-gm?authuser=0",
        title: "Water sports (surf, stand up paddle, diving, dolphins tour, boat tours, etc.)",
      },
    ],
    activity_indoor: [
      {
        image:
          "https://lh3.googleusercontent.com/pw/AP1GczP8-An_pwcwYtzle5FkQU13BAuYNAmqIF42gBaJEj0C8InSyzCD3BMLEg9zT-TXuScZjRVxE_-Gu6OZv1qR6h6qCkARC5Mfy1Qjua2qcqhELwHhYz_IS23ulOWEGkmQ1MM4y1mIs80S8n4WUF4NtT1E=w1346-h897-s-no-gm?authuser=0",
        title: "Wellness Center",
      },
      {
        image:
          "https://lh3.googleusercontent.com/pw/AP1GczNpHocIUKw2j9JckuYgGdhnCO9UCx-d3ChkugI-g0JjOGHckXJg4STFHW8d1a65wCMsJgKpazsRWp4-yTl9wg1C43VdXmhqNLU3PBbPHpNV0dnmDWGFY0C7X0Pn8zcixEtu2jDFdI_MIciWXGkw9UrB=w1437-h897-s-no-gm?authuser=0",
        title: "Spa",
      },
      {
        image:
          "https://lh3.googleusercontent.com/pw/AP1GczPyL323rev-pyE33DvAeSLtehjjT2VjovWGh_joZu1iWT4C7bW-2rKyjphogISpoUnnMNJhpRCKw_HTngqLSqn3tXwC07wzxshHK1fKf1Wdi3Y7a9LNVmk8RtOHaLf15CMBlHtydRv0s12kdaOzlUHL=w1346-h897-s-no-gm?authuser=0",
        title: "Fitness Center",
      },
      {
        image:
          "https://lh3.googleusercontent.com/pw/AP1GczNdKTbDaPI0rcZ1MoWDJgRaUFh1tyBo7CiWyBDQ3ChemvEW6c6iVUaOMzF9spEo-l1OT4qgzAozw6Jahms5cyF8_gjNbmKkmSNLlgh9aLGdIcruxLeiYwYthdh7R2nOjCJK_WWMiXXeYO1GNnwtLLw-=w1497-h897-s-no-gm?authuser=0",
        title: "Indoor heated pool",
      },
      {
        image:
          "https://lh3.googleusercontent.com/pw/AP1GczM8OpGuzPC7BwCzTKK-4PMz5qBw36OtLALKYNOJn9DRcRmPzivNtiZO6j3SUSAP9dOYh9cDarD6bhXlmBiaFWngOkd9zpuI1R0tfleBQ5nGrzQSakjpiJNMefUrnxqv52Ozg307HyOOeNiAYR4keNDo=w1014-h897-s-no-gm?authuser=0",
        title: "Wine tasting and pairing workshops",
      },
    ],
    proposal_desc:
      "This proposal includes all the necessities from accomodation, catering and workspaces, with personal event support on-site. ",
    proposal_properties: [
      {
        property: "Accommodation with 110 rooms (80 single occupancy, 30 double occupancy)",
        items: [],
      },
      {
        property: "Over 15 meeting rooms and gathering spaces, in- and outdoor",
        items: [],
      },
      {
        property: "Free access to all on-site wellness facilities",
        items: [],
      },
      {
        property: "All-inclusive regional and sustainable meals made fresh daily",
        items: [
          "Breakfast, lunch and dinner",
          "Morning and afternoon coffee break",
          "Unlimited soft & hot drinks all day",
          "Additional drinks available upon request",
        ],
      },
    ],
    proposal_desc2: "The following outlines two date options proposed by the property.",
  },
  {
    id: 3,
    title: "Budapest City Getaway",
    desc: "Situated in the lively heart of Budapest, this hotel offers a perfect blend of convenience and excitement for an urban summer",
    images: [
      "https://media.radissonhotels.net/image/radisson-blu-bke-hotel-budapest/exterior/16256-116449-f64892972_3xl.jpg?impolicy=GalleryLightboxFullscreen",
      "https://lh3.googleusercontent.com/pw/AP1GczM8OpGuzPC7BwCzTKK-4PMz5qBw36OtLALKYNOJn9DRcRmPzivNtiZO6j3SUSAP9dOYh9cDarD6bhXlmBiaFWngOkd9zpuI1R0tfleBQ5nGrzQSakjpiJNMefUrnxqv52Ozg307HyOOeNiAYR4keNDo=w1014-h897-s-no-gm?authuser=0",
      "https://media.radissonhotels.net/image/radisson-blu-bke-hotel-budapest/meeting-room/16256-116449-f67394583_3xl.jpg?impolicy=GalleryLightboxFullscreen",
      "https://media.radissonhotels.net/image/radisson-blu-bke-hotel-budapest/guest-room/16256-116449-f67250957_3xl.jpg?impolicy=GalleryLightboxFullscreen",
      "https://media.radissonhotels.net/image/radisson-blu-bke-hotel-budapest/meeting-room/16256-116449-f67250169_3xl.jpg?impolicy=GalleryLightboxFullscreen",
      "https://media.radissonhotels.net/image/radisson-blu-bke-hotel-budapest/meeting-room/16256-116449-f64892922_3xl.jpg?impolicy=GalleryLightboxFullscreen",
      "https://media.radissonhotels.net/image/radisson-blu-bke-hotel-budapest/meeting-room/16256-116449-f64892934_3xl.jpg?impolicy=GalleryLightboxFullscreen",
      "https://media.radissonhotels.net/image/radisson-blu-bke-hotel-budapest/restaurant/16256-116449-f64892980_3xl.jpg?impolicy=GalleryLightboxFullscreen",
      "https://media.radissonhotels.net/image/radisson-blu-bke-hotel-budapest/restaurant/16256-116449-f64892900_3xl.jpg?impolicy=GalleryLightboxFullscreen",
    ],
    Retreat_location: "Budapest city center",
    Distance_from_airport: "35 mins (BUD",
    dates: "",
    Rooms_and_occupancy: " 247 rooms",
    What_you_will_love: [
      "Central location",
      "Excellent price for value",
      "Budapest river dinner (recommendation)",
    ],
    Sustainability_criteria: [
      "Net zero commitment by 2025",
      "Recycling processes in place",
      "Seasonal  and local food suppliers",
    ],
    food_and_beverage_desc:
      "Food & beverage packages are available and can be adjusted to the selected team activities that may include meals outside.",
    food: [
      {
        type: "Breakfast Buffet",
        items: [
          "Continental buffet, including hot and healthy food options. Unlimited tea, coffee,and soft drinks included.",
        ],
      },
      {
        type: "Morning Coffee Break",
        items: ["One cup of coffee/tea, sweet and salty pasties, fresh fruits"],
      },
      {
        type: "Lunch Buffet",
        items: [
          "Salad bar, soup, cold and warm dishes, desserts, 1 soft drink or mineral water + 1 coffee or tea",
        ],
      },
      {
        type: "Afternoon Coffee Break",
        items: [
          "One cup of coffee/tea, infused water/lemonade, mini sandwiches, sweet and salty pastries, fresh fruits. ",
        ],
      },
      {
        type: "Dinner Buffet",
        items: [
          "Salad bar, soup, cold and warm dishes, desserts, 1 soft drink or mineral water + 1 coffee or tea.",
        ],
      },
    ],
    food_and_beverage_desc2:
      "Additional drink packages or event-specific indoor and outdoor catering are available and can be requested to substitute a meal.",
    drinks: [],
    workspace_collaboration_desc:
      "Three spacious meeting rooms are included in the package and more meeting rooms are available on-site if required.",
    workspace_items: [
      {
        image:
          "https://media.radissonhotels.net/image/radisson-blu-bke-hotel-budapest/meeting-room/16256-116449-f64892992_3xl.jpg?impolicy=GalleryLightboxFullscreen",
        title: "Large meeting room",
        items: [
          "Room size: 230 sqm",
          "Capacity: 140 pax",
          "Walls decorated by iconic frescos from the early 20th century",
        ],
      },
      {
        image:
          "https://media.radissonhotels.net/image/radisson-blu-bke-hotel-budapest/meeting-room/16256-116449-f64892938_3xl.jpg?impolicy=GalleryLightboxFullscreen",
        title: "Medium sized rooms (2x) can be joined",
        items: [
          "Room size: 85 sqm + 93 sqm ■ Joined: 170 sqm",
          "Capacity: 64 pax + 72 pax ■ Joined 120 pax",
        ],
      },
      {
        image:
          "https://media.radissonhotels.net/image/radisson-blu-bke-hotel-budapest/meeting-room/16256-116449-f64892986_3xl.jpg?impolicy=GalleryLightboxFullscreen",
        title: "More meeting rooms available",
        items: ["To be defined if selected"],
      },
    ],
    workspace_collaboration_desc2:
      "Your budget may leave some room to book excellent activities to explore Budapest in the city and surroundings.",
    activity_desc:
      "With the proximity to nature and the ocean, this location has everything to relax and get active as a team.",
    activity_outdoor: [],
    activity_indoor: [],
    proposal_desc:
      "The hospitality provider’s proposal includes rooms, food & beverage, and three meeting rooms. Activities are not included.",
    proposal_properties: [
      {
        property: "Accommodation with 140 rooms (single occupancy)",
        items: [],
      },
      {
        property: "Meals included",
        items: [
          "Breakfast buffet, lunch, morning coffee break, and dinner (offer can be adjustedbased on final itinerary)",
        ],
      },
      {
        property: "Three fully equipped meeting rooms",
        items: [
          "One large meeting room",
          "Two medium sized meeting rooms (64 and 71 pax or 120 pax total)",
        ],
      },
      {
        property: "Activities",
        items: ["Not included in the proposal. To be selected with Leapsome."],
      },
    ],
    proposal_desc2: "The following outlines two date options proposed by the property.",
  },
  {
    id: 4,
    title: "Another City Retreat in Budapest",
    desc: "Situated in the lively heart of Budapest, this hotel offers a perfect blend of class and excitement for an urban summer retreat.",
    images: [
      "https://www.jet2holidays.com/HotelImages/Web/BUD_70843_Hilton_Budapest_0423_03.jpg",
      "https://www.diningcity.hu/media/restaurantwidepictures/46962_white_raven_skybar_lounge_hilton_budapest.jpg",
      "https://assets.chateauform.com/medias/domain8909/media100212/63856-qget2p152h-whr.jpg",
      "https://assets.chateauform.com/medias/domain8909/media31/4837-1cn2s260i9-whr.jpg",
      "https://assets.chateauform.com/medias/domain8909/media100212/63817-43fa1v3y65-whr.jpg",
      "https://assets.chateauform.com/medias/domain8909/media100212/63835-9kdwd932j9-whr.jpg",
    ],
    Retreat_location: "Budapest city center",
    Distance_from_airport: "35 mins (BUD",
    Rooms_and_occupancy: " 247 rooms",
    dates: "24.07 - 27.07.2024",
    What_you_will_love: [
      "140 rooms (single occupancy)",
      "Ballroom & 5 breakout rooms",
      "Full F&B package (including 2x coffee breaks)",
    ],
    Sustainability_criteria: [
      "Net zero commitment by 2025",
      "Recycling processes in place",
      "Seasonal  and local food suppliers",
    ],
    expected_price: [
      "3 days, 2 nights: 108 080, 00 € (approx)",
      "4 days, 2 nights: 141 800, 00 € (approx)",
    ],
    food_and_beverage_desc: "",
    food: [],
    food_and_beverage_desc2: "",
    drinks: [],
    workspace_collaboration_desc: "",
    workspace_items: [],
    workspace_collaboration_desc2: "",
    activity_desc: "",
    activity_outdoor: [],
    activity_indoor: [],
    proposal_desc: "",
    proposal_properties: [],
    proposal_desc2: "",
  },
  {
    id: 5,
    title: "Coastal Retreat Outside of Barcelona",
    desc: "Near Barcelona's coast, this location effortlessly combines beach relaxation, spa indulgence, and a large variety of activities.",
    images: [
      "https://www.atzavarahotel.com/content/imgsxml/galerias/panel_gallery/1/piscinas-atzavara-lowres-5925.jpg",
      "https://www.atzavarahotel.com/content/imgsxml/galerias/panel_gallery/1/piscinas-atzavara-lowres-13454.jpg",
      "https://www.atzavarahotel.com/content/imgsxml/galerias/panel_gallery/1/royal-pool-penthouse-lowres-11424.jpg",
      "https://www.atzavarahotel.com/content/imgsxml/galerias/panel_gallery/1/pool-pent-05-frank533.jpg",
      "https://www.atzavarahotel.com/content/imgsxml/galerias/panel_gallery/1/pool-villa-lowres-1326.jpg",
      "https://www.atzavarahotel.com/content/imgsxml/galerias/panel_gallery/1/premium-lowres-2312.jpg",
      "https://www.atzavarahotel.com/content/imgsxml/galerias/panel_gallery/1/7393.jpg",
      "https://www.atzavarahotel.com/content/imgsxml/galerias/panel_gallery/1/dsc02966952.jpg",
      "https://www.atzavarahotel.com/content/imgsxml/galerias/panel_gallery/1/sala-verbena-03-frank611.jpg",
    ],
    Retreat_location: "Beach location",
    Distance_from_airport: "50 mins (BCN)",
    dates: "",
    Rooms_and_occupancy: " 465 rooms",
    What_you_will_love: [
      "Located walking distance from the beach",
      "Wide range of leisure activities",
      "Excellent pool and spa facilities",
    ],
    Sustainability_criteria: [],
    food_and_beverage_desc:
      "This proposal offers local Spanish cuisine and allows your team to dine in- and outdoors throughout the day.",
    food: [
      {
        type: "Breakfast Buffet",
        items: [
          "Continental buffet, including hot and healthy food options. Unlimited tea, coffee,and soft drinks included.",
        ],
      },
      {
        type: "Morning Coffee Break",
        items: ["One cup of coffee/tea, sweet and salty pasties, fresh fruits"],
      },
      {
        type: "Varied Lunch Buffet",
        items: [
          <>
            Open buffet
            <br />
            Work menu
            <br />
            BBQ & Paella
          </>,
        ],
      },
      {
        type: "In- or Outdoor Dinner Options",
        items: [
          <>
            Open buffet
            <br />
            Work menu
            <br />
            BBQ & Paella <br />
            Gala dinner
          </>,
        ],
      },
    ],
    food_and_beverage_desc2: "",
    drinks: [],
    workspace_collaboration_desc:
      "There are several meeting room options that can be added to the proposal if this location is selected.",
    workspace_items: [],
    workspace_collaboration_desc2: "",
    activity_desc:
      "A large range of activities can be offered by this provider, which are currently not included in the hospitality package",
    activity_outdoor: [
      {
        image:
          "https://hips.hearstapps.com/hmg-prod/images/gettyimages-1598957993-655f4b2f1560a.jpg?crop=1.00xw:1.00xh;0,0&resize=2048:*",
        title: "Yoga & Pilates Classes",
      },
      {
        image:
          "https://cdn.biketours.com/assets/files/2820/adobestock_267990467.1600x500.jpeg.webp",
        title: "Yoga & Pilates Classes",
      },
      {
        image: "https://skypadel.com/wp-content/uploads/2023/11/Sky-Padel-pista-India.jpg",
        title: "Padel courts",
      },
      {
        image: "https://www.nps.gov/olym/planyourvisit/images/Slideshow-84.jpg",
        title: "Hike in natural park",
      },
      {
        image: "https://www.dark-clouds.com/wp-content/uploads/2018/02/karaoke.jpg",
        title: "Karaoke night",
      },
    ],
    activity_indoor: [
      {
        image:
          "https://img1.wsimg.com/isteam/ip/2c21d69a-7007-408f-9bbf-99011dde2686/Cocktail_Class.png/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=h:1000,cg:true",
        title: "Cocktail workshop",
      },
      {
        image:
          "https://1001.pics/img/369x175x2/listings/e0a1ce29-d2df-445c-8a85-a02b0d451bd8/900x600/workshop-sushi-maken.fp7e9c6258.webp",
        title: "Sushi workshop",
      },
      {
        image:
          "https://images.squarespace-cdn.com/content/v1/51a4e938e4b0f0ad735ac785/1507753360278-LF0L9KPZZNRWGSKAZJQD/special+event-abc+bingo+night-1.jpg",
        title: "Bingo night",
      },
    ],
    proposal_desc:
      "The hospitality provider offers a full-service proposal including accommodation and food & beverage.",
    proposal_properties: [
      {
        property: "Accommodation with 140 rooms (single occupancy",
        items: [],
      },
      {
        property: "Meals included",
        items: ["Breakfast buffet, lunch, dinner (offer can be adjusted)", "Morning coffee break"],
      },
      {
        property: "Meeting rooms",
        items: ["Not included in the proposal. To be selected with Leapsome."],
      },
      {
        property: "Activities",
        items: ["Not included in the proposal. To be selected with Leapsome."],
      },
    ],
    proposal_desc2: "The following outlines one date option proposed by the property.",
  },
];
