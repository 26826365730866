import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { IconWithTextSimpleChipCmp } from "../../components/Chips";
import { ArrowHorizontalIconCmp, CalendarIconCmp } from "../../components/Icons";
import { useIsSm } from "../../hooks/useIsSmHook";
import { UserContext } from "../../providers/UserProvider";

export default function UpcomingRetreatsCmp() {
  const [open, setOpen] = React.useState(false);
  const isSm = useIsSm();
  // const { userGetProposalApi } = useUserGetPropsalApi();
  // React.useEffect(() => {
  //   userGetProposalApi();
  // }, []);

  //correct this one.
  const { tripsData } = React.useContext(UserContext);

  return (
    <Box>
      <Typography variant="h5">List Of Bookings</Typography>
      {tripsData.length > 0 ? (
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row", md: "column" }}
          flexWrap={{ sm: "wrap", md: "none" }}
          columnGap={{ sm: 2, md: 0 }}
          rowGap={2}
          mt={2}
        >
          {tripsData.map((el, idx) =>
            !isSm ? (
              <CardCmp
                onClickInfo={() => setOpen(true)}
                key={idx}
                id={el.proposal_id}
                image={el.images}
                title={el.proposal_title}
                // team={el.team}
                // day={el.day}
                // month={el.month}
                duration={el.days_and_nights}
                datesIn={el.checkin_date}
                datesOut={el.checkout_date}
                desc={el.short_description}
                name={el.retreat_name}
              />
            ) : (
              <CardMobileCmp
                onClickInfo={() => setOpen(true)}
                key={idx}
                id={el.id}
                image={el.images[0]}
                title={el.name}
                team={el.team}
                day={el.day}
                month={el.month}
                duration={el.duration}
                dates={el.dates}
                desc={el.desc}
              />
            )
          )}
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <img src="/images/homeLeftImg.png" />
          <Typography mt={2} variant="h5Alt" fontWeight={700}>
            There Are No Trips
          </Typography>
        </Box>
      )}
      {/* <AboutPlaceDialogCmp open={open} handleClose={() => setOpen(false)} /> */}
    </Box>
  );
}

function CardCmp({
  image,
  title,
  team,
  duration,
  month,
  day,
  datesIn,
  datesOut,
  id,
  onClickInfo,
  desc,
  name,
}) {
  const navigate = useNavigate();

  console.log(duration);

  return (
    <Box display="flex" alignItems="center" px={3} py={2} columnGap={4} onClick={onClickInfo}>
      <Box
        display="flex"
        alignItems="center"
        columnGap={2}
        width={{ lg: 300, md: 200 }}
        flexShrink={0}
      >
        <Box textAlign="center">
          <Typography variant="body2" color="text.light">
            April
          </Typography>
          <Typography variant="h2Alt" fontWeight={700}>
            06
          </Typography>
        </Box>
        <Box bgcolor="text.light" width="1px" height={28} borderRadius={2} />
        <Box>
          <Typography variant="body2">{name}</Typography>
          <Typography variant="body2" color="text.light">
            {duration}
          </Typography>
        </Box>
      </Box>
      <Box bgcolor="text.light" width={3} height={64} borderRadius={2} flexShrink={0} />
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Box display="flex" alignItems="center">
          <Box height={120} width={120} borderRadius={4} overflow="hidden" flexShrink={0}>
            <img
              src={image}
              alt="hey"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
          <Box display="flex" flexDirection="column" mx={3}>
            <Typography variant="h5Alt" fontWeight={500}>
              {title}
            </Typography>
            <Box py={0.4} />
            <Typography variant="body2">{desc}</Typography>
            <Box py={0.4} />
            <IconWithTextSimpleChipCmp
              icon={CalendarIconCmp}
              label={datesIn + " " + "To" + " " + datesOut}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={{ md: "column", lg: "row" }}
          rowGap={{ md: 2, lg: 0 }}
          alignItems="center"
          columnGap={3}
          flexShrink={0}
        >
          <Button
            variant="outlined"
            endIcon={<ArrowHorizontalIconCmp />}
            onClick={() => navigate("itinerary/" + id)}
          >
            See More
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

function CardMobileCmp({ image, title, team, duration, month, day, dates, id, onClickInfo, desc }) {
  const navigate = useNavigate();

  return (
    <Box
      border="1px solid #C4DFB6"
      borderRadius={4}
      p={0.5}
      flex={1}
      width={{ sm: "325px", md: "100%" }}
    >
      <Box
        height={{ xs: 150, sm: 250 }}
        width="100%"
        borderRadius={4}
        overflow="hidden"
        flexShrink={0}
        onClick={onClickInfo}
      >
        <img
          style={{
            width: "100%",
            minHeight: "100%",
            objectFit: "cover",
          }}
          src={image}
          alt="hey"
        />
      </Box>
      <Box p={{ xs: 1, md: 2 }}>
        <Box display="flex" flexDirection="column">
          <Box py={0.4} />
          <Typography variant="h5Alt" fontWeight={500}>
            {title}
          </Typography>
          <Box py={0.4} />
          <Typography variant="body2">{desc}</Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Typography variant="body2">Retreat with {team}</Typography>
        <Box py={0.4} />
        <IconWithTextSimpleChipCmp icon={CalendarIconCmp} label={dates} />
        <Box py={2} />
        <Box display="flex" justifyContent="space-between" alignItems="center" flexShrink={0}>
          <Button variant="contained">Pay Now</Button>
          <Button
            variant="outlined"
            endIcon={<ArrowHorizontalIconCmp />}
            onClick={() => navigate("itinerary/" + id)}
          >
            itinerary
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
