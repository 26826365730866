import axios from "axios";
import { useState } from "react";

export const useUserSetProposalApi = (data) => {
  const userSetProposalApi = async () => {
    try {
      await axios.post("https://back.eira.ai/api/myproposal/", {
        ...data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return { userSetProposalApi };
};

export const useUserGetPropsalApi = () => {
  const [proposalData, setProposalData] = useState([]);

  const userGetProposalApi = async (userEmail) => {
    try {
      const response = await axios.get("https://back.eira.ai/api/myproposal/" + userEmail);
      return response.data;
    } catch (error) {}
  };
  return { userGetProposalApi, proposalData };
};

export const useUserPropsalGetApi = () => {
  const [proposalData, setProposalData] = useState([]);

  const userProposalGetApi = async () => {
    try {
      const response = await axios.get("https://back.eira.ai/api/myproposal/");
      return response.data;
    } catch (error) {}
  };
  return { userProposalGetApi, proposalData };
};
