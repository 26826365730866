import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import * as React from "react";
import { DropdownCmp, TextFieldWithLabelCmp } from "../../components/Inputs";
import StandardDialogCmp from "../../components/StandardDialogCmp";
import { useUserPutCollaboratorApi } from "../../services/apis/userSetCollaborationApi";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditMemberDialog({ handleClose, open, id, setOpenEditMember }) {
  const [userEditCollaborator, setUserEditCollaborator] = React.useState({
    collaborator_email_id: "",
    collaborator_first_name: "",
    collaborator_last_name: "",
    collaborator_status: "",
  });
  // const { userSetCollaboratorApi } = useUserSetCollaboratorApi(userEditCollaborator);
  // const { editData, userGetCollaboratorApi } = useUserGetCollaboratorApi();
  const { userPutCollaboratorApi } = useUserPutCollaboratorApi(userEditCollaborator, id);

  function refreshPage() {
    window.location.reload(true);
  }

  return (
    <StandardDialogCmp title="Edit Member" open={open} handleClose={handleClose}>
      <Box display="flex" flexDirection="column" rowGap={2} mt={4}>
        <Box display="flex" justifyContent="space-between" columnGap={4}>
          <TextFieldWithLabelCmp
            label="First Name"
            value={userEditCollaborator.collaborator_first_name}
            onChange={(e) =>
              setUserEditCollaborator((prev) => ({
                ...prev,
                collaborator_first_name: e.target.value,
              }))
            }
          />
          <TextFieldWithLabelCmp
            label="Last Name"
            value={userEditCollaborator.collaborator_last_name}
            onChange={(e) =>
              setUserEditCollaborator((prev) => ({
                ...prev,
                collaborator_last_name: e.target.value,
              }))
            }
          />
        </Box>
        <TextFieldWithLabelCmp
          label="Email"
          value={userEditCollaborator.collaborator_email_id}
          onChange={(e) =>
            setUserEditCollaborator((prev) => ({ ...prev, collaborator_email_id: e.target.value }))
          }
        />
        <DropdownCmp
          label="Role"
          options={options}
          value={userEditCollaborator.collaborator_status}
          onChange={(e) =>
            setUserEditCollaborator((prev) => ({ ...prev, collaborator_status: e.target.value }))
          }
        />
        <Box mt={2}>
          <Button
            variant="contained"
            fullWidth
            size="small"
            onClick={async () => {
              await userPutCollaboratorApi();
              setOpenEditMember(false);
              refreshPage();
            }}
          >
            Update
          </Button>
        </Box>
      </Box>
    </StandardDialogCmp>
  );
}

const options = [
  { id: 0, cmp: "Planner", value: "Planner" },
  { id: 1, cmp: "Participant", value: "Participant" },
];
