import { Box, Grid, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import React from "react";
import { MultiChipsCmp } from "../../components/Chips";
import {
  AchievementsIconCmp,
  EllipsisIconCmp,
  EmployeeEngagementIconSvg,
  TeamBuildingIconSvg,
  TrainingIconCmp,
  WellBeingIconCmp,
} from "../../components/Icons";
import { DropdownCmp, TextFieldWithLabelCmp } from "../../components/Inputs";
import { CreateRetreatContext } from "./CreateRetreatContext";
import LeftCmp from "./LeftCmp";

export default function CreateRetreatStep2Cmp() {
  const { formData } = React.useContext(CreateRetreatContext);

  return (
    <Grid container>
      <Grid item xs={4} display={{ xs: "none", md: "block" }}>
        <LeftCmp retreat_name={formData.retreat_name} />
      </Grid>
      <Grid item xs={16} md={8}>
        <FormCmp />
      </Grid>
    </Grid>
  );
}

function FormCmp() {
  const { formData, updateFormValue, countriesData, name, foundCountry, foundCity } =
    React.useContext(CreateRetreatContext);
  return (
    <Box pl={{ xs: 0.5, md: 8 }} pt={2}>
      <Box width="100%" display="flex" flexDirection="column" rowGap={6}>
        <Box>
          <Box
            width="100%"
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            alignItems="center"
            columnGap={4}
            rowGap={{ xs: 2, md: 0 }}
          >
            <DatePicker
              disablePast
              sx={{ width: "100%" }}
              label="Start Date"
              // value={value}
              // onChange={(newValue) => {
              //   setValue(newValue);
              // }}
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              disablePast
              sx={{ width: "100%" }}
              label="End Date"
              // value={value}
              // onChange={(newValue) => {
              //   setValue(newValue);
              // }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            alignItems="center"
            columnGap={4}
            rowGap={{ xs: 2, md: 0 }}
            mt={4}
          >
            <Box
              flex={1}
              width={{
                xs: "100%",
              }}
            >
              <TextFieldWithLabelCmp
                label="How many will attend"
                value={formData.retreat_participant_count}
                onChange={(e) => updateFormValue("retreat_participant_count", e.target.value)}
                name={formData.retreat_participant_count}
              />
            </Box>
            <Box
              flex={1}
              width={{
                xs: "100%",
              }}
            >
              <DropdownCmp
                label="Room Type"
                options={roomTypeOptions}
                value={formData.retreat_room_type}
                onChange={(e) => updateFormValue("retreat_room_type", e.target.value)}
                name={formData.retreat_room_type}
              />
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <HeadingsCmp
            heading="What is the purpose of this retreat? *"
            subHeading="Choose as many as you like"
          />
          <MultiChipsCmp
            data={purposeData}
            xs={6}
            onChange={(sel) => updateFormValue("retreat_purpose", sel.toString())}
          />
        </Box>
        <Box display="flex" flexDirection="column">
          <HeadingsCmp
            heading="Where would you like to go?"
            subHeading={`If you are open to your retreat destination, can’t decide which option sounds best, or
          wish to go somewhere entirely different, please choose “Anywhere”`}
          />
          <TextFieldWithLabelCmp
            placeholder={"Retreat Location"}
            value={formData.retreat_location}
            onChange={(e) => updateFormValue("retreat_location", e.target.value)}
            name={formData.retreat_location}
          />
          {/* <TextFieldWithDropdownCmp
            bgcolor="background.dark"
            placeholder="Create or Search Retreat"
            resultsHeading="Suggestions"
            border="none"
            onChange={(e) => updateFormValue("retreat_location", e.target.value)}
            name={formData.retreat_location}
            fetch={fetch}
            results={[
              {
                // icon: (
                // <Avatar
                //   src="https://encrypted-tbn1.gstatic.com/licensed-image?q=tbn:ANd9GcQT77RmwEbTbJYC3vwuUsFwQ6L7vR9whAE8bUpWc7Em21_WorxQ-PCsF5NYLu_ucTnsidnoIzkq8-nIkN1CUzy4M_5pcbOew6A8nQcTyMw"
                //   alt="place"
                //   sx={{ borderRadius: 3 }}
                // />
                // ),
                text: foundCountry ? name : foundCity ? name : "",
              },
              
            ]}
          /> */}
        </Box>
        <Box display="flex" flexDirection="column">
          <HeadingsCmp
            heading="Who are you planning to take on EIRA retreat? *"
            subHeading="Choose as many as you like"
          />
          <MultiChipsCmp
            data={participantsData}
            xs={6}
            height={{ xs: 80, md: 60 }}
            spacing={2}
            onChange={(sel) => updateFormValue("retreat_audience_type", sel.toString())}
          />
        </Box>
      </Box>
    </Box>
  );
}

function HeadingsCmp({ heading, subHeading }) {
  return (
    <Box mb={3} display="flex" flexDirection="column" rowGap={1}>
      <Typography variant="h5Alt" fontWeight={700}>
        {heading}
      </Typography>
      <Typography variant="body2Alt">{subHeading}</Typography>
    </Box>
  );
}

const roomTypeOptions = [
  { id: 0, cmp: "Single Occupancy (1 person / room)", value: "Single Occupancy (1 person / room)" },
  { id: 1, cmp: "Shared Occupancy (2 people / room)", value: "Shared Occupancy (2 people / room)" },
  { id: 2, cmp: "Single And Shared Occupancy", value: "Single And Shared Occupancy" },
];

const purposeData = [
  {
    id: 1,
    text: (
      <>
        Team
        <br />
        Building
      </>
    ),
    value: "Team Building",
    icon: TeamBuildingIconSvg,
  },
  {
    id: 2,
    text: (
      <>
        Employee
        <br />
        Engagement
      </>
    ),
    value: "Employee Engagement",
    icon: EmployeeEngagementIconSvg,
  },
  {
    id: 3,
    text: (
      <>
        Celebrating
        <br />
        Achievements
      </>
    ),
    value: "Celebrating Achievements",
    icon: AchievementsIconCmp,
  },
  {
    id: 4,
    text: (
      <>
        Training &
        <br />
        Development
      </>
    ),
    value: "Training Development",
    icon: TrainingIconCmp,
  },
  {
    id: 5,
    text: (
      <>
        Morale &
        <br />
        Wellbeing
      </>
    ),
    value: "Moral & Wellbeing",
    icon: WellBeingIconCmp,
  },
  {
    id: 6,
    text: <>Other</>,
    value: "Other",
    icon: EllipsisIconCmp,
  },
];

const participantsData = [
  {
    id: 1,
    text: "Entire Company",
    value: "Entire Company",
  },
  {
    id: 2,
    text: "Team or Department",
    value: "Team or Department",
  },
  {
    id: 3,
    text: "Leadership Team",
    value: "Leadership Team",
  },
  {
    id: 4,
    text: "Top Performers benefit",
    value: "Top Performers benefit",
  },
];
