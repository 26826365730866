import axios from "axios";

export const useUserPriceProposalApi = (data) => {
  const userPriceProposalApi = async () => {
    try {
      await axios.post("https://back.eira.ai/api/price-budgets/", {
        ...data,
        is_booked: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
  return { userPriceProposalApi };
};

export const useUserGetPriceProposalApi = () => {
  const userGetPriceProposalApi = async () => {
    try {
      const response = await axios.get("https://back.eira.ai/api/price-budgets/");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
  return { userGetPriceProposalApi };
};

export const useUserPutPriceProposalApi = () => {
  const userPutPriceProposalApi = async (id) => {
    try {
      await axios.put("https://back.eira.ai/api/price-budgets/" + id, {
        is_booked: true,
      });
    } catch (error) {}
  };
  return { userPutPriceProposalApi };
};
