import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import ImageSideBarCmp from "../ItineraryProposal/ImagesSideBarCmp";
import { ItineraryPageContext } from "./ItineraryPageContext";

export default function ItineraryTabActivitiesCmp() {
  const data = [
    "https://5.imimg.com/data5/XM/YM/JY/SELLER-54500078/football-ground-flooring.jpg",
    "https://images.indianexpress.com/2023/04/Swimming-pool-1200.jpg",
    "https://5.imimg.com/data5/AQ/LP/MY-15068556/outdoor-volleyball-court-synthetic-sports-flooring.jpg",
  ];

  return (
    <Box display="flex" columnGap={8}>
      <Box flex={3} flexShrink={0}>
        <LeftCmp />
      </Box>
      <Box flex={1.3} display={{ xs: "none", md: "block" }}>
        <ImageSideBarCmp data={data} />
      </Box>
    </Box>
  );
}

function LeftCmp() {
  const [openAboutDialog, setOpenAboutDialog] = React.useState(false);
  const [openOfferingsDialog, setOpenOfferingsDialog] = React.useState(false);
  const { retreat } = React.useContext(ItineraryPageContext);

  return (
    <Box display="flex" flexDirection="column" rowGap={5}>
      <SectionCmp heading="About the Workspace">
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="body1" lineHeight={1.8}>
            {retreat.activity_desc}
          </Typography>
          {/* <Box mt={1} sx={{ cursor: "pointer" }} onClick={() => setOpenAboutDialog(true)}>
          <IconWithTextSimpleChipCmp
            icon={ArrowHorizontalIconCmp}
            label="Show More"
            reverse
            color="primary.main"
            fontWeight={700}
          />
        </Box> */}
        </Box>
      </SectionCmp>
      {/* <Grid xs={12} container columnGap={2} rowGap={2}>
      {proposal.workspace_items.map((el) => {
        return (
          <Grid bgcolor="secondary.main" width="48%" padding={2} borderRadius={2}>
            <Typography variant="body1" color="primary" fontWeight={700}>
              {el.title}
            </Typography>
            <Box mt={1} />
            {el.items.map((item) => {
              return (
                <li style={{ fontSize: "14px", fontFamily: "Inter", fontWeight: 500 }}>{item}</li>
              );
            })}
          </Grid>
        );
      })}
    </Grid> */}
      <SectionCmp heading="Outdoor Activities">
        <RetreatCardMainCmp
          activityTitle={retreat.outdoor_activity_title.split("; ")}
          activityTitleLength={retreat.outdoor_activity_title.split("; ").length}
        />
      </SectionCmp>
      <SectionCmp heading="Indoor Activities">
        <RetreatCardMainCmp
          activityTitle={retreat.indoor_activity_title.split("; ")}
          activityTitleLength={retreat.indoor_activity_title.split("; ").length}
        />
      </SectionCmp>
      {/* <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Typography variant="body1" lineHeight={1.8}>
        {proposal.workspace_collaboration_desc2}
      </Typography>
    </Box> */}
    </Box>
  );
}

function RetreatCardMainCmp({ activityTitle, activityTitleLength }) {
  const arr = [];
  for (let i = 0; i < activityTitleLength; i++) {
    arr.push(i);
  }
  return (
    <Box>
      <Box display="flex" flexWrap="wrap" columnGap={4} rowGap={2}>
        {arr.map((el) => {
          return (
            <Box sm={4}>
              <Chip activityTitle={activityTitle[el]} contained />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

function Chip({
  icon,
  activityTitle,
  color,
  bgcolor = "secondary.main",
  contained,
  fontWeight,
  reverse,
  columnGap = 0.5,
  textColor,
  large,
  onClick,
  border,
  borderRadius,
  px,
  py,
}) {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection={reverse && "row-reverse"}
      columnGap={columnGap}
      bgcolor={contained && bgcolor}
      px={px ?? (contained && 2)}
      py={py ?? (contained && 1)}
      borderRadius={borderRadius ?? (contained && 12)}
      sx={{ cursor: onClick && "pointer" }}
      onClick={onClick}
      border={border}
    >
      <Typography
        variant={large ? "body1" : "body2"}
        color={textColor ?? color ?? "primary.main"}
        fontWeight={fontWeight}
        sx={{
          display: "-webkit-box",
          overflow: "hidden",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 1,
        }}
      >
        {activityTitle}
      </Typography>
    </Box>
  );
}

function BudgetSectionCmp() {
  return (
    <Box display="flex" columnGap={4}>
      <Box border="1.5px solid #6A855C" p={2} borderRadius={2} flexShrink={0}>
        <Typography fontSize="16px" fontWeight={600}>
          Option 1: August
        </Typography>
        <Box>
          <Typography>19.08 - 21.08.2024</Typography>
        </Box>
        <Typography fontSize="14px">140 pax</Typography>
        <Typography fontSize="14px">3 days / 2 nights</Typography>
      </Box>
      <Box>
        <BudgetCmp />
      </Box>
    </Box>
  );
}

function BudgetCmp() {
  return (
    <Box display="flex" flexDirection="column" rowGap={1}>
      <Box display="flex" flexShrink={0} alignItems="center">
        <Typography>Accomodation </Typography>
        <Box width={48} height={2} bgcolor="secondary.main" ml={1} mr={1}></Box>
        <Typography>€ 24000</Typography>
      </Box>
      <Box display="flex" flexShrink={0} alignItems="center">
        <Typography>Activities </Typography>
        <Box width={48} height={2} bgcolor="secondary.main" ml={1} mr={1}></Box>
        <Typography>€ 24000</Typography>
      </Box>
      <Box display="flex" flexShrink={0} alignItems="center">
        <Typography>Food & Bev </Typography>
        <Box width={48} height={2} bgcolor="secondary.main" ml={1} mr={1}></Box>
        <Typography>€ 24000</Typography>
      </Box>
      <Box display="flex" flexShrink={0} alignItems="center">
        <Typography>Workspace </Typography>
        <Box width={48} height={2} bgcolor="secondary.main" ml={1} mr={1}></Box>
        <Typography>€ 24000</Typography>
      </Box>
      <Box display="flex" flexShrink={0} alignItems="center">
        <Typography>EIRA Service Fee </Typography>
        <Box width={48} height={2} bgcolor="secondary.main" ml={1} mr={1}></Box>
        <Typography>€ 24000</Typography>
      </Box>
      <Divider sx={{ marginTop: 1, marginBottom: 1, backgroundColor: "primary.main" }} />
      <Box display="flex" flexShrink={0} alignItems="center">
        <Typography>Total </Typography>
        <Box width={48} height={2} bgcolor="secondary.main" ml={1} mr={1}></Box>
        <Typography>€ 24000</Typography>
      </Box>
      <Box display="flex" flexShrink={0} alignItems="center">
        <Typography fontWeight={600}>Total Price(incl. VAT) </Typography>
        <Box width={48} height={2} bgcolor="secondary.main" ml={1} mr={1}></Box>
        <Typography>€ 24000</Typography>
      </Box>
      <Typography sx={{ marginTop: 1 }} fontSize="14px" ml="auto">
        (Price Per Participant: € 1200)
      </Typography>
    </Box>
  );
}

function SectionCmp({ heading, children, headingExtra }) {
  return (
    <Box>
      <Box display="flex" alignItems="center" columnGap={2}>
        <Typography variant="h5Alt" fontWeight={600}>
          {heading}
        </Typography>
        {headingExtra}
      </Box>
      <Box pt={2} />
      {children}
    </Box>
  );
}
