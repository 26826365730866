import { PageMainContentsLayoutCmp, PageSectionLayoutCmp } from "../../components/Layouts";
import MyProposalContextProvider from "./MyProposalContext";
import UpcomingPopularRetreatsCmp from "./ProposalUpcomingRetreat";

export default function MyProposalPage() {
  return (
    <MyProposalContextProvider>
      <MyProposalMainPage />
    </MyProposalContextProvider>
  );
}

function MyProposalMainPage() {
  return (
    <PageMainContentsLayoutCmp>
      <PageSectionLayoutCmp title="My Proposals">
        <UpcomingPopularRetreatsCmp />
      </PageSectionLayoutCmp>
    </PageMainContentsLayoutCmp>
  );
}
