import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  AdminIconCmp,
  CheckIconCmp,
  PersonalBrandingCmp,
  RemoteNetworkIconCmp,
} from "../components/Icons";

export const toolBoxCardData = [
  {
    id: 0,
    image: "/images/toolBox1.jpg",
    section: "Purpose",
    icon: <CheckIconCmp />,
    text: "What to bring",
    title: "The Purpose First Principle",
    shortDesc:
      "Purpose, vision, strategy, goals, tactics, mission. Who is supposed to keep track of all this? We’re here to help you set it straight.",
    longDesc:
      "It all starts with a purpose: companies need a deeper meaning and reason for being in order to survive in the long term and inspire employees and customers alike. This is certainly not a new realization, but nevertheless many companies find it difficult to track down this purpose and formulate it explicitly. But does this mean that companies now only need one purpose and all the other management concepts have become superfluous? Not at all! They all still have their purpose. Our brief presentation is intended to help to better categorize which instrument serves which purpose.",
    amountOfPeople: "Single or group work",
    time: "60 minutes",
    tocItems: [
      {
        label: "Purpose",

        desc: "It is rightly placed at the beginning, because it is like a kind of inner compass that provides orientation at all times, even in times of great uncertainty and constantly changing weather conditions. `What are we needed for? What would serve this purpose best right now?` These questions can always be and can usually be answered.",
      },
      {
        label: "Vision",

        desc: "A purpose is no substitute for a vision, i.e. a very concrete picture of what the world will look like in a few years' time, what role your own company will play in it and what contribution it will have made. In our picture, the vision is the island on the horizon, i.e. not an abstract set of words, but a very concrete view of the future.",
      },
      {
        label: "Strategy",

        desc: "A strategy is the answer to the question `What is currently the most promising path we can take towards our vision?` It is like a map that helps with planning and target control. If the environment changes, a new map is needed, and if the map is not to hand (or is out of date), a return to the purpose helps to overcome uncertainties.",
      },
      {
        label: "Goals",

        desc: "Goals are the islands in our picture - in other words, very specific intermediate stages that can be achieved within a manageable period of a few weeks or months, for example. These stages are constantly re-planned and re-adjusted, and they show us in our daily work which next step makes the most sense.",
      },
      {
        label: "Tactics",

        desc: "They are strategies on a small scale, i.e. they are dedicated to the question of which is the smartest move on the way to the next goal.",
      },
      {
        label: "Mission",

        desc: "In this picture, a mission is interchangeable with a purpose, i.e. also a compass and reason for being. However, there is an important difference: a purpose is understood more as a deeper meaning that emerges from the company and the people who work in it. A mission, on the other hand, can also be conceived top-down: What does the company want to be, what is its mission, for what purpose was it founded?",
      },
    ],
    tracker: [
      {
        label: "Purpose",
        subHead: [],
      },
      {
        label: "Vision",
        subHead: [],
      },
      {
        label: "Strategy",
        subHead: [],
      },
      {
        label: "Goals",
        subHead: [],
      },
      {
        label: "Tactics",
        subHead: [],
      },
      {
        label: "Mission",
        subHead: [],
      },
    ],
  },
  {
    id: 1,
    image: "/images/toolBox2.jpg",
    section: "Team Dynamics",
    icon: <RemoteNetworkIconCmp />,
    text: "Leadership",
    title: "Checking In & Checking Out",
    shortDesc: "Two small habits that can fundamentally change every workplace",
    longDesc:
      "The future of work is mindful. Mindful in the sense that it is no longer normal for everyone to play a professional role that is maintained with the greatest of effort. Instead, it is becoming more and more normal for us to show ourselves as whole people. This includes two things: on the one hand, that we understand ourselves better, including the emotions and thoughts with which we are currently here, on the other hand, that we learn to share these thoughts with others so that they know how we are feeling and how to interpret our behavior. Two wonderfully simple habits to work on are check-in and check-out, which can be incorporated at the beginning and end of every meeting or even a joint working day. In its simplest form, this means that we come together as a group together, ask ourselves one or a maximum of two questions, which everyone then answers in turn.",
    amountOfPeople: "Any",
    time: "1 - 5 min",
    tocItems: [
      {
        label: "Check-Ins",
        subHead: ["Important Check-In Rules"],
        desc: (
          <>
            Typical Check-In Questions: <br /> <br />• How am I here today, what has my attention?
            <br /> • How am I feeling today, what is on my mind right now?
            <br /> • How did I get to this appointment, what are my thoughts on it?
            <br /> • What needs to be said in order to get started?
            <br />
            <br /> Depending on the type of meeting, completely different questions can also be
            asked that can help to jointly focus on the topic at hand or create context.
            <br />
            <br />
            For example: <br />
            <br />• Name one thing that the others do not yet know about you (e.g. in a team
            workshop that focuses on the strengths and skills of the team members)
            <br />
            • What am I grateful for, what do I celebrate and what do I mourn? (e.g. at a conflict
            meeting or a team retrospective)
            <br />
            • In what role are you here today? (When it comes to roles and e.g. governance)
            <br />
            • If you were music, as what song (also works with animals, movies, weather, etc.) would
            you be here today?
            <br />
            Having an (albeit brief) check-in always has positive effects:
            <br />
            <br />
            We create context for each other, i.e. we know how we are each there. It's often good to
            know that a colleague hasn't slept well because their child has been crying all night.
            Or that someone is distracted ("I'm waiting for an important email and have to check my
            smartphone from time to time"). It helps to be in the know and not have to guess why a
            person is behaving differently than usual, for example. ("Is he bored or
            disrespectful?") A check-in also helps quieter colleagues to arrive and show that they
            are there. This increases the likelihood that they will also say something later. And
            for some people, the check-in helps them to feel inside themselves and find out how they
            are feeling. For some it's a matter of course, for others it's a helpful exercise in
            self-reflection.
            <br />
            <br />
            <Typography variant="h6Alt" fontWeight={600}>
              Important Check-In Rules
            </Typography>
            <br />
            To make check-in more effective, here are the most important rules:
            <br />
            <br />
            1. Only one person speaks at a time. No discussions, no comments on what someone else
            has said. Why? The check-in is intended as a safe space where everyone can say what they
            are feeling without censoring themselves because of possible comments and assessments by
            colleagues.
            <br />
            2. It is okay to say nothing (or only briefly "I'm here"). The check-in is merely an
            invitation to share, not an obligation. If you are frustrated because so little is being
            shared, see it as a challenge and change the check-in questions.
            <br />
            3. The check-in doesn't have to be long. The rule of thumb is: the longer the meeting,
            the more time for check-in and check-out. Neither should take up more than 5 percent of
            the total time.
          </>
        ),
      },
      {
        label: "Check-Outs",
        subHead: [],
        desc: (
          <>
            Now let's move on to the check-out. Just as it makes sense to consciously arrive and
            pause for a moment, it also makes sense to take a moment to reflect at the end of an
            appointment. The same rules apply here as for check-in; the final question should also
            be based on what seems to make the most sense in this context. The check-out has the
            additional function of making joint learning possible ("How can we make the meeting even
            better next time?").
            <br />
            <br />
            Possible questions to check out:
            <br />• How do I leave the meeting, what do I take with me?
            <br />• How do I feel now?
            <br />• What still needs to be said in order to end the meeting well?
            <br />• What can we do better or differently next time?
            <br />• On a scale of one to ten, how much energy/motivation do I leave the meeting
            with?
            <br />• Hashtag checkout (for very short checkouts)
            <br />• Twitter checkout (140 characters)
            <br />• Why is it important that we had this meeting? (to reinforce a positive view of
            the meeting)
            <br />
            <br />
            Of course, you can also check in and out all by yourself: In the simplest version, both
            are just short moments of silence, pausing and sensing yourself in order to be fully
            present and to leave behind what came before.
          </>
        ),
      },
    ],
    tracker: [
      {
        label: "Check-Ins",
        subHead: ["Important Check-In Rules"],
      },
      {
        label: "Check-Outs",
        subHead: [],
      },
    ],
  },
  {
    id: 2,
    image: "/images/toolBox3.jpg",
    section: "People and Culture",
    icon: <AdminIconCmp />,
    text: "Branding",
    title: "The Meeting-Meter",
    shortDesc:
      "A tool that helps you as a team to evaluate how much time you spend in meetings meetings - and what needs to change.",
    longDesc:
      "Before you get started, you need a common understanding of what you consider to be a meeting and what is not. For meetings that are primarily about exchanging information, the classification is fairly clear. However, work meetings in which you work together on your topics could also be considered normal working time, for example. It is important that all participants apply the same standards so that you can discuss your results in a meaningful way at the end of the exercise. So, if necessary, be sure to talk about what counts as a meeting for you - and what does not.",
    amountOfPeople: "3-10 people (ideally the whole team)",
    time: "90 to 120 minutes",
    tocItems: [
      {
        label: "Step 1: What is the absolute maximum meeting duration? (10 minutes)",
        subHead: [],
        desc: (
          <>
            As a first step, everyone should reflect for themselves:
            <br />
            <br />
            <Typography variant="h6Alt" fontWeight={600}>
              ??? How many hours per week do I want to spend in meetings ???
            </Typography>
            <br />
            <br />
            What is the maximum number of hours that could be spent each week so that preparation
            and follow-up really do fit into the same week. Preparation and follow-up include break
            times between meetings, but also tasks that arise from a meeting. There is no point in
            being so full of meetings that it is impossible to take to-dos from them with you.
            <br />
            <br />
            As a rough guide for most people, a maximum of 20 hours of meeting time per week is
            feasible in a 40-hour week. The exact meeting capacity is of course very individual.
            That is why this reflection exists.
          </>
        ),
      },
      {
        label: "Step 2: What is my ideal “Target State”? (15 minutes)",
        subHead: [],
        desc: (
          <>
            In the second step, you answer the question of what your dream state looks like:
            <br />
            <br />
            <Typography variant="h6Alt" fontWeight={600}>
              How many hours would I like to spend in meetings in a perfect week? What would be my
              ideal target state and why?
            </Typography>
            <br />
            <br />
            First of all, think about what other types of work you need time for (e.g. focus work
            (e.g. focus work, training, reflection ...) and how much time you need for this each
            week
            <br />
            <br />
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 450 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>

                    <TableCell align="right">Hours per Week</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key="bye" sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      Focus Work
                    </TableCell>
                    <TableCell align="right">12</TableCell>
                  </TableRow>
                  <TableRow key="bye" sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      Personal Development
                    </TableCell>
                    <TableCell align="right">5</TableCell>
                  </TableRow>
                  <TableRow key="bye" sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      Reflection
                    </TableCell>
                    <TableCell align="right">5</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            Subtract these time budgets from your total working time. Then you will know how much
            time you have left for meetings and their preparation and follow-up. As a result, you
            should formulate a clear figure that indicates your average number of hours per week for
            meetings.
          </>
        ),
      },
      {
        label: "Step 3: What is my current state? (15 minutes)",
        subHead: [],
        desc: (
          <>
            Next, you should find out how many hours you actually spend in meetings in an average
            week. The calendar exercise will help you with this: scan your calendar from the last
            four to six weeks and determine the average value.
            <br />
            <br />
            <Typography variant="h6Alt" fontWeight={600}>
              How many hours per week do I currently spend in meetings?
            </Typography>
            <br />
            <br />
            <TableContainer component={Paper} sx={{ width: 450 }}>
              <Table sx={{ minWidth: 350 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Calendar Week</TableCell>

                    <TableCell align="right">Monday</TableCell>
                    <TableCell align="right">Tuesday</TableCell>
                    <TableCell align="right">Wednesday</TableCell>
                    <TableCell align="right">Thursday</TableCell>
                    <TableCell align="right">Friday</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key="bye" sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      CW 30
                    </TableCell>
                    <TableCell align="right">4h</TableCell>
                    <TableCell align="right">5h</TableCell>
                    <TableCell align="right">7h</TableCell>
                    <TableCell align="right">3h</TableCell>
                    <TableCell align="right">1h</TableCell>
                    <TableCell align="right">20h</TableCell>
                  </TableRow>
                  <TableRow key="bye" sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      CW 31
                    </TableCell>
                    <TableCell align="right">2h</TableCell>
                    <TableCell align="right">4h</TableCell>
                    <TableCell align="right">2h</TableCell>
                    <TableCell align="right">3h</TableCell>
                    <TableCell align="right">1h</TableCell>
                    <TableCell align="right">12h</TableCell>
                  </TableRow>
                  <TableRow key="bye" sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      CW 32
                    </TableCell>
                    <TableCell align="right">4h</TableCell>
                    <TableCell align="right">3h</TableCell>
                    <TableCell align="right">5h</TableCell>
                    <TableCell align="right">1h</TableCell>
                    <TableCell align="right">1h</TableCell>
                    <TableCell align="right">13h</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            Calculation aid: Add up your weekly meeting hours and then divide the total by the
            number of weeks.
          </>
        ),
      },
      {
        label: "Step 4: Plot onto Meeting-Meter (45 minutes)",
        subHead: [],
        desc: (
          <>
            In the last step, you compile the individual results in the team. The best way to do
            this is to transfer the matrix below to a (virtual) whiteboard or use our template on
            page 6. The actual values from step 3 are shown on the x-axis (bottom right) and the
            target values from step 2 on the y-axis (top left).
            <br />
            <br />
            Draw your current location on the matrix: What is your actual value? What would be your
            target value? All team members therefore locate themselves with a point between their
            actual and their target. Of course, it would be desirable if you landed on the line that
            is drawn with a dashed line. is drawn.
            <br />
            <br />
            Each person then briefly shares how they came to this conclusion: also feel free to say
            how many hours of meetings per week you could tolerate at most and whether there are
            specific reasons why your target is particularly high or low. Perhaps for example, do
            meetings generally tend to give or take away energy? The individual target can vary
            greatly within a team, and it's good to know the preferences of others.
          </>
        ),
      },
      {
        label: "Step 5: Determine Next Steps (30 minutes)",
        subHead: [],
        desc: "In a final round, reflect on which people in the team have significant deviations between their actual and target status and whether (and how) this can be changed. Record all the next steps that arise together.",
      },
    ],
    tracker: [
      {
        label: (
          <>
            Step 1: What is the absolute
            <br /> maximum meeting
            <br /> duration? (10 minutes)
          </>
        ),
        subHead: [],
      },
      {
        label: (
          <>
            Step 2: What is my ideal <br /> “Target State”? (15 minutes)
          </>
        ),
        subHead: [],
      },
      {
        label: (
          <>
            Step 3: What is my current
            <br /> state? (15 minutes)
          </>
        ),
        subHead: [],
      },
      {
        label: (
          <>
            Step 4: Plot onto Meeting- <br />
            Meter (45 minutes)
          </>
        ),
        subHead: [],
      },
      {
        label: (
          <>
            Step 5: Determine Next
            <br /> Steps (30 minutes)
          </>
        ),
        subHead: [],
      },
    ],
  },
  {
    id: 3,
    image: "/images/toolBox3.jpg",
    section: "Values",
    icon: <PersonalBrandingCmp />,
    text: "Personal Branding",
    title: "The Value Check",
    shortDesc:
      "Values in organizations are often there to sound good. But they only become effective when they have a real impact on behavior. We show how this can be done.",
    longDesc: (
      <>
        Values are a difficult topic. This is mainly due to the fact that they are often used as a
        marketing tool. Then they are not values in the true sense of the word. Whether on a
        personal level (What are my values?) or on a company level (What are our shared values?), it
        makes sense to make the following distinction:
        <br />
        <br /> A) There are lived values, i.e. values in the true sense. These are the things that
        are particularly important to us and therefore take priority over other things. Lived values
        mean that these values are reflected in our day-to-day behavior. Behavior is therefore in
        sync with these values.
        <br />
        <br />
        B) There are also aspirational values, i.e. those that we would like to prioritize. They are
        not yet reflected in our day-to-day behavior. But we are striving for this.
        <br />
        <br />
        C) Then there are promotional values, i.e. those that sound good in some way and that we
        would like to ascribe to ourselves. However, these values are not actually reflected in our
        current behavior, nor are there any plans to actively change them.
      </>
    ),
    amountOfPeople: "3-10 people (ideally the whole team)",
    time: "90 to 120 minutes",
    tocItems: [
      {
        label: "Step 1: What is the absolute maximum meeting duration? (10 minutes)",
        subHead: [],
        desc: (
          <>
            This exercise starts where a list of values already exists. We assume that your team or
            company has already determined which values it wants to stand for.
            <br />
            <br />
            1) The first step is to assess whether your list contains advertising values. If this is
            the case, you should delete them. After all, working with values only makes sense if you
            aim to actually implement them at a behavioral level. You can recognize promotional
            values by asking yourself whether your team or company is making significant efforts to
            put this value into practice.
            <br />
            <br />
            2) For the practiced and aspirational values, we recommend the following exercise:
            <br />
            <br /> • Define your value in one noun and write it down. Then, answer the following
            questions just about this one value.
            <br />• What does this value look like when we live it? List specific behaviors!
            <br />• Which behavior contradicts this value? List specific examples. <br />• What
            critical situations are there in which this value should be practiced in particular?
          </>
        ),
      },
      {
        label: "Step 2: What is my current state? (15 minutes)",
        subHead: [],
        desc: (
          <>
            The exercise is designed to give you more clarity about w
            <br />
            <br />
            Of course, it is not always realistic to arrive at 100 percent. To at least get in at
            least get there, you should ask yourself the following questions:
            <br />• How can we encourage the desired behavior to be shown?
            <br />• How can we make it less likely that the undesirable behavior will be shown?
            Especially in critical situations.
            <br />• How do we give ourselves feedback?
            <br />• Which people should act as role models here and how can we ensure that their
            behavior is in line with the values?
          </>
        ),
      },
    ],
    tracker: [
      {
        label: (
          <>
            Step 1: What is the absolute
            <br /> maximum meeting
            <br /> duration? (10 minutes)
          </>
        ),
        subHead: [],
      },
      {
        label: (
          <>
            Step 2: What is my ideal <br /> “Target State”? (15 minutes)
          </>
        ),
        subHead: [],
      },
    ],
  },
];
