import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import * as React from "react";
import { TextFieldWithLabelCmp } from "../../components/Inputs";
import StandardDialogCmp from "../../components/StandardDialogCmp";
import { useUserParticipantsApi } from "../../services/apis/userParticipantsApi";
import { ItineraryPageContext } from "./ItineraryPageContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddParticipantsDialog({ handleClose, open }) {
  const { retreat } = React.useContext(ItineraryPageContext);
  const { userParticipantsApi } = useUserParticipantsApi();

  const [participants, setParticipants] = React.useState({
    user_id: localStorage.getItem("user_id"),
    trip_name: retreat.title,
    trip_id: retreat.id,
    email_id: "",
    participant_name: "",
  });

  function refreshPage() {
    window.location.reload(true);
  }

  return (
    <StandardDialogCmp
      title="Add members to your organisation"
      open={open}
      handleClose={handleClose}
    >
      <Box display="flex" flexDirection="column" rowGap={2} mt={4}>
        <Box display="flex" justifyContent="space-between" columnGap={4}>
          <TextFieldWithLabelCmp
            label="Name"
            value={participants.participant_name}
            onChange={(e) =>
              setParticipants((prev) => ({ ...prev, participant_name: e.target.value }))
            }
          />
          <TextFieldWithLabelCmp
            label="Email ID"
            value={participants.email_id}
            onChange={(e) => setParticipants((prev) => ({ ...prev, email_id: e.target.value }))}
          />
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            fullWidth
            size="small"
            onClick={async () => {
              await userParticipantsApi(participants);
              refreshPage();
            }}
          >
            Invite
          </Button>
        </Box>
      </Box>
    </StandardDialogCmp>
  );
}

const options = [
  { id: 0, cmp: "Planner", value: "Planner" },
  { id: 1, cmp: "Participant", value: "Participant" },
];
