import { Box, Typography } from "@mui/material";
import React from "react";
import { SearchIconCmp } from "../../components/Icons";
import { TextFieldWithDropdownCmp2 } from "../../components/Inputs";
import { useIsXs } from "../../hooks/useIsXsHook";
import { useUserGetRetreatPreferences } from "../../services/apis/userRetreatPreferences";
import { CreateRetreatContext } from "./CreateRetreatContext";

export default function CreateRetreatStep1Cmp() {
  const { handleNext, formData, updateFormValue, setFormData } =
    React.useContext(CreateRetreatContext);
  // const { userGetRetreatPreferences } = useUserGetRetreatPreferences(formData.retreat_name);
  const isXs = useIsXs();

  const { userGetRetreatPreferences, data } = useUserGetRetreatPreferences();
  React.useEffect(() => {
    userGetRetreatPreferences();
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      columnGap={{ xs: 0, sm: 6, md: 12 }}
      p={{ xs: 3, md: 10 }}
    >
      <Box flex={1} width="100%" height="100%" display={!isXs ? "default" : "none"}>
        <img
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          src="/images/create-retreat-1.png"
        />
      </Box>
      <Box
        flex={1}
        bgcolor="secondary.main"
        px={{ xs: 2, sm: 3 }}
        py={{ xs: 3, sm: 6 }}
        borderRadius={4}
        textAlign="center"
      >
        <Typography variant="h4Alt" color="primary" fontWeight={700}>
          Lets plan the retreat of a lifetime!
        </Typography>
        <Box pt={0.5} />
        <Typography variant="body1Alt" color="grey">
          To get started, give your retreat a name
        </Typography>
        <Box pt={2} />
        <TextFieldWithDropdownCmp2
          value={formData.retreat_name}
          onChange={(e) => updateFormValue("retreat_name", e.target.value)}
          name={formData.retreat_name}
          autoFocus
          placeholder="Create or Search Retreat"
          actionText="Create new retreat"
          resultsHeading="Your Retreats"
          border="none"
          results={data
            .filter((el) => el.user_id == localStorage.getItem("user_id"))
            .reduce((acc, curr) => {
              if (acc.findIndex((el) => el.retreat_name === curr.retreat_name) === -1) {
                acc.push(curr);
              }
              return acc;
            }, [])
            .map((el) => ({
              icon: <SearchIconCmp fontSize="small" sx={{ color: "text.light" }} />,
              text: el.retreat_name,
            }))}
          onClickAction={() => {
            // userGetRetreatPreferences();
            handleNext();
          }}
        />
      </Box>
    </Box>
  );
}
