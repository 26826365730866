import { Box, Button, Typography } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { IconWithTextSimpleChipCmp } from "../../components/Chips";
import { FireIconCmp, LocationIconCmp } from "../../components/Icons";
import OfferingGridCmp from "../../components/OfferingCmp";
import StandardDialogCmp from "../../components/StandardDialogCmp";
import { useUserBookingPopularApi } from "../../services/apis/userBookingApi";

export default function AboutPlaceDialogCmp({
  open,
  handleClose,
  images = [],
  desc = "Immerse yourself in nature and pure luxury of Shangrila Ranao situated on top of Tandi Hill near Jibhi. Not only yu can pour yourself a hot bubbl bath, but also enjoy stunning views right from your bthtub.",
  locationHighlights = ["Fabulous Spa", "Amazing surf experience", "Thrilling Trek"],
  data = [
    {
      icon: FireIconCmp,
      label: "Fabulous Spa",
      content: "A room with wifi that's well suited for working",
    },
    {
      icon: FireIconCmp,
      label: "Dedicated Workspace",
      content: "A room with wifi that's well suited for working",
    },
    {
      icon: FireIconCmp,
      label: "Dedicated Workspace",
      content: "A room with wifi that's well suited for working",
    },
  ],
  isDisplay = "none",
  purpose = {},
  userEmail,
}) {
  const navigate = useNavigate();
  const { userBookingPopularApi } = useUserBookingPopularApi();
  return (
    <StandardDialogCmp open={open} handleClose={handleClose}>
      <Box display="flex" flexDirection="column" rowGap={4}>
        <Box>
          <Typography variant="h5Alt" fontWeight={600}>
            About this space
          </Typography>
          <Box pt={2} />
          <Box display="flex" justifyContent="space-between">
            {images.map((el, idx) => {
              return (
                <img
                  key={idx}
                  src={el}
                  width="130px"
                  height="100px"
                  style={{ borderRadius: "20px" }}
                />
              );
            })}
          </Box>
          <Box pt={2} />
          <Typography variant="body1" lineHeight={1.5}>
            {desc}
          </Typography>
        </Box>

        <Box display="flex" flexWrap="wrap" columnGap={2} rowGap={2}>
          {locationHighlights.map((el, idx) => {
            return (
              <IconWithTextSimpleChipCmp
                key={idx}
                icon={LocationIconCmp}
                label={el.label}
                contained
              />
            );
          })}
          {/* <IconWithTextSimpleChipCmp
            icon={WavesIconCmp}
            label="Amazing surf experience"
            contained
          />
          <IconWithTextSimpleChipCmp icon={TrekIconCmp} label="Thrilling Trek" contained /> */}
        </Box>
        <Box>
          <Typography variant="h5Alt" fontWeight={600}>
            What this place offers
          </Typography>
          <Box>
            <OfferingGridCmp data={data} />
          </Box>
        </Box>
        <Button
          variant="outlined"
          sx={{ display: isDisplay }}
          onClick={async () => {
            await userBookingPopularApi({ userEmail });
            navigate("/create-retreat?step=2");
          }}
        >
          Book A Meeting
        </Button>
      </Box>
    </StandardDialogCmp>
  );
}
