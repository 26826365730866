import React from "react";
import { PageMainContentsLayoutCmp, PageSectionLayoutCmp } from "../../components/Layouts";
import ItineraryProposalBodyCmp from "./ItineraryProposalBody";
import ItineraryPropsalHeaderCmp from "./ItineraryProposalHeader";
import ItineraryProposalPageContextProvider, {
  ItineraryProposalPageContext,
} from "./ItineraryProposalPageContext";

export default function ItineraryProposalPage() {
  return (
    <ItineraryProposalPageContextProvider>
      <ItineraryPageMain />
    </ItineraryProposalPageContextProvider>
  );
}

function ItineraryPageMain() {
  const { proposal } = React.useContext(ItineraryProposalPageContext);

  if (proposal === null) return <div>loading...</div>;

  return (
    <PageMainContentsLayoutCmp>
      <PageSectionLayoutCmp title="My Proposal">
        <ItineraryPropsalHeaderCmp />
        <ItineraryProposalBodyCmp />
      </PageSectionLayoutCmp>
    </PageMainContentsLayoutCmp>
  );
}
