import { Box, Typography } from "@mui/material";
import React from "react";
import { IconWithTextSimpleChipCmp } from "../../components/Chips";
import { CheckCircleIconCmp, CrossCircleIconCmp } from "../../components/Icons";
import { ItineraryProposalPageContext } from "./ItineraryProposalPageContext";

export default function ItineraryTabProposalPolicyCmp() {
  return (
    <Box display="flex" columnGap={8}>
      <LeftCmp />
    </Box>
  );
}

function LeftCmp() {
  const { proposal } = React.useContext(ItineraryProposalPageContext);

  const arr1 = [];
  for (let i = 0; i < proposal.policy_dos.split("; ").length; i++) {
    arr1.push(i);
  }

  const arr2 = [];
  for (let i = 0; i < proposal.policy_donts.split("; ").length; i++) {
    arr2.push(i);
  }
  return (
    <Box display="flex" flexDirection="column" rowGap={4}>
      <Typography variant="h5Alt" fontWeight={700}>
        Terms & Conditions
      </Typography>
      <Typography lineHeight={1.8}>{proposal.policy_short_desc}</Typography>
      <Box display="flex" flexDirection="column" rowGap={2}>
        {arr1.map((el) => {
          return (
            <IconWithTextSimpleChipCmp
              icon={CheckCircleIconCmp}
              label={proposal.policy_dos.split("; ")[el]}
              fontWeight={500}
              columnGap={1}
              textColor="text.main"
            />
          );
        })}
        {arr2.map((el) => {
          return (
            <IconWithTextSimpleChipCmp
              icon={CrossCircleIconCmp}
              label={proposal.policy_donts.split("; ")[el]}
              fontWeight={500}
              columnGap={1}
              textColor="text.main"
              color="red"
            />
          );
        })}
      </Box>
    </Box>
  );
}
