import axios from "axios";

export const useUserManageProposaApi = () => {
  const userManageProposalApi = async (data, id) => {
    try {
      await axios.post("https://back.eira.ai/api/manage-proposals/", {
        ...data,
        proposal_id: id,
      });
    } catch (error) {
      console.log(error);
    }
  };
  return { userManageProposalApi };
};

export const useUserManageProposaGetApi = () => {
  const userManageProposalGetApi = async (data, id) => {
    try {
      const manageProposalData = await axios.get("https://back.eira.ai/api/manage-proposals/");
      return manageProposalData.data;
    } catch (error) {
      console.log(error);
    }
  };
  return { userManageProposalGetApi };
};

export const useUserManageProposalDeleteApi = () => {
  const userManageTripsDeleteApi = async (id) => {
    try {
      await axios.delete("https://back.eira.ai/api/manage-proposals/" + id);
    } catch (error) {
      console.log(error);
    }
  };
  return { userManageTripsDeleteApi };
};
