import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { MultiChipsCmp } from "../../components/Chips";
import {
  AchievementsIconCmp,
  EmployeeEngagementIconSvg,
  TeamBuildingIconSvg,
  TrainingIconCmp,
} from "../../components/Icons";
import QACmp from "../../components/QACmp";

export default function SupportInitialPageCmp() {
  const [chipChoosen, setChipChoosen] = React.useState();
  return (
    <Box display="flex" flexDirection="column" rowGap={8}>
      <HeaderCmp />
      {/* <ChipsCmp setChipChoosen={setChipChoosen} /> */}
      <DetailsCmp chipChoosen={chipChoosen} />
    </Box>
  );
}

function HeaderCmp() {
  const navigate = useNavigate();

  return (
    <Box
      p={4}
      borderRadius={4}
      bgcolor="secondary.main"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      columnGap={8}
    >
      <Box display="flex" flexDirection="column" rowGap={2}>
        <Typography variant="h2Alt" fontWeight={700} color="primary">
          Hello, how can we help?
        </Typography>
        <Typography variant="body2Alt">
          Team retreats are in-person gatherings for teams to reconnect, collaborate, brainstorm, or
          simply have fun together. They provide an excellent opportunity for teams to take a
          reenergizing break from their everyday office or home office setup, facilitating
          reconnection, inspiration, and enhanced collaboration.
        </Typography>
      </Box>
      <Box height={250} display={{ xs: "none", md: "block" }}>
        <img src="/images/support.png" alt="support" style={{ height: "100%" }} />
      </Box>
    </Box>
  );
}

function ChipCmp({ label }) {
  return (
    <Box borderRadius={10} px={2} py={0.5} bgcolor="rgba(255,255,255,0.5)">
      <Typography variant="body2" color="primary">
        {label}
      </Typography>
    </Box>
  );
}

function ChipsCmp({ setChipChoosen }) {
  return (
    <Box margin="0 auto" textAlign="center" width={{ xs: "100%", md: "60%" }} maxWidth={800}>
      <Typography variant="h5" color="text.light">
        or choose a category to find the help you need
      </Typography>
      <Box pt={1} />
      <MultiChipsCmp
        data={data}
        spacing={2}
        maxSelectItems={1}
        xs={6}
        sm={3}
        md={3}
        onChange={(sel) => setChipChoosen(sel.toString())}
      />
    </Box>
  );
}

const data = [
  {
    id: 1,
    text: (
      <>
        Team
        <br />
        Building
      </>
    ),
    value: "Team Building",
    icon: TeamBuildingIconSvg,
  },
  {
    id: 2,
    text: (
      <>
        Employee
        <br />
        Engagement
      </>
    ),
    value: "Employee Engagement",
    icon: EmployeeEngagementIconSvg,
  },
  {
    id: 3,
    text: (
      <>
        Celebrating
        <br />
        Achievements
      </>
    ),
    value: "Celebrating Achievements",
    icon: AchievementsIconCmp,
  },
  {
    id: 4,
    text: (
      <>
        Training &
        <br />
        Development
      </>
    ),
    value: "Training & Development",
    icon: TrainingIconCmp,
  },
];

function DetailsCmp({ chipChoosen }) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" rowGap={3}>
      <Box textAlign="center">
        <Typography variant="h3Alt" fontWeight={700}>
          {chipChoosen}
        </Typography>
        <Box pt={1} />
        <Typography variant="body2Alt" color="text.light">
          We have tried to answer all your doubts.
        </Typography>
      </Box>
      <QACmp
        title="What is a team retreat and why are they important?"
        content="Team retreats are in-person gatherings for teams to reconnect, collaborate, brainstorm, or simply have fun together. They provide an excellent opportunity for teams to take a reenergizing break from their everyday office or home office setup, facilitating reconnection, inspiration, and enhanced collaboration."
      />
      <Box width="100%">
        <Divider />
      </Box>
      <QACmp
        title="What are cyclical team retreats?"
        content="Cyclical team retreats typically occur at pre-defined intervals such as every month, 2-months, 3-months, 4-months, etc. Through its continuous and rhythmic patterns, they are an excellent tool to boost team culture and cohesion by building shared experiences and meaningful team connections long term."
      />
      <Box width="100%">
        <Divider />
      </Box>
      <QACmp
        title="Where do I start when I want to organize a company offsite with EIRA?"
        content="Don't you worry, we have got you covered! Share your preferences with us in a short survey. After that, we will start crafting your ideal team offsite experience for you and your team! Are you ready?"
      />
      <Box width="100%">
        <Divider />
      </Box>
      <QACmp
        title="How much does a three-day company retreat typically cost per person?"
        content="Well, the answer is it depends on what you are looking for... However, we fully understand that before embarking on planning a retreat, having an estimate can be extremely helpful. Therefore, based on our experience, you can expect a per-person price ranging between €1,300 and €2'500 for a three-day offsite, including accommodation, workspaces, activities, and food."
      />
      <Box width="100%">
        <Divider />
      </Box>
      <QACmp
        title="How much does EIRA cost?"
        content="We want to keep things as simple and transparent as possible. This is why we charge a flatrate of €50 per participant/per retreat day as our EIRA planning fee."
      />
    </Box>
  );
}
