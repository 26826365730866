import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PageMainContentsLayoutCmp, PageSectionLayoutCmp } from "../../components/Layouts";
import { toolBoxChipData } from "../../temp/toolBoxChipDBTemp";

export default function ToolboxPageMain() {
  return (
    <Box position="relative">
      <Box
        width="100%"
        height="100%"
        sx={{ backdropFilter: "blur(8px)", overflow: "hidden" }}
        position="absolute"
        zIndex={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          fontWeight={700}
          fontSize={24}
          color="primary.main"
          fontFamily="Lora"
          letterSpacing={1}
        >
          Coming Soon...
        </Typography>
      </Box>
      <ToolboxPage />
    </Box>
  );
}

function ToolboxPage() {
  return (
    <PageMainContentsLayoutCmp>
      <PageSectionLayoutCmp title="Toolbox">
        <HeaderCmp />
        <Box mt={4} />
        <BodyCmp />
      </PageSectionLayoutCmp>
    </PageMainContentsLayoutCmp>
  );
}

function HeaderCmp() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap={1}
      p={4}
      borderRadius={4}
      position="relative"
      bgcolor="secondary.main"
    >
      <Typography variant="h4Alt" fontWeight={600} color="primary">
        Embark on a Journey of Connection & Growth with EIRA!
      </Typography>
      <Typography variant="body2Alt">
        Discover. Connect. Thrive. Your Ultimate Retreat Experience Starts Here!
      </Typography>
      <Box
        position="absolute"
        right="5em"
        height="10.5em"
        top="-2em"
        display={{ xs: "none", md: "block" }}
      >
        <img src="/images/toolbox-1.png" alt="hero" style={{ height: "100%" }} />
      </Box>
    </Box>
  );
}

function BodyCmp() {
  const navigate = useNavigate();

  return (
    <Grid container spacing={3}>
      {toolBoxChipData.map((el, idx) => (
        <Grid key={idx} item xs={6} sm={3}>
          <Box
            width="100%"
            height={220}
            display="flex"
            flexDirection="column"
            rowGap={1}
            alignItems="center"
            justifyContent="center"
            color="text.light"
            border="1px solid"
            borderRadius={5}
            borderColor="text.light"
            textAlign="center"
            sx={{ cursor: "pointer", transition: "0.3s" }}
            onClick={() => {
              navigate("topic/" + el.id);
            }}
          >
            {el.icon}
            <Typography variant="h4Alt" color="inherit" fontWeight={500}>
              {el.label}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
