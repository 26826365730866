import {
  BarIconSvg,
  ChefIconCmp,
  CocktailIconCmp,
  DesignIconCmp,
  FireIconCmp,
  HikingIconCmp,
  HorseRidingIconCmp,
  LocationIconCmp,
  SunIconCmp,
  SurfingIconCmp,
  ViewIconCmp,
} from "../components/Icons";

export const createRetreatProposalDBTemp = [
  {
    id: 0,
    title: "Vintage-Chic Boutique Hotel in the Heart of Milan",
    image: [
      "https://www.aethos.com/milan/wp-content/uploads/aethos-milan_suite_JFK_bed_DSC6452-HDR_2000x1333-1024x683.jpg",
      "https://www.aethos.com/milan/wp-content/uploads/Aethos-Milan_pizzaria_DSC6060-2000x1333-1-1024x683.jpg",
      "https://www.aethos.com/milan/wp-content/uploads/Aethos-Milan_Zaia_interior_DSC7906-2_PION_2000x1333-1024x683.jpg",
      "https://www.aethos.com/milan/wp-content/uploads/Aethos-Milan_pizzaria_DSC6036-2000x1333-1-1024x683.jpg",
    ],
    location: "Italy",
    purpose: "Training",
    participants: "35 Participants",
    shortDesc:
      "Immerse yourself in the vibrant heart of Milan at our beautiful vintage boutique hotel, where modern luxury meets timeless elegance. Experience the perfect blend between historic charm and contemporary comfort for an unforgettable stay.",
    longDesc:
      "Immerse yourself in the vibrant energy of Milan as you find solace in the heart of the city at a beautiful vintage boutique hotel. Situated in a prime location, the hotel effortlessly blends modern luxury with the charm of yesteryears, promising a memorable stay.Explore the inviting ambiance of the bar, where mixology becomes an art,and cocktail workshops can unfold for an engaging experience. Regardless of your expertise, the bar provides a stylish setting to delve into the craft of creating the perfect cocktail.For a more intimate dining experience, a private restaurant is at your disposal. Offering not only delicious cuisine but also a refined atmosphere, the restaurant can be transformed into a private event space. Whether marking a special occasion or hosting a corporate gathering, it sets the stage for a personalized and intimate event. Discover the cultural richness of Milan and allow this vintage boutique hotel to be your elegant retreat, a haven in the heart of the city's lively spirit.",
    locationHighlights: [
      {
        icon: LocationIconCmp,
        label: "Super Central Location",
      },
      {
        icon: DesignIconCmp,
        label: "High Style & Design Factor",
      },
      {
        icon: CocktailIconCmp,
        label: "Cocktail Making Experience",
      },
      {
        icon: BarIconSvg,
        label: "Private Bar",
      },
    ],
    facilities: [
      {
        icon: FireIconCmp,
        label: "Spa & Wellness",
        content: "Experience relaxation and rejuvenation at our luxurious spa and wellness center.",
      },
      {
        icon: FireIconCmp,
        label: "Swimming Pool",
        content: "Dive into serenity and enjoy a refreshing swim in our pristine swimming pool.",
      },
      {
        icon: FireIconCmp,
        label: "Gym",
        content:
          "Stay active and energized with our state-of-the-art gym facilities, equipped for all fitness levels.",
      },
      {
        icon: FireIconCmp,
        label: "Dedicated Working Place",
        content:
          "Boost productivity in our dedicated workspace, designed for focused work and collaboration.",
      },
      {
        icon: FireIconCmp,
        label: "Climbing Wall",
        content:
          "Reach new heights and challenge yourself on our exhilarating climbing wall adventure.",
      },
      {
        icon: FireIconCmp,
        label: "Indoor Pool",
        content:
          "Take a dip regardless of the weather in our inviting indoor pool, perfect for year-round enjoyment.",
      },
    ],
  },
  {
    id: 1,
    title: "Modern-Chic Coastal Boutique Hotel",
    image: [
      "https://www.sublimecomporta.pt/media/sublime-comporta-country-retreat-amp-spa-gallerysublime_comporta_bio_pool_suites_p_100519_0001_vf.jpg",
      "https://www.sublimecomporta.pt/media/sublime-comporta-country-retreat-amp-spa-galleryguest-suites_sublime-guest-suite-3-2.jpg",
      "https://www.sublimecomporta.pt/media/sublime-comporta-country-retreat-and-spa-galleryrestaurant-sublime-comporta-miguel-cancio-martins-0280.jpg",
      "https://www.sublimecomporta.pt/media/sublime-comporta-country-house-retreat-gallerysublime-comporta-spa-5.jpg",
    ],
    location: "Portugal",
    purpose: "Wellbeing",
    participants: "90 Participants",
    shortDesc:
      "Discover coastal bliss at our modern-chic beach boutique hotel, perfectly situated by the beach. Embrace the carefree spirit of seaside living in a stylish haven where every detail is curated for a relaxed and chic escape by the ocean.",
    longDesc:
      "Nestled in the heart of the charming village of Comporta, this modern-chic coastal boutique hotel offers a unique connection to coastal living. It's more than just a beachfront escape; it's an invitation to experience the tranquility of seaside life, where the rhythmic sounds of the ocean waves create a calming backdrop for your retreat.The spa and wellness area is a haven for relaxation, staffed by expert therapists dedicated to providing a genuinely rejuvenating experience. Here, you can unwind in a serene atmosphere, allowing the soothing ambiance to envelop you and leave you feeling refreshed and revitalized. Step into the enchanting pool area, a peaceful sanctuary surrounded by lush greenery and panoramic views of the beach. Whether you're inclined to bask in the sun on a comfortable lounger or take a refreshing dip in the crystal-clear waters, the pool offers a quiet escape for those in search of serenity and a touch of luxury.",
    facilities: [
      {
        icon: FireIconCmp,
        label: "Spa & Wellness",
        content: "Experience relaxation and rejuvenation at our luxurious spa and wellness center.",
      },
      {
        icon: FireIconCmp,
        label: "Swimming Pool",
        content: "Dive into serenity and enjoy a refreshing swim in our pristine swimming pool.",
      },
      {
        icon: FireIconCmp,
        label: "Gym",
        content:
          "Stay active and energized with our state-of-the-art gym facilities, equipped for all fitness levels.",
      },
      {
        icon: FireIconCmp,
        label: "Dedicated Working Place",
        content:
          "Boost productivity in our dedicated workspace, designed for focused work and collaboration.",
      },
      {
        icon: FireIconCmp,
        label: "Climbing Wall",
        content:
          "Reach new heights and challenge yourself on our exhilarating climbing wall adventure.",
      },
      {
        icon: FireIconCmp,
        label: "Indoor Pool",
        content:
          "Take a dip regardless of the weather in our inviting indoor pool, perfect for year-round enjoyment.",
      },
    ],
    locationHighlights: [
      {
        icon: ChefIconCmp,
        label: "Chef In Villa Experience",
      },
      {
        icon: CocktailIconCmp,
        label: "Wine Tasting",
      },
      {
        icon: SurfingIconCmp,
        label: "Surfing",
      },
      {
        icon: HorseRidingIconCmp,
        label: "Horse Riding",
      },
    ],
  },
  {
    id: 2,
    title: "Luxury Mountain Boutique Hotel in the Alpes",
    image: [
      "https://www.aethos.com/monterosa/wp-content/uploads/SPA_CAMPZERO_02-2000x1333-1-1024x667.jpg",
      "https://www.aethos.com/monterosa/wp-content/uploads/Aethos-Monterosa_spa_01_2000x1333-1024x682.jpg",
      "https://www.aethos.com/monterosa/wp-content/uploads/Aethos-Monterosa-Spa-outdoor_2000X1333-1024x682.jpg",
      "https://www.aethos.com/monterosa/wp-content/uploads/Aethos-Monterosa-Rooms-Suite_2000X1333-1024x644.jpg",
    ],
    location: "Italy",
    purpose: "Leadership",
    participants: "60 Participants",
    shortDesc:
      "Located in the beautiful Italian Alps, this extraordinary design hotel offers a captivating blend of modern aesthetics and stunning natural landscapes.",
    longDesc:
      "Located in the beautiful Italian Alps, this extraordinary design hotel offers a captivating blend of modern aesthetics and stunning natural landscapes. Marvel at the picturesque views of the surrounding forests and mountains from your private terrace as you immerse yourself in the innovative and contemporary ambiance of this sanctuary in Champoluc. It's ideal for guests seeking a deep connection with the beauty of nature.",
    facilities: [
      {
        icon: FireIconCmp,
        label: "Spa & Wellness",
        content: "Experience relaxation and rejuvenation at our luxurious spa and wellness center.",
      },
      {
        icon: FireIconCmp,
        label: "Swimming Pool",
        content: "Dive into serenity and enjoy a refreshing swim in our pristine swimming pool.",
      },
      {
        icon: FireIconCmp,
        label: "Gym",
        content:
          "Stay active and energized with our state-of-the-art gym facilities, equipped for all fitness levels.",
      },
      {
        icon: FireIconCmp,
        label: "Dedicated Working Place",
        content:
          "Boost productivity in our dedicated workspace, designed for focused work and collaboration.",
      },
      {
        icon: FireIconCmp,
        label: "Climbing Wall",
        content:
          "Reach new heights and challenge yourself on our exhilarating climbing wall adventure.",
      },
      {
        icon: FireIconCmp,
        label: "Indoor Pool",
        content:
          "Take a dip regardless of the weather in our inviting indoor pool, perfect for year-round enjoyment.",
      },
    ],
    locationHighlights: [
      {
        icon: ViewIconCmp,
        label: "View Over The Italian Alps",
      },
      {
        icon: SunIconCmp,
        label: "Large Garden Area",
      },
      {
        icon: HikingIconCmp,
        label: "Climbing Call",
      },
      {
        icon: HikingIconCmp,
        label: "Hiking",
      },
    ],
  },
  {
    id: 3,
    title: "Modern-Chic Lisbon City Hotel",
    image: [
      "https://www.patiodotijolo.com/media/patio-do-tijolo-gallerypatio-do-tijolo-hotel1-1.webp",
      "https://www.patiodotijolo.com/media/patio-do-tijolo-gallerypatio-do-tijolo-hotel2-1.webp",
      "https://www.patiodotijolo.com/media/patio-do-tijolo-gallerypatio-do-tijolo-hotel18.webp",
      "https://www.patiodotijolo.com/media/patio-do-tijolo-gallerypatio-do-tijolo-hotel12-1.webp",
    ],
    location: "Lisbon, Portugal",
    purpose: "Strategy Offsite",
    participants: "40 Participants",
    shortDesc:
      "Situated in the vibrant heart of Lisbon, this small boutique hotel offers an extraordinary setting between the lively Bairro Alto and the charming Príncipe Real, just a stone's throw away from Casa das Janelas com Vista, the first creation in the city.",
    longDesc:
      "Situated in the vibrant heart of Lisbon, this small boutique hotel offers an extraordinary setting between the lively Bairro Alto and the charming Príncipe Real, just a stone's throw away from Casa das Janelas com Vista, the first creation in the city.Experience true `felicidade` or happiness, as the locals say, as you immerse yourself in the meticulous details that define the 24 rooms. Each room is thoughtfully designed as a unique stage in your journey, with restful and calm decor inspired by the surrounding ambiance. Enjoy the serenity of the small garden and the breathtaking views of Lisbon from the rooftop terrace, where every moment becomes a memorable part of your stay.",
    facilities: [
      {
        icon: FireIconCmp,
        label: "Spa & Wellness",
        content: "Experience relaxation and rejuvenation at our luxurious spa and wellness center.",
      },
      {
        icon: FireIconCmp,
        label: "Swimming Pool",
        content: "Dive into serenity and enjoy a refreshing swim in our pristine swimming pool.",
      },
      {
        icon: FireIconCmp,
        label: "Gym",
        content:
          "Stay active and energized with our state-of-the-art gym facilities, equipped for all fitness levels.",
      },
      {
        icon: FireIconCmp,
        label: "Dedicated Working Place",
        content:
          "Boost productivity in our dedicated workspace, designed for focused work and collaboration.",
      },
      {
        icon: FireIconCmp,
        label: "Climbing Wall",
        content:
          "Reach new heights and challenge yourself on our exhilarating climbing wall adventure.",
      },
      {
        icon: FireIconCmp,
        label: "Indoor Pool",
        content:
          "Take a dip regardless of the weather in our inviting indoor pool, perfect for year-round enjoyment.",
      },
    ],
    locationHighlights: [
      {
        icon: LocationIconCmp,
        label: "Located In City Center",
      },
      {
        icon: LocationIconCmp,
        label: "Rooftop Terrace With Lisbon View",
      },
    ],
  },
  {
    id: 4,
    title: "Mediterranean Beach Boutique Hotel with Ocean View",
    image: [
      "https://www.aethos.com/sardinia/wp-content/uploads/Aethos-Sardinia-Aethos-Pool-Suite-with-Sea-View-806_2_2000x1333-1024x572.jpg",
      "https://www.aethos.com/sardinia/wp-content/uploads/Aethos-Sardinia-Pool_1_2000x1333-1024x684.jpg",
      "https://www.aethos.com/sardinia/wp-content/uploads/804_6-1024x683.jpg",
      "https://www.aethos.com/sardinia/wp-content/uploads/Aethos-Sardinia-Aethos-Pool-Suite-with-Sea-View-806_10_2000x1333-1024x682.jpg",
    ],
    location: "Italy",
    purpose: "Employee Wellbeing",
    participants: "35 Participants",
    shortDesc:
      "Experience the enchanting beauty of our Mediterranean-inspired resort, surrounded by vibrant gardens. Located amidst charming porches and terraces, every room features its own separate entrance, ensuring a feeling of seclusion and calmness.",
    longDesc:
      "Experience the enchanting beauty of our Mediterranean-inspired resort, surrounded by vibrant gardens. Located amidst charming porches and terraces, every room features its own separate entrance, ensuring a feeling of seclusion and calmness. Enter into a space where you can unwind on a luxurious double bed or twin beds, complemented by a private bathroom featuring a shower. Furthermore, each accommodation comes with either a terrace or balcony furnished with a table, chairs, and chaise lounges, providing the perfect setting for complete relaxation.",
    facilities: [
      {
        icon: FireIconCmp,
        label: "Spa & Wellness",
        content: "Experience relaxation and rejuvenation at our luxurious spa and wellness center.",
      },
      {
        icon: FireIconCmp,
        label: "Swimming Pool",
        content: "Dive into serenity and enjoy a refreshing swim in our pristine swimming pool.",
      },
      {
        icon: FireIconCmp,
        label: "Gym",
        content:
          "Stay active and energized with our state-of-the-art gym facilities, equipped for all fitness levels.",
      },
      {
        icon: FireIconCmp,
        label: "Dedicated Working Place",
        content:
          "Boost productivity in our dedicated workspace, designed for focused work and collaboration.",
      },
      {
        icon: FireIconCmp,
        label: "Climbing Wall",
        content:
          "Reach new heights and challenge yourself on our exhilarating climbing wall adventure.",
      },
      {
        icon: FireIconCmp,
        label: "Indoor Pool",
        content:
          "Take a dip regardless of the weather in our inviting indoor pool, perfect for year-round enjoyment.",
      },
    ],
    locationHighlights: [
      {
        icon: LocationIconCmp,
        label: "Private Yacht Tour",
      },
      {
        icon: LocationIconCmp,
        label: "Yoga And Wellbeing Classes",
      },
      {
        icon: LocationIconCmp,
        label: "Spa Treatments",
      },
    ],
  },
];
