import axios from "axios";

export const useLogInApi = () => {
  const getLogIn = async ({ user_email, password_hash }) => {
    try {
      const responseLogin = await axios.get("https://back.eira.ai/api/user-credentials/");
      const responseCompanyProfile = await axios.get("https://back.eira.ai/api/company-profiles/");
      const responseProfile = await axios.get("https://back.eira.ai/api/user-profiles/");
      const isLoggedIn = responseLogin.data.users.find(
        (el) => el.user_email === user_email && el.password_hash === password_hash
      );

      const isLoggedProfileIn = responseProfile.data.user_profiles.find(
        (el) => el.user_id === isLoggedIn.user_id
      );

      const isLoggedCompanyProfileIn = responseCompanyProfile.data.companies.find(
        (el) => el.user_id === isLoggedIn.user_id
      );

      if (isLoggedIn !== undefined) {
        localStorage.setItem("user_id", isLoggedIn.user_id);
        localStorage.setItem("user_profile_id", isLoggedProfileIn.user_profile_id);
        localStorage.setItem("company_id", isLoggedCompanyProfileIn.company_id);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    getLogIn,
  };
};
