import { Avatar, Box, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { MultiChipsCmp } from "../../components/Chips";
import { UploadIconCmp } from "../../components/Icons";
import { TextFieldWithLabelCmp } from "../../components/Inputs";

export default function MyProfileSettingsCmp({
  setUserProfileData,
  userProfileData,
  userEmail,
  setUserEmail,
}) {
  return (
    <Grid container columnSpacing={8} rowSpacing={4}>
      <Grid item xs={12} sm={6}>
        <TextFieldWithLabelCmp
          label="First Name"
          value={userProfileData.user_first_name}
          onChange={(e) =>
            setUserProfileData((prev) => ({ ...prev, user_first_name: e.target.value }))
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldWithLabelCmp
          label="Last Name"
          value={userProfileData.user_last_name}
          onChange={(e) =>
            setUserProfileData((prev) => ({ ...prev, user_last_name: e.target.value }))
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldWithLabelCmp
          label="Email"
          value={userEmail.user_email}
          onChange={(e) => setUserEmail((prev) => ({ ...prev, user_email: e.target.value }))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldWithLabelCmp
          label="LinkedIn"
          value={userProfileData.user_linkedin_profile_url}
          onChange={(e) =>
            setUserProfileData((prev) => ({ ...prev, user_linkedin_profile_url: e.target.value }))
          }
        />
      </Grid>
      {/* <Grid item xs={12} sm={6}> */}
      {/* <TextFieldWithLabelCmp label="Company Name" /> */}
      {/* <Box pt={4} /> */}
      {/* <SelectAvatarCmp /> */}
      {/* </Grid> */}
      <Grid item xs={12} sm={12}>
        <Typography fontWeight={500} color="text.light">
          Job Title
        </Typography>
        <Box pt={1} />
        <MultiChipsCmp
          onChange={(sel) =>
            setUserProfileData((prev) => ({ ...prev, user_designation: sel.toString() }))
          }
          defaultValue={data
            .filter((el) => el.text === userProfileData.user_designation)
            .map((el) => el.id)}
          data={data}
          height={48}
          xs={12}
          sm={12}
          spacing={2}
          typographyVariant="body2"
          maxSelectItems={1}
        />
      </Grid>
    </Grid>
  );
}

function SelectAvatarCmp() {
  const avatarSize = 56;

  return (
    <Box>
      <Typography fontWeight={500} color="text.light">
        Profile Picture
      </Typography>
      <Box display="flex" flexWrap="wrap" rowGap={{ xs: 1, md: 0 }} pt={1} columnGap={1}>
        {[...new Array(5)].map((_, idx) => (
          <Avatar
            key={idx}
            selected={idx === 0}
            variant="rounded"
            src="https://mui.com/static/images/avatar/1.jpg"
            sx={{
              width: avatarSize,
              height: avatarSize,
              borderRadius: 4,
              "::before": idx === 0 && {
                content: `"✓"`,
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.4)",
              },
            }}
          ></Avatar>
        ))}
        <Box>
          <IconButton
            variant="rounded"
            sx={{
              height: avatarSize,
              width: avatarSize,
              backgroundColor: "secondary.main",
              color: "primary.main",
            }}
          >
            <UploadIconCmp />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

const data = [
  {
    id: 1,
    text: "CEO or Founder",
    value: "CEO or Founder",
  },
  {
    id: 2,
    text: "People & HR",
    value: "People & HR",
  },
  {
    id: 3,
    text: "Team Lead",
    value: "Team Lead",
  },
  {
    id: 4,
    text: "Team Member",
    value: "Team Member",
  },
  {
    id: 5,
    text: "Admin / Operations",
    value: "Admin / Operations",
  },
  {
    id: 6,
    text: "Other",
    value: "Other",
  },
  // {
  //   id: 6,
  //   text: "Marketer",
  //   value: "Marketer",
  // },
  // {
  //   id: 7,
  //   text: "Operations",
  //   value: "Operations",
  // },
  // {
  //   id: 8,
  //   text: "Administration",
  //   value: "Administration",
  // },
  // {
  //   id: 9,
  //   text: "Sales",
  //   value: "Sales",
  // },
  // {
  //   id: 10,
  //   text: "Account Management",
  //   value: "Account Management",
  // },
  // {
  //   id: 11,
  //   text: "Legal",
  //   value: "Legal",
  // },
  // {
  //   id: 12,
  //   text: "Communication",
  //   value: "Communication",
  // },
];
