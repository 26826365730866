import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import AppbarCmp from "./AppbarCmp";
import SidebarCmp from "./SidebarCmp";

export default function GeneralLayoutCmp() {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Box bgcolor="background.main" display={{ xs: "default", lg: "flex" }} minHeight="100vh">
        <SidebarCmp openForSm={open} setOpenSm={setOpen} />
        {/* added margin left as it was looking bad in small laptop size when sidebar open */}
        <Box component="main" sx={{ flexGrow: 1 }} marginLeft={{ xs: 0, sm: 0, md: 7, lg: 0 }}>
          <AppbarCmp onOpenMenu={() => setOpen(true)} />
          <Outlet />
          <Box height={{ xs: 80, md: 0 }} />
        </Box>
      </Box>
    </>
  );
}
