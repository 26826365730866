import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { UserContext } from "../../providers/UserProvider";
import { useUserBookingPopularGetApi } from "../../services/apis/userBookingApi";
import { useUserQueryGetApi } from "../../services/apis/userQueryApi";

export default function AdminPageBookingsCmp() {
  const [bookingMeetData, setBookingMeetData] = useState([]);
  const [query, setQuery] = useState([]);
  const { userBookingPopularGetApi } = useUserBookingPopularGetApi();
  const { userQueryGetApi } = useUserQueryGetApi();
  const { priceProposalData } = React.useContext(UserContext);

  const fetchTripsData = React.useCallback(async () => {
    const data = await userBookingPopularGetApi();
    setBookingMeetData(data.bookings);
  }, []);

  const fetchUserQueryData = React.useCallback(async () => {
    const data = await userQueryGetApi();
    setQuery(data.user_queries);
  }, []);

  React.useEffect(() => {
    fetchTripsData();
    fetchUserQueryData();
  }, [fetchTripsData, fetchUserQueryData]);

  return (
    <Box>
      <Box>
        <Typography fontWeight={500} color="text.light" pb={1.3}>
          Call Booking
        </Typography>
        <TableContainer component={Paper} sx={{ marginTop: 2, maxHeight: 440 }}>
          <Table sx={{ minWidth: 450 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Email Id</TableCell>
                <TableCell>Proposal Name</TableCell>
                <TableCell>Booked At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bookingMeetData.map((el) => {
                return (
                  <TableRow key={el.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell>{el.email_id}</TableCell>
                    <TableCell>{el.proposal_name}</TableCell>
                    <TableCell>{el.created_at}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box mt={4}>
        <Typography fontWeight={500} color="text.light" pb={1.3}>
          Proposal Booking
        </Typography>
        <TableContainer component={Paper} sx={{ marginTop: 2, maxHeight: 440 }}>
          <Table sx={{ minWidth: 450 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Email Id</TableCell>
                <TableCell>Option Title</TableCell>
                <TableCell>Proposal Name</TableCell>
                <TableCell>Created At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {priceProposalData
                .filter((el) => el.is_booked === true)
                .map((el) => {
                  return (
                    <TableRow
                      key={el.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {el.email_id}
                      </TableCell>
                      <TableCell>{el.option_title}</TableCell>
                      <TableCell>{el.proposal_name}</TableCell>
                      <TableCell>{el.created_at}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box mt={4}>
        <Typography fontWeight={500} color="text.light" pb={1.3}>
          User Queries
        </Typography>
        <TableContainer component={Paper} sx={{ marginTop: 2, maxHeight: 440 }}>
          <Table sx={{ minWidth: 450 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Email Id</TableCell>
                <TableCell>Message</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {query.map((el) => {
                return (
                  <TableRow key={el.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {el.user_email}
                    </TableCell>
                    <TableCell>{el.query_message}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
