import axios from "axios";

export const useUserQueryApi = ({ query_message, user_email }) => {
  const userQueryApi = async () => {
    try {
      await axios.post("https://back.eira.ai/api/user-queries/", {
        query_message: query_message,
        user_id: localStorage.getItem("user_id"),
        user_email: user_email,
        query_status: "Active",
      });
    } catch (error) {
      console.log(error);
    }
  };
  return { userQueryApi };
};

export const useUserQueryGetApi = () => {
  const userQueryGetApi = async () => {
    try {
      const response = await axios.get("https://back.eira.ai/api/user-queries/");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
  return { userQueryGetApi };
};
