import { Box } from "@mui/material";
import React from "react";
import ShopPageCardCmp from "../../components/ShopPageCardCmp";
import AboutPlaceDialogCmp from "../CreateRetreat/AboutPlaceDialogCmp";
import { ShopPageContext } from "./ShopPageContext";

export default function ShopPageWorkshopCmp({ workshopData }) {
  const { setOpenTimeDialog } = React.useContext(ShopPageContext);
  const [open, setOpen] = React.useState(false);
  return (
    <Box display="flex" flexDirection="column" rowGap={4}>
      {workshopData.map((el) => {
        return (
          <ShopPageCardCmp
            onClick={() => setOpen(true)}
            title="Brand Design Workshop with Michael"
            content="Elevate team bonding with an exhilarating surf experience in beautiful Ericeira.
            "
            image="/images/workshop-1.jpg"
            timeFromHotel={"0km from hotel"}
            hours={"5 hours"}
            time={"TDB"}
            onOpenTimeDialog={() => setOpenTimeDialog(true)}
          />
        );
      })}
      {/* <ShopPageCardCmp
        onClick={() => setOpen(true)}
        title="Online-Marketing Workshop with Marie"
        content="Elevate team bonding with an exhilarating surf experience in beautiful Ericeira.
        "
        image="/images/workshop-2.png"
        timeFromHotel={"0km from hotel"}
        hours={"2 hours"}
        time={"TDB"}
        onOpenTimeDialog={() => setOpenTimeDialog(true)}
        added
        tag
      /> */}
      <AboutPlaceDialogCmp open={open} handleClose={() => setOpen(false)} />
    </Box>
  );
}
