import React from "react";
import { useGetUser } from "../../services/apis/userApis";
import { proposalDBTemp } from "../../temp/ProposalDBTemp";

export const MyProposalContext = React.createContext("");

export default function MyProposalContextProvider({ children }) {
  const [userEmail, setUserEmail] = React.useState({
    user_email: "",
  });

  const { getUser } = useGetUser();
  const fetchInitialData = React.useCallback(async () => {
    const emailData = await getUser();
    setUserEmail(emailData);
  }, []);
  React.useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  //Booked

  const [bookedId, setBookedId] = React.useState([]);
  const [bookedCardData, setBookedCardData] = React.useState([]);

  const handleBookedCardData = (id) => {
    const idx = bookedId.findIndex((el) => el === id);
    if (idx === -1) {
      setBookedCardData([...bookedCardData, { ...proposalDBTemp.find((el) => el.id === id) }]);
    }
  };

  const handleBooking = (id) => {
    const idx = bookedId.findIndex((el) => el === id);
    if (idx === -1) {
      setBookedId([...bookedId, id]);
    } else {
      setBookedId((prev) => prev.filter((el) => el !== id));
    }
  };
  //Booked

  return (
    <MyProposalContext.Provider
      value={{
        bookedId,

        bookedCardData,
        setBookedId,

        setBookedCardData,

        handleBookedCardData,

        handleBooking,
        userEmail,
      }}
    >
      {children}
    </MyProposalContext.Provider>
  );
}
