import axios from "axios";

export const useUserGetTripsApi = () => {
  const userGetTripsApi = async (userEmail) => {
    try {
      const response = await axios.get("https://back.eira.ai/api/myproposal/trips/" + userEmail);
      return response.data;
    } catch (error) {}
  };
  return { userGetTripsApi };
};
