import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../providers/UserProvider";
import { itineraryViewEnum } from "../../utils/enums";

export const ItineraryPageContext = React.createContext(null);

export default function ItineraryPageContextProvider({ children }) {
  const [retreat, setRetreat] = useState([]);

  const [viewType, setViewType] = React.useState(itineraryViewEnum.list);
  const { proposalFullData } = React.useContext(UserContext);

  const params = useParams();
  useEffect(() => {
    if (proposalFullData.length !== 0) {
      const tripId = Number(params.tripId);
      const res = proposalFullData.find((el) => el.id == tripId);
      setRetreat(res);
    }
  }, [proposalFullData]);

  return (
    <ItineraryPageContext.Provider value={{ retreat, viewType, setViewType }}>
      {children}
    </ItineraryPageContext.Provider>
  );
}
