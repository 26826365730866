import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { Avatar, Box, Button, Divider, IconButton, Menu, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../providers/UserProvider";
import {
  ChevronArrowIconCmp,
  CreateRetreatIconCmp,
  InfoIconCmp,
  LogOutIconSvg,
  MenuIconCmp,
  NotificationIconCmp,
  ProfileIconSvg,
  SubscriptionIconSvg,
  TransferIconCmp,
} from "./Icons";

export default function AppbarCmp({ onOpenMenu }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [anchorE3, setAnchorE3] = React.useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorE2);
  const open3 = Boolean(anchorE3);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenMenuSwitchMember = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleOpenMenuProfile = (event) => {
    setAnchorE3(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseMenuSwitchMember = () => {
    setAnchorE2(null);
  };

  const handleCloseMenuProfile = () => {
    setAnchorE3(null);
  };

  const navigate = useNavigate();

  return (
    <>
      <Box display={{ xs: "none", md: "block" }}>
        <Box py={2} px={4} display="flex">
          <Box display="flex" alignItems="center" flex={1}>
            <Button
              variant="contained"
              startIcon={<CreateRetreatIconCmp />}
              onClick={() => navigate("/create-retreat")}
            >
              Craft Your Retreat
            </Button>
          </Box>
          <Box display="flex" alignItems="center" columnGap={2}>
            {/* <SwitchMemberCmp openMenu={handleOpenMenuSwitchMember} /> */}
            <ProfileCmp openMenu={handleOpenMenuProfile} />
            {/* <Box display="flex">
              <IconButton size="small" onClick={handleOpenMenu}>
                <NotificationIconCmp color={open ? "primary" : "default"} />
              </IconButton>
            </Box> */}
          </Box>
        </Box>
        <NotificationsPopupCmp open={open} anchorEl={anchorEl} handleClose={handleCloseMenu} />
        {/* <SwitchMemberPopupCmp
          anchorE2={anchorE2}
          open2={open2}
          onClose2={handleCloseMenuSwitchMember}
        /> */}
        <ProfilePopupCmp anchorE3={anchorE3} open3={open3} onClose3={handleCloseMenuProfile} />
        <Divider />
      </Box>

      <Box
        display={{ xs: "auto", md: "none" }}
        bgcolor="white"
        position="fixed"
        width="100vw"
        bottom={0}
        zIndex={1000}
        boxShadow="rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"
      >
        <Box
          py={1}
          px={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          boxShadow="rgba(50, 50, 50, 0.6) 0px -1px 8px -4px"
        >
          <Box display="flex" flexDirection="column" alignItems="center" onClick={onOpenMenu}>
            <MenuIconCmp sx={{ fontSize: 20 }} />
            <Typography fontSize="12px" fontFamily="Inter">
              Menu
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            onClick={handleOpenMenuSwitchMember}
          >
            <TransferIconCmp fontSize="small" />
            <Typography fontSize="12px" fontFamily="Inter">
              Switch
            </Typography>
          </Box>
          <Box sx={{ position: "relative", width: 64 }} display="flex" justifyContent="center">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                position: "absolute",
                width: 64,
                height: 64,
                top: -56,
                backgroundColor: "secondary.main",
                borderRadius: 100,
                boxShadow: "rgba(50, 50, 50, 0.6) 0px -1px 8px -4px",
              }}
            >
              <CreateRetreatIconCmp fontSize="medium" />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" onClick={handleOpenMenu}>
            <NotificationIconCmp fontSize="small" color={open ? "primary" : "default"} />
            <Typography fontSize="12px" fontFamily="Inter">
              Notif
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            onClick={handleOpenMenuProfile}
          >
            <Avatar
              src="https://i.pinimg.com/236x/97/43/ec/9743ecac80966a95e9d328c08b995c04.jpg"
              alt="profile picture"
              sx={{ width: 24, height: 24 }}
            />
            <Typography fontSize="12px" fontFamily="Inter">
              Profile
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

function SwitchMemberCmp({ openMenu }) {
  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" alignItems="center" columnGap={1}>
        <TransferIconCmp fontSize="small" color="primary" />
        <Typography
          variant="body1"
          color="primary"
          fontWeight={700}
          component="a"
          sx={{ textDecoration: "underline" }}
        >
          Switch to Member
        </Typography>
      </Box>
      <IconButton size="small" onClick={openMenu} sx={{ marginLeft: 0.5 }}>
        <InfoIconCmp sx={{ fontSize: 14 }} />
      </IconButton>
    </Box>
  );
}

function SwitchMemberPopupCmp({ open2, anchorE2, onClose2 }) {
  return (
    <Menu
      anchorEl={anchorE2}
      open={open2}
      onClose={onClose2}
      elevation={3}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: { xs: -5.5, md: 4 },
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&::before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: { md: 0 },
            bottom: { xs: -10 },
            right: { xs: 78, md: 14 },
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: { xs: "bottom", md: "top" } }}
      anchorOrigin={{ horizontal: "right", vertical: { xs: "top", md: "bottom" } }}
    >
      <Box>
        <Box p={2}>
          <Typography
            fontSize={12}
            fontWeight={400}
            fontFamily="Inter"
            color="primary"
            lineHeight="14.52px"
          >
            Event participant, eager to
            <br /> engage and enjoy.
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            columnGap={1}
            p={1}
            bgcolor="rgb(225, 236, 200,0.48)"
            borderRadius="10px"
            mt={1}
          >
            <TransferIconCmp fontSize="small" color="primary" />
            <Typography
              fontSize={12}
              fontFamily="Inter"
              color="primary"
              fontWeight={700}
              component="a"
              sx={{ textDecoration: "underline" }}
            >
              Switch to Member
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ padding: "16px 16px 8px 16px" }}>
          <Typography fontSize={12} fontWeight={400} fontFamily="Inter">
            Currently in Admin mode
          </Typography>
        </Box>
      </Box>
    </Menu>
  );
}

function ProfileCmp({ openMenu }) {
  const userContext = React.useContext(UserContext);
  return (
    <Box
      onClick={openMenu}
      bgcolor="white"
      p={1}
      display="flex"
      alignItems="center"
      borderRadius={3}
      columnGap={1}
      sx={{ cursor: "pointer" }}
    >
      <Avatar
        src="https://i.pinimg.com/236x/97/43/ec/9743ecac80966a95e9d328c08b995c04.jpg"
        alt="profile picture"
        sx={{ width: 32, height: 32 }}
      />
      <Typography variant="body1" component="span">
        {userContext.userProfile.user_first_name + " " + userContext.userProfile.user_last_name}
      </Typography>
      <ChevronArrowIconCmp />
    </Box>
  );
}

function ProfilePopupCmp({ open3, anchorE3, onClose3 }) {
  const navigate = useNavigate();
  const deleteUser = () => {
    // localStorage.setItem("isLoggedIn", false);
    localStorage.removeItem("user_id");
    localStorage.removeItem("company_id");
    localStorage.removeItem("user_profile_id");
    localStorage.removeItem("isLoggedIn");
  };
  return (
    <Menu
      anchorEl={anchorE3}
      open={open3}
      onClose={onClose3}
      elevation={3}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: { xs: -5.5, md: 5.5 },
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&::before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: { md: 0 },
            bottom: { xs: -10 },
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: { xs: "bottom", md: "top" } }}
      anchorOrigin={{ horizontal: "right", vertical: { xs: "top", md: "bottom" } }}
    >
      <Box>
        <Box p={2}>
          <Box
            display="flex"
            columnGap={2}
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/settings")}
          >
            <ProfileIconSvg />
            <Typography
              fontSize="16px"
              fontWeight={400}
              fontFamily="Inter"
              color=" rgba(33, 33, 33, 0.48)"
            >
              My Profile
            </Typography>
          </Box>
          <Box
            display="flex"
            mt={2}
            columnGap={2}
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/support")}
          >
            <SubscriptionIconSvg />
            <Typography
              fontSize="16px"
              fontWeight={400}
              fontFamily="Inter"
              color=" rgba(33, 33, 33, 0.48)"
            >
              Support
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          display="flex"
          columnGap={2}
          p={2}
          sx={{ cursor: "pointer" }}
          onClick={async () => {
            deleteUser();
            navigate("/login");
          }}
        >
          <LogOutIconSvg />
          <Typography
            fontSize="16px"
            fontWeight={400}
            fontFamily="Inter"
            color="rgba(221, 0, 0, 1)"
          >
            Logout
          </Typography>
        </Box>
      </Box>
    </Menu>
  );
}

function NotificationsPopupCmp({ open, anchorEl, handleClose }) {
  return (
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      elevation={3}
      MenuListProps={{
        "aria-labelledby": "long-button",
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: { xs: -5.5, md: 4.5 },
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&::before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: { md: 0 },
            bottom: { xs: -10 },
            right: { xs: 52, md: 14 },
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: { xs: "bottom", md: "top" } }}
      anchorOrigin={{ horizontal: "right", vertical: { xs: "top", md: "bottom" } }}
    >
      <Box>
        <Box pl={3} pt={2}>
          <Typography variant="h5" fontWeight={600}>
            Notifications
          </Typography>
          {[...new Array(2)].map((_, idx) => (
            <NotificationItemCmp key={idx} idx={idx} />
          ))}
        </Box>
        <Box bgcolor="#fbf7f3" p={2} borderRadius="0px 0px 24px 24px">
          <Box
            sx={{ cursor: "pointer" }}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Typography variant="paraMed">Read all</Typography>
            <CheckCircleOutlinedIcon sx={{ color: "primary.main" }} />
          </Box>
        </Box>
      </Box>
    </Menu>
  );
}

function NotificationItemCmp({ idx }) {
  return (
    <Box mr={3}>
      <Box bgcolor={idx === 1 ? "secondary.main" : undefined} p={1} borderRadius={3}>
        <Box pt={2} display="flex">
          <Box>
            <NotificationIconCmp />
          </Box>
          <Box display="flex" flexDirection="column" ml={1} width="100%">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography fontSize="16px" fontFamily="Inter" fontWeight={600} color="primary">
                Announcement
              </Typography>
              <Box
                borderRadius={100}
                width="10px"
                height="10px"
                bgcolor="primary.main"
                mr={2}
              ></Box>
            </Box>
            <Typography fontSize="16px" fontWeight={500} fontFamily="Inter" mt={0.5} mb={0.5}>
              Hey We've added a new location
              <br /> "Berlin, Germany"
            </Typography>
            <Typography
              variant="paraReg"
              sx={{ opacity: "48%" }}
              fontSize="14px"
              fontFamily="Inter"
              fontWeight={400}
              mt={0.5}
              mb={0.5}
            >
              Today at 9:42 AM
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ margin: "8px 0px 8px 0px" }} />
    </Box>
  );
}
