import { AddCircleOutline } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { DeleteIconCmp, EditIconCmp } from "../../components/Icons";
import { useUserGetCollaboratorApi } from "../../services/apis/userSetCollaborationApi";
import AddMemberDialog from "./AddMemberDialog";
import EditMemberDialog from "./EditMemberDialog";
import RemoveMemberDialog from "./RemoveMemberDialog";

export default function CollaborationSettingsCmp() {
  const [openAddMember, setOpenAddMember] = React.useState(false);
  const [openEditMember, setOpenEditMember] = React.useState(false);
  const [openRemoveMember, setOpenRemoveMember] = React.useState(false);
  const [id, setId] = React.useState();
  const [name, setName] = React.useState();

  return (
    <Box pb={10}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box flex={1}>
          <Typography fontWeight={700} fontSize={18}>
            Team Collaborations
          </Typography>
        </Box>
        {/* <Box flexShrink={1}>
          <DropdownCmp value={2} options={options} />
        </Box> */}
      </Box>
      <TableCmp
        onClickRemoveMember={() => setOpenRemoveMember(true)}
        onClickEditMember={() => setOpenEditMember(true)}
        setId={setId}
        setName={setName}
      />
      <Box display="flex" justifyContent="center" mt={{ xs: 2, sm: 4 }}>
        <Button color="primary" onClick={() => setOpenAddMember(true)}>
          <AddCircleOutline /> <Box mx={0.5} /> Invite More
        </Button>
      </Box>
      <AddMemberDialog open={openAddMember} handleClose={() => setOpenAddMember(false)} />
      <EditMemberDialog
        open={openEditMember}
        handleClose={() => setOpenEditMember(false)}
        setOpenEditMember={setOpenEditMember}
        id={id}
      />
      <RemoveMemberDialog
        open={openRemoveMember}
        handleClose={() => setOpenRemoveMember(false)}
        setOpenRemoveMember={setOpenRemoveMember}
        id={id}
        name={name}
      />
    </Box>
  );
}

const options = [
  { id: 0, cmp: "Team Building" },
  { id: 1, cmp: "Team Building" },
  { id: 2, cmp: "Team Building" },
];
const headings = ["Name", "Email", "Status", ""];
// const rowTemp = [
//   {
//     id: 1,
//     profileImage: "https://mui.com/static/images/avatar/1.jpg",
//     name: "Krishna A.",
//     email: "krishnaA@example.com",
//     status: "Planner",
//   },
//   {
//     id: 2,
//     profileImage: "https://wallpapers.com/images/hd/cool-profile-picture-l0hzjr9omwan07nt.jpg",
//     name: "Mani S.",
//     email: "MaiS@example.com",
//     status: "Participant",
//   },
//   {
//     id: 2,
//     profileImage: "https://wallpapers.com/images/hd/cool-profile-picture-1ecoo30f26bkr14o.jpg",
//     name: "Mani S.",
//     email: "MaiS@example.com",
//     status: "Invited",
//   },
// ];

function TableCmp({ onClickRemoveMember, setId, setName, onClickEditMember }) {
  const { data, userGetCollaboratorApi } = useUserGetCollaboratorApi();
  React.useEffect(() => {
    userGetCollaboratorApi();
  }, []);
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650, marginTop: 4 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headings.map((el, idx) => (
              <TableCell
                key={idx}
                align="left"
                sx={{ fontWeight: 700, color: "#21212185", fontSize: 16 }}
              >
                {el}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .filter((el) => el.user_profile_id == localStorage.getItem("user_profile_id"))

            .map((row) => (
              <TableRow
                key={row.collaborator_profile_id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                  <Avatar src={row.profileImage} />{" "}
                  <Typography>
                    {row.collaborator_first_name + " " + row.collaborator_last_name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>{row.collaborator_email_id}</Typography>
                </TableCell>
                <TableCell>
                  <Chip
                    label={row.collaborator_status}
                    sx={{
                      fontWeight: 700,
                      fontSize: 12,
                      minWidth: 100,
                      ...(row.status === "Planner" && {
                        color: "primary.main",
                        backgroundColor: "secondary.main",
                      }),
                      ...(row.status === "Invited" && {
                        color: "#212121",
                        backgroundColor: "#F1EDE9",
                      }),
                    }}
                  />
                </TableCell>
                <TableCell width={100}>
                  {row.collaborator_status === "Planner" ? (
                    <IconButton
                      onClick={() => {
                        setId(row.collaborator_profile_id);
                        onClickEditMember();
                      }}
                    >
                      <EditIconCmp />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setId(row.collaborator_profile_id);
                        setName(row.collaborator_first_name + " " + row.collaborator_last_name);
                        onClickRemoveMember();
                      }}
                    >
                      <DeleteIconCmp sx={{ color: "red" }} />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
