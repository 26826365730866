import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { MultiChipsCmp } from "../../components/Chips";
import {
  AchievementsIconCmp,
  EllipsisIconCmp,
  EmployeeEngagementIconSvg,
  TeamBuildingIconSvg,
  TrainingIconCmp,
  UploadIconCmp,
  WellBeingIconCmp,
} from "../../components/Icons";

export default function OnboardingFormStep3Cmp({
  setUserCompanyProfileOnb,
  userCompanyProfileOnb,
}) {
  return (
    <Box display="flex" flexDirection="column" rowGap={4}>
      <Box>
        <Typography variant="h5Alt" fontWeight={700}>
          How Will You Use Our Product?
        </Typography>
        <Box pt={1} />
        <Typography variant="body2Alt" color="text.light">
          Your choice here won’t limit what you can do in EIRA.
        </Typography>
      </Box>
      <Box>
        <Typography fontWeight={500} color="text.light">
          Pick one that describes your retreat planning
        </Typography>
        <Box pt={1} />
        <MultiChipsCmp
          data={data}
          spacing={2}
          maxSelectItems={1}
          xs={6}
          onChange={(sel) =>
            setUserCompanyProfileOnb((prev) => ({ ...prev, company_business_desc: sel.toString() }))
          }
        />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" columnGap={4}>
        <Box flex={1}>
          <Typography fontWeight={500} color="text.light">
            Planned a retreat before?
          </Typography>
          <Box pt={1} />
          <MultiChipsCmp
            onChange={(sel) =>
              setUserCompanyProfileOnb((prev) => ({
                ...prev,
                is_past_company_retreat: sel[0],
              }))
            }
            data={[
              { id: 0, text: "Yes", value: true },
              { id: 1, text: "No", value: false },
            ]}
            spacing={2}
            maxSelectItems={1}
            height={40}
            xs={6}
          />
        </Box>
        <Box flex={1}>
          <Typography fontWeight={500} color="text.light">
            Upload logo (if any)
          </Typography>
          <Box pt={1} />
          <Button
            variant="outlined"
            startIcon={<UploadIconCmp />}
            fullWidth
            sx={{ color: "text.light", fontWeight: 500 }}
          >
            Upload
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

const data = [
  {
    id: 1,
    text: (
      <>
        Strategy
        <br />
        Offsites
      </>
    ),
    icon: TeamBuildingIconSvg,
    value: "Strategy Offsites",
  },
  {
    id: 2,
    text: (
      <>
        Executive
        <br />
        Retreats
      </>
    ),
    icon: EmployeeEngagementIconSvg,
    value: "Executive Retreats",
  },
  {
    id: 3,
    text: (
      <>
        Celebration
        <br />
        Retreats
      </>
    ),
    icon: AchievementsIconCmp,
    value: "Celebration Retreats",
  },
  {
    id: 4,
    text: (
      <>
        Training &
        <br />
        Development
      </>
    ),
    icon: TrainingIconCmp,
    value: "Training & Development",
  },
  {
    id: 5,
    text: (
      <>
        Office-Away-From-
        <br />
        Home Packages
      </>
    ),
    icon: WellBeingIconCmp,
    value: "Morale & Wellbeing",
  },
  {
    id: 6,
    text: <>Other</>,
    icon: EllipsisIconCmp,
    value: "Other",
  },
];
