import {
  AdminIconCmp,
  CheckIconCmp,
  PersonalBrandingCmp,
  RemoteNetworkIconCmp,
} from "../components/Icons";

export const toolBoxChipData = [
  {
    id: 0,
    label: "Retreat Checklist",
    icon: <CheckIconCmp />,
  },
  {
    id: 1,
    label: "Managing Remote Teams",
    icon: <RemoteNetworkIconCmp />,
  },
  {
    id: 2,
    label: "Team Retrospective",
    icon: <AdminIconCmp />,
  },
  {
    id: 3,
    label: "Personal Branding Workshop",
    icon: <PersonalBrandingCmp />,
  },
];
