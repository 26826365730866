import React from "react";
import { useParams } from "react-router-dom";
import { toolBoxChipData } from "../../temp/toolBoxChipDBTemp";

export const ToolBoxTopicPageContext = React.createContext(null);

export default function ToolBoxTopicPageContextProvider({ children }) {
  const params = useParams();
  const topicId = Number(params.topicId);
  // const [viewType, setViewType] = React.useState(itineraryViewEnum.list);

  const toolBoxTopic = toolBoxChipData.find((el) => el.id === topicId);

  return (
    <ToolBoxTopicPageContext.Provider
      value={{
        toolBoxTopic,
        //  viewType, setViewType
      }}
    >
      {children}
    </ToolBoxTopicPageContext.Provider>
  );
}
