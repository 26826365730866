import { Box } from "@mui/material";
import React from "react";

export default function ImageSideBarCmp({ data }) {
  return (
    <Box p={2} display="flex" flexDirection="column" rowGap={3}>
      {data.map((el) => {
        return (
          <Box height={160} borderRadius={3}>
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "14px" }}
              src={el}
            />
          </Box>
        );
      })}
    </Box>
  );
}
