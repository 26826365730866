import axios from "axios";
import React from "react";
import { HomePageContext } from "../../pages/Home/HomePageContext";
import { MyProposalContext } from "../../pages/MyProposal/MyProposalContext";

export const useUserBookingApi = () => {
  const { bookedCardData, userEmail } = React.useContext(MyProposalContext);
  const userBookingApi = async () => {
    for (let i = 0; i < bookedCardData.length; i++) {
      try {
        await axios.post("https://back.eira.ai/api/bookings/", {
          user_id: localStorage.getItem("user_id"),
          email_id: userEmail.user_email,
          proposal_name: bookedCardData[i].title,
          is_call_scheduled: false,
          booking_status: "False",
        });
        console.log(bookedCardData);
      } catch (error) {
        console.log(error);
      }
    }
  };
  return { userBookingApi };
};

export const useUserBookingCreateRetreatApi = () => {
  const userBookingCreateRetreatApi = async (data, userEmail) => {
    try {
      await axios.post("https://back.eira.ai/api/bookings/", {
        user_id: localStorage.getItem("user_id"),
        email_id: userEmail,
        proposal_name: data.retreat_name,
        is_call_scheduled: false,
        booking_status: "False",
      });
    } catch (error) {
      console.log(error);
    }
  };
  return { userBookingCreateRetreatApi };
};

export const useUserBookingPopularApi = () => {
  const { bookedCardPopularData } = React.useContext(HomePageContext);
  const userBookingPopularApi = async ({ userEmail }) => {
    for (let i = 0; i < bookedCardPopularData.length; i++) {
      try {
        await axios.post("https://back.eira.ai/api/bookings/", {
          user_id: localStorage.getItem("user_id"),
          email_id: userEmail,
          proposal_name: bookedCardPopularData[i].title,
          is_call_scheduled: true,
          booking_status: "True",
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
  return { userBookingPopularApi };
};

export const useUserBookingPopularGetApi = () => {
  const userBookingPopularGetApi = async () => {
    try {
      const response = await axios.get("https://back.eira.ai/api/bookings/");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
  return { userBookingPopularGetApi };
};
