import {
  Autocomplete,
  Box,
  Button,
  Chip,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import {
  AddIconCmp,
  ChevronArrowIconCmp,
  CrossIconCmp,
  RequiredIconCmp,
  SearchIconCmp,
  UploadIconCmp,
} from "./Icons";

export function TextFieldWithDropdownCmp2({
  bgcolor = "#fff",
  placeholder,
  actionText,
  resultsHeading,
  results,
  autoFocus,
  onClickAction,
  defaultValue,
  border,
  onChange,
  name,
  value,
}) {
  const [isFocus, setFocus] = React.useState(false);
  const inputRef = React.useRef();

  function handleClearInput() {
    inputRef.current.value = "";
    inputRef.current.focus();
  }

  function handleResultClick(text) {
    onChange({ target: { value: text } });
  }

  console.log(results);

  return (
    <Box
      bgcolor={bgcolor}
      borderRadius={4}
      display="flex"
      alignItems="center"
      boxShadow={isFocus && "0px 4px 28px -24px #000000"}
      position="relative"
    >
      <TextField
        inputRef={inputRef}
        fullWidth
        variant="outlined"
        defaultValue={defaultValue}
        sx={{ border: border, "& fieldset": { border: border } }}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        autoFocus={autoFocus}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" onClick={handleClearInput} sx={{ cursor: "pointer" }}>
              <CrossIconCmp sx={{ color: "text.light" }} fontSize="small" />
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start" sx={{ cursor: "pointer" }}>
              <SearchIconCmp sx={{ color: "text.light" }} fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
      {(isFocus || name) && (
        <Box
          position="absolute"
          top="4em"
          zIndex={1}
          left={0}
          width="100%"
          bgcolor="background.main"
          borderRadius={3}
          overflow="hidden"
          boxShadow={"0px 4px 28px -24px #000000"}
          display="flex"
          flexDirection="column"
          textAlign="start"
        >
          {results && results.length > 0 && (
            <Box p={2} maxHeight={150} overflow="auto">
              <Typography variant="body2" fontWeight={600}>
                {resultsHeading}
              </Typography>
              <Box mt={2} display="flex" flexDirection="column" rowGap={1}>
                {results.map((el, idx) => (
                  <Box
                    key={idx}
                    display="flex"
                    alignItems="center"
                    columnGap={2}
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleResultClick(el.text)}
                  >
                    {el.icon}
                    <Typography>{el.text}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          {actionText && name && (
            <Box
              bgcolor="#fff"
              borderTop={results && results.length > 0 && "1px solid"}
              borderColor="text.light"
            >
              <Button
                sx={{
                  color: "primary.main",
                  py: 2,
                  borderRadius: 0,
                }}
                fullWidth
                onClick={onClickAction}
              >
                <Box display="flex" justifyContent="space-between" width="100%">
                  {actionText + ` "${name}"`}
                  <ChevronArrowIconCmp direction="right" />
                </Box>
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export function TextFieldWithDropdownCmp({ placeholder, results, onChange }) {
  const inputRef = React.useRef();
  const [value, setValue] = React.useState();
  const [inputValue, setInputValue] = React.useState();

  console.log(value);

  function handleResultClick(text) {
    onChange({ target: { value: text } });
  }

  return (
    <Box>
      <Stack spacing={2}>
        <Autocomplete
          fullWidth
          freeSolo
          id="free-solo-2-demo"
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            handleResultClick(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            handleResultClick(newInputValue);
          }}
          disableClearable
          options={results.map((option) => option.text)}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                type: "search",
              }}
            />
          )}
        />
      </Stack>
    </Box>
  );
}

export function UploadMultipleImagesCmp({ maxImage, title }) {
  const inputRef = useRef(null);
  const [images, setImages] = useState([]);

  const handleChange = (e) => {
    inputRef.current.click();
  };

  const handleImageChange = (e) => {
    const { files } = e.target;
    setImages((prev) => {
      Array.from(files).forEach((file) => prev.unshift(URL.createObjectURL(file)));
      return prev.slice(0, maxImage);
    });
  };

  return (
    <Box>
      <input
        ref={inputRef}
        style={{ display: "none" }}
        type="file"
        multiple
        onChange={(e) => handleImageChange(e)}
      />
      <Typography fontWeight={500} color="text.light">
        {title}
      </Typography>
      <Box pt={1} />
      <Button
        variant="outlined"
        onClick={handleChange}
        startIcon={<UploadIconCmp />}
        sx={{ color: "text.light", fontWeight: 500, padding: 1.8, width: "100%" }}
      >
        Upload
      </Button>
      <Box display="flex" columnGap={1} mt={1}>
        {images.map((image, index) => {
          return (
            <Box width="56px" height="56px" borderRadius="8px">
              <img
                style={{
                  width: "56px",
                  height: "56px",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
                key={index}
                src={image}
                alt="Not found"
                // onComplete={setSrc}
                //  onLoad={(e) => {
                //   setImageRef(e.target);
                //  }}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export function AdditiveTextFieldCmp({ label, placeholder, onChange, value }) {
  const [chips, setChips] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (inputValue) {
      event.preventDefault();
      const newArr = [...chips, inputValue.trim()];
      if (onChange) {
        onChange(newArr.join("; "));
      }
      if (!chips.includes(inputValue.trim())) {
        setChips(newArr);
      }
      setInputValue("");
    }
  };

  const handleRemoveChip = (index) => {
    setChips(chips.filter((_, i) => i !== index));
    if (onChange) {
      onChange(chips.join("; "));
    }
  };

  return (
    <Box>
      <Typography variant="body1" fontWeight={500} color="text.light" flexShrink={0}>
        {label}
      </Typography>
      <Box mt={1} />
      <Box display="flex" columnGap={1} alignItems="center">
        <TextField
          multiline
          fullWidth
          type="text"
          onChange={handleInputChange}
          value={inputValue}
          placeholder={placeholder}
        />
        <IconButton onClick={handleInputKeyDown} sx={{ width: "40px", height: "40px" }}>
          <AddIconCmp />
        </IconButton>
      </Box>
      <Box m={1} display="flex" columnGap={1} flexWrap="wrap" rowGap={2}>
        {chips.map((chip, index) => (
          <Chip
            key={index}
            sx={{
              height: "auto",
              "& .MuiChip-label": {
                display: "block",
                whiteSpace: "normal",
              },
            }}
            onDelete={() => handleRemoveChip(index)}
            label={chip}
          />
        ))}
      </Box>
    </Box>
  );
}

export function TextFieldWithLabelCmp({
  label,
  sideLabel,
  placeholder,
  defaultValue,
  multiline,
  rows,
  maxRows,
  letterSpacing,
  disabled,
  type,
  onChange,
  name,
  isRequired,
  value,
}) {
  return (
    <Box flex={1} width="100%">
      <Box display="flex" alignContent="center" justifyContent="space-between">
        <Typography variant="body1" fontWeight={500} color="text.light" flexShrink={0}>
          {label}
        </Typography>
        <Box
          display={name === "" ? "flex" : "none"}
          justifyContent="center"
          alignItems="center"
          columnGap={0.5}
        >
          <Box display={isRequired ? "flex" : "none"}>
            <RequiredIconCmp fontSize="small" />
          </Box>
          <Typography
            fontWeight={700}
            fontSize="14px"
            fontFamily="Inter"
            color={name === "" ? "red" : "text.light"}
          >
            {sideLabel}
          </Typography>
        </Box>
      </Box>
      <Box mt={1} />
      <TextField
        onChange={onChange}
        name={name}
        variant="outlined"
        placeholder={placeholder}
        disabled={disabled}
        defaultValue={defaultValue}
        fullWidth
        multiline={multiline}
        rows={rows}
        maxRows={maxRows}
        type={type}
        inputProps={{ style: { letterSpacing } }}
        value={value}
      />
    </Box>
  );
}

export function DropdownCmp({
  label,
  options = [],
  multiple,
  value,
  onChange,
  sideLabel,
  isRequired,
}) {
  return (
    // width="100%" added
    <Box flex={1} width="100%">
      <Box display="flex" alignContent="center" justifyContent="space-between">
        <Typography fontWeight={500} color="text.light">
          {label}
        </Typography>
        <Box
          display={value === "" ? "flex" : "none"}
          justifyContent="center"
          alignItems="center"
          columnGap={0.5}
        >
          <Box display={isRequired ? "flex" : "none"}>
            <RequiredIconCmp fontSize="small" />
          </Box>
          <Typography
            fontWeight={700}
            fontSize="14px"
            fontFamily="Inter"
            color={value === "" ? "red" : "text.light"}
          >
            {sideLabel}
          </Typography>
        </Box>
      </Box>
      <Box pt={1} />
      {/* TODO: style select */}
      <Select
        fullWidth
        value={value || ""}
        onChange={onChange}
        sx={{
          borderRadius: 4,
          color: "#212121",
        }}
        multiple={multiple}
        renderValue={
          multiple &&
          ((selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected?.map((value) => (
                <Chip key={value} label={options.find((el) => el.value === value).cmp} />
              ))}
            </Box>
          ))
        }
        IconComponent={ChevronArrowIconCmp}
      >
        {options.map((el, idx) => (
          <MenuItem key={idx} value={el.value}>
            {el.cmp}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
