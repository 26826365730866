import axios from "axios";
import React from "react";
import { usePageStepHook } from "../../hooks/usePageStepHook";
import { useUserRetreatPreferencesApi } from "../../services/apis/userRetreatPreferences";

export const CreateRetreatContext = React.createContext(null);

export default function CreateRetreatContextProvider({ children }) {
  const { stepNo, handlePrev, handleNext } = usePageStepHook();
  const [countriesData, setCountriesData] = React.useState([{}]);
  const [citiesData, setCitiesData] = React.useState([{}]);
  const [foundCountry, setFoundCountry] = React.useState(false);
  const [foundCity, setFoundCity] = React.useState(false);
  const [name, setName] = React.useState("");
  const [formData, setFormData] = React.useState({
    retreat_name: "",
    user_id: "",
    retreat_check_in_date: "",
    retreat_check_out_date: "",
    retreat_participant_count: "",
    is_retreat_accommodation: "",
    retreat_status: "",
    retreat_room_type: "",
    retreat_purpose: "",
    retreat_location: "",
    retreat_audience_type: "",
  });

  const [open, setOpen] = React.useState(false);

  const fetch = React.useCallback(async () => {
    const options = {
      method: "GET",
      url: "https://api.api-ninjas.com/v1/country?name=" + formData.retreat_location,
      headers: {
        "X-Api-Key": "dFsH9q8VMfozUjT8ywvhjw==EE3dAuYD91QDVCDz",
      },
    };
    try {
      const { data } = await axios.request(options);
      setCountriesData(data[0]);
      data.find((el) => el.name === formData.retreat_location)
        ? setName(formData.retreat_location)
        : setName("Country not found");
      if (name.length > 0) {
        setFoundCountry(true);
        setFoundCity(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, [formData.retreat_location]);

  const fetchCity = React.useCallback(async () => {
    const options = {
      method: "GET",
      url: "https://api.api-ninjas.com/v1/city?name=" + formData.retreat_location,
      headers: {
        "X-Api-Key": "dFsH9q8VMfozUjT8ywvhjw==EE3dAuYD91QDVCDz",
      },
    };
    try {
      const { data } = await axios.request(options);
      setCitiesData(data[0]);
      data.find((el) => el.name === formData.retreat_location)
        ? setName(formData.retreat_location)
        : setName("City not found");
      if (name.length > 0) {
        setFoundCity(true);
        setFoundCountry(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, [formData.retreat_location]);

  React.useEffect(() => {
    fetch();
    // fetchCity();
  }, [fetch]);

  const { userSetRetreatPreferences } = useUserRetreatPreferencesApi();

  const handleSubmit = () => {
    return;
    userSetRetreatPreferences(formData);
  };
  const updateFormValue = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };
  return (
    <CreateRetreatContext.Provider
      value={{
        stepNo,
        handleNext,
        handlePrev,
        handleSubmit,
        updateFormValue,
        formData,
        setFormData,
        countriesData,
        citiesData,
        fetch,
        foundCountry,
        foundCity,
        name,
        open,
        setOpen,
      }}
    >
      {children}
    </CreateRetreatContext.Provider>
  );
}
