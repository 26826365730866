import { Box, Typography } from "@mui/material";
import { MultiChipsCmp } from "../../components/Chips";
import { DropdownCmp, TextFieldWithLabelCmp } from "../../components/Inputs";

export default function OnboardingFormStep2Cmp({
  userCompanyProfileOnb,
  setUserCompanyProfileOnb,
}) {
  return (
    <Box display="flex" flexDirection="column" rowGap={4}>
      <Box>
        <Typography variant="h5Alt" fontWeight={700}>
          Tell Us About Your Company
        </Typography>
        <Box pt={1} />
        <Typography variant="body2Alt" color="text.light">
          By default this will be used to Personalize your dashboard.You can adjust these settings
          now or change them later from your profile.
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems="center"
        columnGap={3}
        rowGap={{ xs: 2, sm: 0 }}
      >
        <TextFieldWithLabelCmp
          label="Company Name"
          sideLabel="Required"
          value={userCompanyProfileOnb.company_name}
          onChange={(e) =>
            setUserCompanyProfileOnb((prev) => ({ ...prev, company_name: e.target.value }))
          }
          name={userCompanyProfileOnb.company_name}
          isRequired={true}
        />
        <DropdownCmp
          label="Company Size"
          sideLabel="Required"
          options={companySizeOptions}
          value={userCompanyProfileOnb.company_size}
          onChange={(e) =>
            setUserCompanyProfileOnb((prev) => ({ ...prev, company_size: e.target.value }))
          }
          isRequired={true}
        />
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems="center"
        columnGap={3}
        rowGap={{ xs: 2, sm: 0 }}
      >
        <TextFieldWithLabelCmp
          label="Workspace Url"
          sideLabel="Required"
          value={userCompanyProfileOnb.company_url}
          onChange={(e) =>
            setUserCompanyProfileOnb((prev) => ({ ...prev, company_url: e.target.value }))
          }
          name={userCompanyProfileOnb.company_url}
          isRequired={true}
        />
        {/* <DropdownCmp
          label="Company Location"
          sideLabel="Required"
          options={companyLocationOptions}
          value={userCompanyProfileOnb.company_location}
          onChange={(e) =>
            setUserCompanyProfileOnb((prev) => ({ ...prev, company_location: e.target.value }))
          }
          isRequired={true}
        /> */}
        <TextFieldWithLabelCmp
          label="Company Location"
          sideLabel="Required"
          value={userCompanyProfileOnb.company_location}
          onChange={(e) =>
            setUserCompanyProfileOnb((prev) => ({ ...prev, company_location: e.target.value }))
          }
          name={userCompanyProfileOnb.company_location}
          isRequired={true}
        />
      </Box>
      <Box>
        <Typography fontWeight={500} color="text.light">
          Type of company
        </Typography>
        <Box pt={1} />
        <MultiChipsCmp
          onChange={(sel) =>
            setUserCompanyProfileOnb((prev) => ({ ...prev, company_business: sel.toString() }))
          }
          data={typeOfCompanyOptions}
          height={48}
          xs={6}
          spacing={2}
          typographyVariant="body2"
        />
      </Box>
    </Box>
  );
}

const companyLocationOptions = [
  { id: 0, cmp: "Portugal", value: "Portugal" },
  { id: 1, cmp: "Lisbon, Portugal", value: "Lisbon, Portugal" },
  { id: 2, cmp: "Madrid, Spain", value: "Madrid, Spain" },
  { id: 3, cmp: "Gibralter", value: "Gibralter" },
  { id: 4, cmp: "Other", value: "Other" },
];

const companySizeOptions = [
  { id: 0, cmp: "1-10", value: "1-10" },
  { id: 1, cmp: "11-50", value: "11-50" },
  { id: 2, cmp: "51-100", value: "51-100" },
  { id: 3, cmp: "101-200", value: "101-200" },
  { id: 4, cmp: "More than 200", value: "More than 200" },
];

const typeOfCompanyOptions = [
  { id: 0, text: "E-Commerce", value: "E-Commerce" },
  { id: 1, text: "Finance", value: "Finance" },
  { id: 2, text: "Health Care", value: "Health Care" },
  { id: 3, text: "Education", value: "Education" },
  { id: 4, text: "Travel", value: "Travel" },
  { id: 5, text: "Food & Beverage", value: "Food & Beverage" },
  { id: 6, text: "Deep Tech", value: "Deep Tech" },
  { id: 7, text: "SAAS", value: "SAAS" },
  { id: 8, text: "Climate Change", value: "Climate Change" },
  { id: 9, text: "Consumer Tech", value: "Consumer Tech" },
  { id: 10, text: "Entertainment", value: "Entertainment" },
  { id: 11, text: "Social Media", value: "Social Media" },
];
