import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { TextWithLinkCmp } from "../../components/Chips";
import { TextFieldWithLabelCmp } from "../../components/Inputs";
import { LoginSignupPagesLayoutCmp } from "../../components/Layouts";
import { UserContext } from "../../providers/UserProvider";
import { useSignUpApi } from "../../services/apis/userApis";
import { useUserSetCompanyProfileApi } from "../../services/apis/userSetCompanyProfileApi";
import { useUserSetProfileApi } from "../../services/apis/userSetProfileApi";

export default function SignupPage() {
  const [user_email, setEmail] = React.useState();
  const [password_hash, setPassword] = React.useState();
  const [confirm_password_hash, setConfirmPassword] = React.useState();

  const navigate = useNavigate();

  const { postSignUp, error, loading } = useSignUpApi();

  const { userSetProfileApi } = useUserSetProfileApi();
  const { userSetCompanyProfileApi } = useUserSetCompanyProfileApi();
  const { fetchInitialData } = React.useContext(UserContext);

  return (
    <LoginSignupPagesLayoutCmp>
      <Typography variant="h4Alt" fontWeight={700}>
        Join the Eira Experience
      </Typography>
      {/* <Box
        bgcolor="secondary.main"
        p={2}
        borderRadius={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <IconWithTextSimpleChipCmp
          icon={GoogleIconCmp}
          label="Sign up with Google"
          large
          fontWeight={700}
          columnGap={1}
        />
      </Box> */}
      {/* <Box
        position="relative"
        display="flex"
        justifyContent="center"
        width="100%"
        borderBottom="1px solid rgba(230, 230, 230, 1)"
      >
        <Box position="absolute" top={-12} bgcolor="#fff" px={1}>
          <Typography fontWeight={600} color="text.light">
            OR
          </Typography>
        </Box>
      </Box> */}
      <Box display="flex" flexDirection="column" rowGap={2}>
        <TextFieldWithLabelCmp
          label="Email Address"
          onChange={(e) => setEmail(e.target.value)}
          name={user_email}
          type={user_email}
        />
        <TextFieldWithLabelCmp
          label="Password"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          name={password_hash}
        />
        <TextFieldWithLabelCmp
          label="Confirm Password"
          type="password"
          onChange={(e) => setConfirmPassword(e.target.value)}
          name={confirm_password_hash}
        />
      </Box>
      <Box>
        <Button
          variant="contained"
          fullWidth
          onClick={async () => {
            await postSignUp({
              user_email,
              password_hash,
              confirm_password_hash,
            });
            await userSetProfileApi();
            await userSetCompanyProfileApi();
            await fetchInitialData();
            if (!loading && !error) navigate("/onboarding");
          }}
        >
          Sign Up
        </Button>
      </Box>
      <TextWithLinkCmp
        label="Already have an account?"
        linkText="Login"
        onClick={() => navigate("/login")}
      />
      <Typography variant="caption" color="text.light" component="span">
        Your account creation signals agreement with our{" "}
        <Box component="span" sx={{ textDecoration: "underline", cursor: "pointer" }}>
          Terms & Privacy
        </Box>
      </Typography>
      <Box mb={0.5} />
    </LoginSignupPagesLayoutCmp>
  );
}
