import { Box, Typography } from "@mui/material";
import React from "react";
import { IconWithTextSimpleChipCmp } from "../../components/Chips";
import { CheckCircleIconCmp, CrossCircleIconCmp } from "../../components/Icons";
import { ItineraryPageContext } from "./ItineraryPageContext";

export default function ItineraryTabPolicyCmp() {
  return (
    <Box display="flex" columnGap={8}>
      {/* <Box flex={3}> */}
      <LeftCmp />
      {/* </Box> */}
      {/* <Box flex={1.3} display={{ xs: "none", md: "block" }}> */}
      {/* <ItineraryBodyRightCmp /> */}
      {/* </Box> */}
    </Box>
  );
}

function LeftCmp() {
  const { retreat } = React.useContext(ItineraryPageContext);

  const arr1 = [];
  for (let i = 0; i < retreat.policy_dos.split("; ").length; i++) {
    arr1.push(i);
  }

  const arr2 = [];
  for (let i = 0; i < retreat.policy_donts.split("; ").length; i++) {
    arr2.push(i);
  }

  return (
    <Box display="flex" flexDirection="column" rowGap={4}>
      <Typography variant="h5Alt" fontWeight={700}>
        Terms & Conditions
      </Typography>
      <Box display="flex" flexDirection="column" rowGap={2}>
        {arr1.map((el) => {
          return (
            <IconWithTextSimpleChipCmp
              icon={CheckCircleIconCmp}
              label={retreat.policy_dos.split("; ")[el]}
              fontWeight={500}
              columnGap={1}
              textColor="text.main"
            />
          );
        })}
        {arr2.map((el) => {
          return (
            <IconWithTextSimpleChipCmp
              icon={CrossCircleIconCmp}
              label={retreat.policy_donts.split("; ")[el]}
              fontWeight={500}
              columnGap={1}
              textColor="text.main"
              color="red"
            />
          );
        })}
      </Box>
    </Box>
  );
}
