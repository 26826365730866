import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../providers/UserProvider";
import { itineraryViewEnum } from "../../utils/enums";

export const ItineraryProposalPageContext = React.createContext("");

export default function ItineraryProposalPageContextProvider({ children }) {
  const endRef = useRef(null);
  const [proposal, setProposal] = useState(null);
  const [viewType, setViewType] = React.useState(itineraryViewEnum.list);
  const { proposalFullData } = React.useContext(UserContext);
  const scrollToBottom = () => {
    endRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const params = useParams();
  useEffect(() => {
    scrollToBottom();
    if (proposalFullData.length !== 0) {
      const proposalId = Number(params.proposalId);
      const res = proposalFullData.find((el) => el.id == proposalId);
      setProposal(res);
    }
  }, [proposalFullData]);

  return (
    <ItineraryProposalPageContext.Provider value={{ proposal, viewType, setViewType, endRef }}>
      {children}
    </ItineraryProposalPageContext.Provider>
  );
}
