import { Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowHorizontalIconCmp } from "../../components/Icons";
import SubscriptionPlansCmp from "../../components/SubscriptionPlansCmp";
import { usePageStepHook } from "../../hooks/usePageStepHook";
import { useUserSetCollaboratorApi } from "../../services/apis/userSetCollaborationApi";
import { usePutCompanyProfile } from "../../services/apis/userSetCompanyProfileApi";
import { usePutUserProfile } from "../../services/apis/userSetProfileApi";
import OnboardingFormStep1Cmp from "./OnboardingFormStep1Cmp";
import OnboardingFormStep2Cmp from "./OnboardingFormStep2Cmp";
import OnboardingFormStep3Cmp from "./OnboardingFormStep3Cmp";

export default function OnboardingFormCmp() {
  const [userProfileDataOnb, setUserProfileDataOnb] = React.useState({
    user_first_name: "",
    user_last_name: "",
    user_designation: "",
  });
  // company_profile
  const [userCompanyProfileOnb, setUserCompanyProfileOnb] = React.useState({
    company_name: "",
    company_size: "",
    company_location: "",
    company_url: "",
    company_business: "",
    company_business_desc: "",
    is_past_company_retreat: "",
    company_logo_url: "",
  });
  // Collaboration
  const [collaborator_email_id, setCollaboratorEmail1] = React.useState();
  // required fields filled or not
  const [isFieldFilled, setIsFieldFilled] = React.useState("1");

  const navigate = useNavigate();
  const { stepNo, handleNext, handlePrev } = usePageStepHook();

  const { putUserProfile } = usePutUserProfile(userProfileDataOnb);

  const { putCompanyProfile } = usePutCompanyProfile(userCompanyProfileOnb);

  const { userSetCollaboratorApi } = useUserSetCollaboratorApi({ collaborator_email_id });

  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap={1}
      alignItems="center"
      width={{ xs: "90%", sm: "80%", md: "40%" }}
      margin="0 auto"
    >
      <StepCounterCmp stepNo={stepNo} />
      <Box bgcolor="#fff" borderRadius={4} width="100%">
        <Box p={4}>
          {
            [
              <OnboardingFormStep1Cmp
                setUserProfileDataOnb={setUserProfileDataOnb}
                userProfileDataOnb={userProfileDataOnb}
              />,
              <OnboardingFormStep2Cmp
                setUserCompanyProfileOnb={setUserCompanyProfileOnb}
                userCompanyProfileOnb={userCompanyProfileOnb}
              />,
              <OnboardingFormStep3Cmp
                setUserCompanyProfileOnb={setUserCompanyProfileOnb}
                userCompanyProfileOnb={userCompanyProfileOnb}
              />,
              // <SubsStepTemp />,
              // <OnboardingFormStep5Cmp
              //   collaborator_email_id={collaborator_email_id}
              //   setCollaboratorEmail1={setCollaboratorEmail1}
              // />,
            ][stepNo]
          }
        </Box>
      </Box>
      <ActionButtonsCmp
        stepNo={stepNo}
        handleNext={async () => {
          if (stepNo === 0) {
            await putUserProfile();
            handleNext();
          } else if (stepNo === 1) {
            if (
              userCompanyProfileOnb.company_name !== "" &&
              userCompanyProfileOnb.company_size !== "" &&
              userCompanyProfileOnb.company_url !== "" &&
              userCompanyProfileOnb.company_location !== ""
            ) {
              setIsFieldFilled("0");
              handleNext();
            } else {
              alert("Fill the required fields first");
            }
          } else {
            handleNext();
          }
        }}
        handlePrev={handlePrev}
        handleSubmit={async () => {
          await putCompanyProfile();
          navigate("/home", { state: { newUser: true } });
        }}
      />
    </Box>
  );
}

function StepCounterCmp({ stepNo }) {
  return (
    <Box display="flex" width="100%">
      {[0, 1, 2].map((el) => {
        const isCurrentStep = stepNo === el;
        const isVisitedStep = stepNo > el;

        return (
          <Box
            key={el}
            display="flex"
            width="100%"
            height={8}
            mx={1}
            borderRadius={100}
            overflow="hidden"
          >
            <Box
              width="50%"
              height="100%"
              bgcolor={isVisitedStep || isCurrentStep ? "primary.main" : "secondary.main"}
              borderRadius={isCurrentStep ? 10 : 0}
            ></Box>
            <Box
              width="50%"
              height="100%"
              bgcolor={isVisitedStep ? "primary.main" : "secondary.main"}
            ></Box>
          </Box>
        );
      })}
    </Box>
  );
}

function ActionButtonsCmp({ stepNo, handleNext, handlePrev, handleSubmit }) {
  if (stepNo === 0)
    return (
      <Button
        variant="contained"
        fullWidth
        endIcon={<ArrowHorizontalIconCmp />}
        onClick={handleNext}
      >
        Continue
      </Button>
    );

  return (
    <Box display="flex" alignItems="center" width="100%" columnGap={4}>
      <Button
        variant="outlined"
        fullWidth
        startIcon={<ArrowHorizontalIconCmp direction="left" />}
        onClick={handlePrev}
      >
        Back
      </Button>
      {stepNo === 2 ? (
        <Button
          variant="contained"
          fullWidth
          endIcon={<ArrowHorizontalIconCmp />}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      ) : (
        <Button
          variant="contained"
          fullWidth
          endIcon={<ArrowHorizontalIconCmp />}
          onClick={handleNext}
        >
          Continue
        </Button>
      )}
    </Box>
  );
}

function SubsStepTemp() {
  return (
    <Box sx={{ maxWidth: { md: "84vw" } }}>
      <Box
        sx={{
          overflowY: "hidden",
          overflowX: "auto",
          scrollbarWidth: "thin",
        }}
      >
        <SubscriptionPlansCmp />
      </Box>
    </Box>
  );
}
