import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { SearchIconCmp } from "../../components/Icons";
import {
  AdditiveTextFieldCmp,
  TextFieldWithDropdownCmp,
  TextFieldWithLabelCmp,
  UploadMultipleImagesCmp,
} from "../../components/Inputs";
import { useMultipleSelectHook } from "../../hooks/useMultipleSelectHook";

export default function AdminpageProposalCmp({ proposalFormData, setProposalFormData }) {
  const { search } = useParams();
  const { state: purpose, handleChange: handleChangePurpose } = useMultipleSelectHook();
  const { state: typeOfAudience, handleChange: handleChangeAudience } = useMultipleSelectHook();
  const { state: highlightingActivities, handleChange: handleChangeHighlightingActivities } =
    useMultipleSelectHook();
  const { state: highlightingWorkshops, handleChange: handleChangeHighlightingWorkshops } =
    useMultipleSelectHook();

  return (
    <Box>
      <OverviewCmp proposalFormData={proposalFormData} setProposalFormData={setProposalFormData} />
      <Box mt={4} />
      <FoodAndBeveragesCmp
        proposalFormData={proposalFormData}
        setProposalFormData={setProposalFormData}
      />
      <Box mt={4} />
      <WorkspaceCmp proposalFormData={proposalFormData} setProposalFormData={setProposalFormData} />
      <Box mt={4} />
      <ActivitiesCmp
        proposalFormData={proposalFormData}
        setProposalFormData={setProposalFormData}
      />
      <Box mt={4} />
      <OptionsCmp proposalFormData={proposalFormData} setProposalFormData={setProposalFormData} />
      <Box mt={4} />
      <PoliciesCmp proposalFormData={proposalFormData} setProposalFormData={setProposalFormData} />
    </Box>
  );
}

function OverviewCmp({ proposalFormData, setProposalFormData }) {
  return (
    <Box>
      <Typography fontSize="18px" fontWeight={600}>
        Section 1: Overview
      </Typography>
      <Box mt={4} />
      <Grid container columnSpacing={8} rowSpacing={4}>
        <Grid item xs={12} sm={6}>
          <Typography fontWeight={500} color="text.light" pb={1.3}>
            Proposal Title
          </Typography>
          <TextFieldWithDropdownCmp
            placeholder="Proposal Title"
            // actionText="See all results for "
            resultsHeading="Suggestions"
            value={proposalFormData.title}
            name={proposalFormData.title}
            // defaultValue={search}
            onChange={(e) => setProposalFormData((prev) => ({ ...prev, title: e.target.value }))}
            results={[
              {
                icon: <SearchIconCmp fontSize="small" sx={{ color: "text.light" }} />,
                text: "Horse Riding",
              },
              {
                icon: <SearchIconCmp fontSize="small" sx={{ color: "text.light" }} />,
                text: "Skiing",
              },
            ]}
            onClickAction={() => {}}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Retreat Location Type"
            placeholder="Retreat Location Type"
            multiline
            value={proposalFormData.location_type}
            onChange={(e) =>
              setProposalFormData((prev) => ({ ...prev, location_type: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Short Description"
            placeholder="Short Description"
            multiline
            value={proposalFormData.short_description}
            onChange={(e) =>
              setProposalFormData((prev) => ({ ...prev, short_description: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Distance from Airport"
            placeholder="Distance from Airport"
            multiline
            value={proposalFormData.distance_from_airport}
            onChange={(e) =>
              setProposalFormData((prev) => ({ ...prev, distance_from_airport: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Room Occupancy"
            placeholder="Room Occupancy"
            multiline
            value={proposalFormData.room_occupancy}
            onChange={(e) =>
              setProposalFormData((prev) => ({ ...prev, room_occupancy: e.target.value }))
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Capacity"
            placeholder="Capacity"
            multiline
            value={proposalFormData.capacity}
            onChange={(e) => setProposalFormData((prev) => ({ ...prev, capacity: e.target.value }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AdditiveTextFieldCmp
            label="What do you Love text"
            placeholder="What do you Love text"
            onChange={(e) => setProposalFormData((prev) => ({ ...prev, what_do_you_love: e }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AdditiveTextFieldCmp
            label="Sustainability Text"
            placeholder="Sustainability Text"
            onChange={(e) => setProposalFormData((prev) => ({ ...prev, sustainability_text: e }))}
            // onChange={(prev) => setArray1([...prev])}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UploadMultipleImagesCmp maxImage={9} title={"Prorosal Images (9 Images)"} />
        </Grid>
      </Grid>
    </Box>
  );
}

function FoodAndBeveragesCmp({ proposalFormData, setProposalFormData }) {
  return (
    <Box>
      <Typography fontSize="18px" fontWeight={600}>
        Section 2: Food And Beverages
      </Typography>
      <Box mt={4} />
      <Grid container columnSpacing={8} rowSpacing={4}>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="F&B Short Desc"
            placeholder="F&B Short Desc"
            multiline
            value={proposalFormData.fb_short_description}
            onChange={(e) =>
              setProposalFormData((prev) => ({ ...prev, fb_short_description: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AdditiveTextFieldCmp
            label="F&B Card Title"
            placeholder="F&B Card Title"
            onChange={(e) => setProposalFormData((prev) => ({ ...prev, fb_card_title: e }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AdditiveTextFieldCmp
            label="F&B Card Desc"
            placeholder="F&B Card Desc"
            onChange={(e) => setProposalFormData((prev) => ({ ...prev, fb_card_desc: e }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="F&B Extra Short Desc"
            placeholder="F&B Extra Short Desc"
            multiline
            value={proposalFormData.fb_extra_short_desc}
            onChange={(e) =>
              setProposalFormData((prev) => ({ ...prev, fb_extra_short_desc: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AdditiveTextFieldCmp
            label="F&B Extra Card Title"
            placeholder="F&B Extra Card Title"
            onChange={(e) => setProposalFormData((prev) => ({ ...prev, fb_extra_card_title: e }))}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <AdditiveTextFieldCmp
            label="F&B Extra Card Desc P1"
            placeholder="F&B Extra Card Desc P1"
            onChange={(e) => setProposalFormData((prev) => ({ ...prev, fb_extra_card_desc_p1: e }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AdditiveTextFieldCmp
            label="F&B Extra Card Desc P2"
            placeholder="F&B Extra Card Desc P2"
            onChange={(e) => setProposalFormData((prev) => ({ ...prev, fb_extra_card_desc_p2: e }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UploadMultipleImagesCmp maxImage={3} title={"Image Upload (3 images)"} />
        </Grid>
      </Grid>
    </Box>
  );
}

function WorkspaceCmp({ proposalFormData, setProposalFormData }) {
  return (
    <Box>
      <Typography fontSize="18px" fontWeight={600}>
        Section 3: Workspace
      </Typography>
      <Box mt={4} />
      <Grid container columnSpacing={8} rowSpacing={4}>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Workspaces Short Desc"
            placeholder="Workspaces Short Desc"
            multiline
            value={proposalFormData.workspace_short_desc}
            onChange={(e) =>
              setProposalFormData((prev) => ({ ...prev, workspace_short_desc: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AdditiveTextFieldCmp
            label="Workspace Card Title"
            placeholder="Workspace Card Title"
            onChange={(e) => setProposalFormData((prev) => ({ ...prev, workspace_card_title: e }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AdditiveTextFieldCmp
            label="Workspace Card Room Size"
            placeholder="Workspace Card Room Size"
            onChange={(e) =>
              setProposalFormData((prev) => ({ ...prev, workspace_card_room_size: e }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AdditiveTextFieldCmp
            label="Workspace Card Capacity"
            placeholder="Workspace Card Capacity"
            onChange={(e) =>
              setProposalFormData((prev) => ({ ...prev, workspace_card_capacity: e }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Workspace Extra Short Desc"
            placeholder="Workspace Extra Short Desc"
            multiline
            value={proposalFormData.workspace_extra_short_desc}
            onChange={(e) =>
              setProposalFormData((prev) => ({
                ...prev,
                workspace_extra_short_desc: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UploadMultipleImagesCmp title={"Workspace Card Image Upload"} />
        </Grid>
      </Grid>
    </Box>
  );
}

function ActivitiesCmp({ proposalFormData, setProposalFormData }) {
  return (
    <Box>
      <Typography fontSize="18px" fontWeight={600}>
        Section 4: Activities
      </Typography>
      <Box mt={4} />
      <Grid container columnSpacing={8} rowSpacing={4}>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Activity Short Desc"
            placeholder="Activity Short Desc"
            multiline
            value={proposalFormData.activity_short_desc}
            onChange={(e) =>
              setProposalFormData((prev) => ({ ...prev, activity_short_desc: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AdditiveTextFieldCmp
            label="Outdoor Activity Title"
            placeholder="Outdoor Activity Title"
            onChange={(e) =>
              setProposalFormData((prev) => ({ ...prev, outdoor_activity_title: e }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AdditiveTextFieldCmp
            label="Indoor Activity Title"
            placeholder="Indoor Activity Title"
            onChange={(e) => setProposalFormData((prev) => ({ ...prev, indoor_activity_title: e }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UploadMultipleImagesCmp maxImage={3} title={"Image Upload (3 images)"} />
        </Grid>
      </Grid>
    </Box>
  );
}

function OptionsCmp({ proposalFormData, setProposalFormData }) {
  return (
    <Box>
      <Typography fontSize="18px" fontWeight={600}>
        Section 5: Options
      </Typography>
      <Box mt={4} />
      <Grid container columnSpacing={8} rowSpacing={4}>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Price Proposal Short Desc"
            placeholder="Price Proposal Short Desc"
            multiline
            value={proposalFormData.price_short_desc}
            onChange={(e) =>
              setProposalFormData((prev) => ({ ...prev, price_short_desc: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AdditiveTextFieldCmp
            label="Proposal Component Title"
            placeholder="Proposal Component Title"
            onChange={(e) =>
              setProposalFormData((prev) => ({ ...prev, proposal_component_title: e }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AdditiveTextFieldCmp
            label="Proposal Component Desc"
            placeholder="Proposal Component Desc"
            onChange={(e) =>
              setProposalFormData((prev) => ({ ...prev, proposal_component_desc: e }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Short Description 2"
            placeholder="Short Description 2"
            multiline
            value={proposalFormData.short_description_2}
            onChange={(e) =>
              setProposalFormData((prev) => ({ ...prev, short_description_2: e.target.value }))
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}

function PoliciesCmp({ proposalFormData, setProposalFormData }) {
  return (
    <Box>
      <Typography fontSize="18px" fontWeight={600}>
        Section 6: Policies
      </Typography>
      <Box mt={4} />
      <Grid container columnSpacing={8} rowSpacing={4}>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Policy Short Desc"
            placeholder="Policy Short Desc"
            multiline
            value={proposalFormData.policy_short_desc}
            onChange={(e) =>
              setProposalFormData((prev) => ({ ...prev, policy_short_desc: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AdditiveTextFieldCmp
            label="Policy DO's"
            placeholder="Policy DO's"
            onChange={(e) => setProposalFormData((prev) => ({ ...prev, policy_dos: e }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AdditiveTextFieldCmp
            label="Policy Don'ts"
            placeholder="Policy Don'ts"
            onChange={(e) => setProposalFormData((prev) => ({ ...prev, policy_donts: e }))}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

const purposeOptions = [
  { id: 0, cmp: "Dedicated Workshop", value: "Dedicated Workshop" },
  { id: 1, cmp: "Great Location", value: "Great Location" },
  { id: 2, cmp: "Dedicated Workshop", value: "Dedicated Workshop" },
  { id: 3, cmp: "Great Location", value: "Great Location" },
  { id: 4, cmp: "Dedicated Workshop", value: "Dedicated Workshop" },
];

const typeOfAudienceOptions = [
  { id: 0, cmp: "Dedicated Workshop", value: "Dedicated Workshop" },
  { id: 1, cmp: "Great Location", value: "Great Location" },
  { id: 2, cmp: "Dedicated Workshop", value: "Dedicated Workshop" },
  { id: 3, cmp: "Great Location", value: "Great Location" },
  { id: 4, cmp: "Dedicated Workshop", value: "Dedicated Workshop" },
];

const highlightingActivitiesOptions = [
  { id: 0, cmp: "Dedicated Workshop", value: "Dedicated Workshop" },
  { id: 1, cmp: "Great Location", value: "Great Location" },
  { id: 2, cmp: "Dedicated Workshop", value: "Dedicated Workshop" },
  { id: 3, cmp: "Great Location", value: "Great Location" },
  { id: 4, cmp: "Dedicated Workshop", value: "Dedicated Workshop" },
];

const highlightingWorkshopsOptions = [
  { id: 0, cmp: "Dedicated Workshop", value: "Dedicated Workshop" },
  { id: 1, cmp: "Great Location", value: "Great Location" },
  { id: 2, cmp: "Dedicated Workshop", value: "Dedicated Workshop" },
  { id: 3, cmp: "Great Location", value: "Great Location" },
  { id: 4, cmp: "Dedicated Workshop", value: "Dedicated Workshop" },
];
