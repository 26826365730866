import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { LaunchIconCmp } from "../../components/Icons";
import OfferingGridCmp from "../../components/OfferingCmp";
import { UserContext } from "../../providers/UserProvider";
import { ItineraryProposalPageContext } from "./ItineraryProposalPageContext";
import PriceProposalBookDialog from "./PriceProposalBookDialog";

export default function ItineraryPriceProposalTabProposalCmp() {
  return (
    <Box display="flex" columnGap={8}>
      <LeftCmp />
    </Box>
  );
}

function LeftCmp() {
  const [open, setOpen] = React.useState(false);
  const [openOfferingsDialog, setOpenOfferingsDialog] = React.useState(false);
  const { proposal, endRef } = React.useContext(ItineraryProposalPageContext);
  const [id, setId] = React.useState();

  const arr = [];
  for (let i = 0; i < proposal.proposal_component_title.split("; ").length; i++) {
    arr.push(i);
  }

  const { priceProposalData } = React.useContext(UserContext);

  return (
    <Box display="flex" flexDirection="column" rowGap={5}>
      <SectionCmp heading="Proposals">
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="body1" lineHeight={1.8}>
            {proposal.price_short_desc}
          </Typography>
          {/* <Box mt={1} sx={{ cursor: "pointer" }} onClick={() => setOpenAboutDialog(true)}>
            <IconWithTextSimpleChipCmp
              icon={ArrowHorizontalIconCmp}
              label="Show More"
              reverse
              color="primary.main"
              fontWeight={700}
            />
          </Box> */}
        </Box>
      </SectionCmp>
      <Grid container spacing={2} columnGap={2} display="flex" justifyContent="space-between">
        {arr.map((el) => {
          return (
            <Grid width="400px" item sm={5} padding={1} borderRadius={2}>
              <OfferingGridCmp
                xs={12}
                // onClickLaunch={() => setOpenOfferingsDialog(true)}
                spacing={0}
                data={[
                  {
                    icon: LaunchIconCmp,
                    label: proposal.proposal_component_title.split("; ")[el],
                    content: proposal.proposal_component_desc.split("; ")[el],
                  },
                ]}
              />
            </Grid>
          );
        })}
      </Grid>
      <Box display="flex" flexDirection="column" alignItems="flex-start" ref={endRef}>
        <Typography variant="body1" lineHeight={1.8}>
          {proposal.proposal_desc2}
        </Typography>
      </Box>

      <BudgetSectionCmp
        setId={setId}
        onClickInfo={() => setOpen(true)}
        priceProposalData={priceProposalData}
        proposal={proposal}
      />

      <Box display="flex" flexWrap="wrap" columnGap={4} rowGap={8}></Box>
      <PriceProposalBookDialog open={open} handleClose={() => setOpen(false)} id={id} />
      {/* <Grid xs={12} container columnGap={2} rowGap={2}>
        {proposal.drinks.map((el) => {
          return (
            <Grid bgcolor="secondary.main" width="48%" padding={2} borderRadius={2}>
              <Typography variant="body1" color="primary" fontWeight={700}>
                {el.type}
              </Typography>
              <Box mt={1} />
              <Typography fontSize="14px">{el.item1}</Typography>
              <Box mt={2} />
              <Typography fontSize="14px">{el.item2}</Typography>
            </Grid>
          );
        })}
      </Grid> */}
    </Box>
  );
}

function BudgetSectionCmp({ onClickInfo, priceProposalData, proposal, setId }) {
  const { userEmail } = React.useContext(UserContext);

  return (
    <Grid container spacing={5}>
      {priceProposalData
        .filter((el1) => el1.proposal_name == proposal.title && el1.email_id == userEmail)
        .map((el) => (
          <Grid item xs={8} sm={8} md={7} lg={6} key={el.id} sx={{ cursor: "pointer" }}>
            <Box display="flex" columnGap={2}>
              <Box
                border="1.5px solid #6A855C"
                p={2}
                borderRadius={2}
                flexShrink={0}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box>
                  <Typography fontSize="16px" fontWeight={600}>
                    {el.option_title}
                  </Typography>
                  <Box>
                    <Typography>{el.option_date_range}</Typography>
                  </Box>
                  <Typography fontSize="14px">{el.option_participants}</Typography>
                  <Typography fontSize="14px">{el.option_days_nights}</Typography>
                </Box>
                <Box>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      setId(el.id);
                      onClickInfo();
                    }}
                  >
                    Request to Book
                  </Button>
                </Box>
              </Box>
              <Box>
                <BudgetCmp
                  acc={el.accommodation}
                  activity={el.activities}
                  food={el.food_bev}
                  work={el.workspaces}
                  service={el.eira_service_fees}
                  total={el.total_price_with_service_fees}
                  totalVAT={el.total_price_with_vat}
                  participant={el.total_price_per_participant}
                />
              </Box>
            </Box>
          </Grid>
        ))}
    </Grid>
  );
}

function BudgetCmp({ acc, activity, food, work, service, total, totalVAT, participant }) {
  return (
    <Box display="flex" flexDirection="column" rowGap={1}>
      <Box display="flex" flexShrink={0} alignItems="center" justifyContent="space-between">
        <Typography>Accomodation </Typography>
        <Box display="flex" alignItems="center" columnGap={1}>
          <Box width={56} height={2} bgcolor="secondary.main" ml={1} mr={1}></Box>
          <Typography> {acc}</Typography>
        </Box>
      </Box>
      <Box display="flex" flexShrink={0} alignItems="center" justifyContent="space-between">
        <Typography>Activities </Typography>
        <Box display="flex" alignItems="center" columnGap={1}>
          <Box width={56} height={2} bgcolor="secondary.main" ml={1} mr={1}></Box>
          <Typography>{activity}</Typography>
        </Box>
      </Box>
      <Box display="flex" flexShrink={0} alignItems="center" justifyContent="space-between">
        <Typography>Food & Bev </Typography>
        <Box display="flex" alignItems="center" columnGap={1}>
          <Box width={56} height={2} bgcolor="secondary.main" ml={1} mr={1}></Box>
          <Typography>{food}</Typography>
        </Box>
      </Box>
      <Box display="flex" flexShrink={0} alignItems="center" justifyContent="space-between">
        <Typography>Workspace </Typography>
        <Box display="flex" alignItems="center" columnGap={1}>
          <Box width={56} height={2} bgcolor="secondary.main" ml={1} mr={1}></Box>
          <Typography>{work}</Typography>
        </Box>
      </Box>
      <Box display="flex" flexShrink={0} alignItems="center" justifyContent="space-between">
        <Typography>EIRA Service Fee </Typography>
        <Box display="flex" alignItems="center" columnGap={1}>
          <Box width={56} height={2} bgcolor="secondary.main" ml={1} mr={1}></Box>
          <Typography>{service}</Typography>
        </Box>
      </Box>
      <Divider sx={{ marginTop: 1, marginBottom: 1, backgroundColor: "primary.main" }} />
      <Box display="flex" flexShrink={0} alignItems="center" justifyContent="space-between">
        <Typography>Total </Typography>
        <Box display="flex" alignItems="center" columnGap={1}>
          <Box width={56} height={2} bgcolor="secondary.main" ml={1} mr={1}></Box>
          <Typography>{total}</Typography>
        </Box>
      </Box>
      <Box display="flex" flexShrink={0} alignItems="center" justifyContent="space-between">
        <Box>
          <Typography fontWeight={600}>Total Price</Typography>
          <Typography fontSize="12px">(incl. VAT)</Typography>
        </Box>
        <Box display="flex" alignItems="center" columnGap={1}>
          <Box width={56} height={2} bgcolor="secondary.main" ml={1} mr={1}></Box>
          <Typography>{totalVAT}</Typography>
        </Box>
      </Box>
      <Typography sx={{ marginTop: 1 }} fontSize="14px" ml="auto">
        (Price Per Participant:{participant} )
      </Typography>
    </Box>
  );
}

// function TableCmp() {
//   return (
//     <Box>
//       <TableContainer component={Paper}>
//         <Table sx={{ minWidth: 650 }} aria-label="simple table">
//           <TableHead>
//             <TableRow>
//               <TableCell>Accommodation</TableCell>
//               <TableCell>Food & Beverages</TableCell>
//               <TableCell>Workspace</TableCell>
//               <TableCell>Price Per Partcipants</TableCell>
//               <TableCell>EIRA Service Cost</TableCell>
//               <TableCell>Total</TableCell>
//               <TableCell>Total Price VAT(incl.)</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
//               <TableCell component="th" scope="row">
//                 hello
//               </TableCell>
//               <TableCell>hello</TableCell>
//               <TableCell>hello2</TableCell>
//               <TableCell>hello3</TableCell>
//             </TableRow>
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Box>
//   );
// }

function SectionCmp({ heading, children, headingExtra }) {
  return (
    <Box>
      <Box display="flex" alignItems="center" columnGap={2}>
        <Typography variant="h5Alt" fontWeight={600}>
          {heading}
        </Typography>
        {headingExtra}
      </Box>
      <Box pt={2} />
      {children}
    </Box>
  );
}
