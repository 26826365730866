import axios from "axios";
import React from "react";

export const useUserRetreatPreferencesApi = () => {
  const userSetRetreatPreferences = React.useCallback(async (data) => {
    try {
      await axios.post("https://back.eira.ai/api/retreat-preferences/", {
        ...data,
        user_id: window.localStorage.getItem("user_id"),
        retreat_check_in_date: "2024-05-15T00:00:00",
        retreat_check_out_date: "2024-05-20T00:00:00",
        is_retreat_accommodation: true,
        retreat_status: "Active",
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return { userSetRetreatPreferences };
};

export const useUserGetRetreatPreferences = () => {
  const [data, setData] = React.useState([]);
  const [retreatData, setRetreatData] = React.useState([]);
  const userGetRetreatPreferences = async (retreat_name) => {
    try {
      const response = await axios.get("https://back.eira.ai/api/retreat-preferences/");
      // console.log(response.data.find((el) => el.retreat_name === retreat_name));
      // return response.data.find((el) => el.retreat_name === retreat_name);
      setData(response.data.retreats);

      // data.find((el) =>
      //   el.retreat_name == retreat_name
      // );
    } catch (error) {}
  };
  return { userGetRetreatPreferences, data, retreatData };
};
