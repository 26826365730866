import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { MultiChipsCmp } from "../../components/Chips";
import { SearchIconCmp } from "../../components/Icons";
import { TextFieldWithDropdownCmp } from "../../components/Inputs";
import { useMultipleSelectHook } from "../../hooks/useMultipleSelectHook";
import { UserContext } from "../../providers/UserProvider";
import {
  useUserManageProposaApi,
  useUserManageProposaGetApi,
  useUserManageProposalDeleteApi,
} from "../../services/apis/userManageProposalApi";

export default function AdminPageManageProposalsTabCmp() {
  const { state: activities, handleChange: handleChangeActivities } = useMultipleSelectHook();
  const { state: workshop, handleChange: handleChangeWorkshop } = useMultipleSelectHook();
  const { state: guide, handleChange: handleChangeGuide } = useMultipleSelectHook();

  const [myProposal, setMyProposal] = React.useState([]);
  const [proposalRemove, setProposalRemove] = React.useState({
    title: "",
  });
  const [id, setId] = useState("");
  const [removeProposalId, setRemoveproposalId] = React.useState("");
  const [manageProposal, setManageProposal] = React.useState({
    email_id: "",
    proposal_title: "",
    proposal_id: "",
  });

  const { userManageProposalGetApi } = useUserManageProposaGetApi();
  const { userManageTripsDeleteApi } = useUserManageProposalDeleteApi();

  const fetchMyProposalData = React.useCallback(async () => {
    const data = await userManageProposalGetApi();
    setMyProposal(data.proposals);
  }, []);

  const { userData, proposalData, proposalFullData } = React.useContext(UserContext);
  const arr = [];

  for (let i = 0; i < myProposal.length; i++) {
    if (myProposal[i].email_id == manageProposal.email_id) {
      arr.push({ id: i, value: myProposal[i].proposal_title, text: myProposal[i].proposal_title });
    }
  }

  const { userManageProposalApi } = useUserManageProposaApi();

  function handleId() {
    proposalData.find((el) => el.title === manageProposal.proposal_title && setId(el.id));
    myProposal.find(
      (el) => el.proposal_title === proposalRemove.title && setRemoveproposalId(el.id)
    );
  }

  React.useEffect(() => {
    handleId();
  }, [handleId]);

  return (
    <Box>
      <Grid container columnSpacing={8} rowSpacing={4}>
        <Grid item xs={12} sm={6} display="flex" columnGap={2} alignItems="center">
          <Box flex={3}>
            <TextFieldWithDropdownCmp
              placeholder="Select The Email ID"
              // actionText="See all results for "
              resultsHeading="Suggestions"
              // defaultValue={search}
              onChange={(e) => setManageProposal((prev) => ({ ...prev, email_id: e.target.value }))}
              results={userData.map((el) => ({
                icon: <SearchIconCmp fontSize="small" sx={{ color: "text.light" }} />,
                text: el.user_email,
              }))}
              onClickAction={() => {}}
            />
          </Box>
          <Box flex={0.5}>
            <Button variant="contained" onClick={() => fetchMyProposalData()}>
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box mt={5}>
        <Typography fontWeight={500} color="text.light" pb={1.3}>
          List of Proposals
        </Typography>
        <MultiChipsCmp data={arr} xs={8} md={3} maxSelectItems={0} height={80} />
      </Box>
      <Grid container columnSpacing={8} rowSpacing={4} mt={2}>
        <Grid item xs={12} sm={6}>
          <Typography fontWeight={500} color="text.light" pb={1.3}>
            Add A Proposals
          </Typography>
          <Box display="flex" columnGap={1} alignItems="center" width="100%">
            <Box flex={3}>
              <TextFieldWithDropdownCmp
                placeholder="Add A Proposal"
                // actionText="See all results for "
                resultsHeading="Suggestions"
                // defaultValue={search}
                onChange={(e) =>
                  setManageProposal((prev) => ({ ...prev, proposal_title: e.target.value }))
                }
                results={
                  proposalFullData
                    .filter((el1) => el1.title !== "")
                    .map((el) => ({
                      icon: <SearchIconCmp fontSize="small" sx={{ color: "text.light" }} />,
                      text: el.title,
                    }))
                  //   [
                  //   {
                  //     icon: <SearchIconCmp fontSize="small" sx={{ color: "text.light" }} />,
                  //     text: "Horse Riding",
                  //   },
                  //   {
                  //     icon: <SearchIconCmp fontSize="small" sx={{ color: "text.light" }} />,
                  //     text: "Skiing",
                  //   },
                  // ]
                }
                onClickAction={() => {}}
              />
            </Box>
            <Box flex={0.5}>
              <Button
                variant="contained"
                onClick={async () => {
                  await userManageProposalApi(manageProposal, id);
                }}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography fontWeight={500} color="text.light" pb={1.3}>
            Remove A Proposals
          </Typography>
          <Box display="flex" columnGap={1} alignItems="center">
            <Box flex={3}>
              <TextFieldWithDropdownCmp
                placeholder="Remove A Proposal"
                // actionText="See all results for "
                resultsHeading="Suggestions"
                // defaultValue={search}
                onChange={(e) => setProposalRemove((prev) => ({ ...prev, title: e.target.value }))}
                results={arr.map((el) => ({
                  icon: <SearchIconCmp fontSize="small" sx={{ color: "text.light" }} />,
                  text: el.text,
                }))}
                onClickAction={() => {}}
              />
            </Box>
            <Box flex={0.5}>
              <Button
                variant="contained"
                onClick={() => userManageTripsDeleteApi(removeProposalId)}
              >
                Remove
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
