import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BackButtonCmp } from "../../components/Buttons";
import { IconWithTextSimpleChipCmp } from "../../components/Chips";
import { MembersIconCmp, TimeIconCmp } from "../../components/Icons";
import { PageMainContentsLayoutCmp, PageSectionLayoutCmp } from "../../components/Layouts";
import { toolBoxCardData } from "../../temp/toolBoxDBTemp";
import ToolBoxTopicPageContextProvider, { ToolBoxTopicPageContext } from "./ToolBoxTopicContext";

export default function ToolboxTopicPage() {
  return (
    <ToolBoxTopicPageContextProvider>
      <ToolboxTopicPageMain />
    </ToolBoxTopicPageContextProvider>
  );
}

function ToolboxTopicPageMain() {
  const { toolBoxTopic } = React.useContext(ToolBoxTopicPageContext);

  return (
    <PageMainContentsLayoutCmp>
      <PageSectionLayoutCmp title="Toolbox">
        <Box display="flex" flexDirection="column" rowGap={6}>
          <BackButtonCmp />
          <Box>
            <Typography variant="h5" fontWeight={700} color="primary">
              {toolBoxTopic.label}
            </Typography>
            <Box pt={1} />
            <Typography lineHeight="29px">
              Immerse yourself in nature and pure luxury at Shangrila Rénao situated on top of Tandi
              hill near Jibhi. Not only can you pour yourself in a hot bubble bath but also enjoy
              the stunning views right from your bathtub.
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" rowGap={3}>
            {toolBoxCardData.map((el) => (
              <CardCmp
                id={el.id}
                image={el.image}
                title={el.title}
                shortDesc={el.shortDesc}
                time={el.time}
                amountOfPeople={el.amountOfPeople}
              />
            ))}
          </Box>
        </Box>
      </PageSectionLayoutCmp>
    </PageMainContentsLayoutCmp>
  );
}

function CardCmp({ title, shortDesc, amountOfPeople, time, image, id }) {
  const navigate = useNavigate();

  return (
    <Box
      border={{ xs: "1px solid #C4DFB6", sm: "none" }}
      borderRadius={4}
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      alignItems="center"
      p={0.5}
      columnGap={{ sm: 2, md: 4 }}
      sx={{ cursor: "pointer" }}
      onClick={() => navigate("postID/" + id)}
    >
      <Box
        width={{ xs: "100%", sm: 160, md: 180, lg: 240 }}
        height={{ xs: 180, sm: 140, md: 120, lg: 120 }}
        flexShrink={0}
        borderRadius={4}
        overflow="hidden"
        position="relative"
      >
        <img src={image} alt="soe" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
      </Box>
      <Box py={1} display={{ xs: "block", sm: "none" }} />
      <Box display="flex" flexDirection="column" rowGap={1} flex={1}>
        <Typography variant="h5Alt" fontWeight={500}>
          {title}
        </Typography>
        <Typography variant="body2">{shortDesc}</Typography>
        <Box display="flex" alignItems="center" columnGap={2}>
          <IconWithTextSimpleChipCmp icon={MembersIconCmp} label={amountOfPeople} />
          <IconWithTextSimpleChipCmp icon={TimeIconCmp} label={time} />
        </Box>
      </Box>
    </Box>
  );
}
