import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { LaunchIconCmp } from "../../../components/Icons";
import ImageSideBarCmp from "../ImagesSideBarCmp";
import { ItineraryProposalPageContext } from "../ItineraryProposalPageContext";

export default function ItineraryProposalTabFoodAndBeverageCmp() {
  return (
    <Box>
      <LeftCmp />
    </Box>
  );
}

function LeftCmp() {
  const [openAboutDialog, setOpenAboutDialog] = React.useState(false);
  const [openOfferingsDialog, setOpenOfferingsDialog] = React.useState(false);
  const { proposal } = React.useContext(ItineraryProposalPageContext);

  if (proposal === null) return <div>loading...</div>;

  return (
    <Box display="flex" flexDirection="column" rowGap={5}>
      <Box display="flex" columnGap={8}>
        <Box flex={3} flexShrink={0}>
          <SectionCmp heading="Food & Beverages">
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="body1" lineHeight={1.8}>
                {proposal.fb_short_description}
              </Typography>
              {/* <Box mt={1} sx={{ cursor: "pointer" }} onClick={() => setOpenAboutDialog(true)}>
            <IconWithTextSimpleChipCmp
              icon={ArrowHorizontalIconCmp}
              label="Show More"
              reverse
              color="primary.main"
              fontWeight={700}
            />
          </Box> */}
            </Box>
          </SectionCmp>
          <Box mt={6} />
          <CultureToolboxCmp
            cardTitle={proposal.fb_card_title.split("; ")}
            cardDescLength={proposal.fb_card_desc.split("; ").length}
            cardDesc={proposal.fb_card_desc.split("; ")}
          />
        </Box>
        <Box flex={1.3} display={{ xs: "none", md: "block" }}>
          <ImageSideBarCmp data={data} />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Typography variant="body1" lineHeight={1.8}>
          {proposal.fb_extra_short_desc}
        </Typography>
      </Box>
      <CultureToolboxCmp
        cardTitle={proposal.fb_extra_card_title.split("; ")}
        cardDescLength={proposal.fb_extra_card_desc_p1.split("; ").length}
        cardDesc={proposal.fb_extra_card_desc_p1.split("; ")}
        cardDesc2={proposal.fb_extra_card_desc_p2.split("; ")}
      />
    </Box>
  );
}

function CultureToolboxCmp({ cardTitle, cardDescLength, cardDesc, cardDesc2 }) {
  const arr = [];
  for (let i = 0; i < cardDescLength; i++) {
    arr.push(i);
  }
  return (
    <Box>
      <Grid container spacing={2}>
        {arr.map((el, idx) => {
          return (
            <Grid item sm={6} key={idx} sx={{ cursor: "pointer" }}>
              <CultureToolboxCardCmp
                cardTitle={cardTitle[el]}
                cardDesc={cardDesc[el]}
                cardDesc2={cardDesc2 && cardDesc2[el]}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

function CultureToolboxCardCmp({ cardTitle, cardDesc, cardDesc2 }) {
  return (
    <Box borderRadius={4} border="1px solid #C4DFB6" p={1} height="100%">
      <Box px={1} pt={1} pb={3} display="flex" flexDirection="column" rowGap={1}>
        <Box display="flex" justifyContent="space-between">
          <Box
            color="#fff"
            bgcolor="primary.main"
            borderRadius="50%"
            height={32}
            width={32}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <LaunchIconCmp />
          </Box>
        </Box>
        <Typography
          variant="body1"
          fontWeight={700}
          color="primary"
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
          }}
        >
          {cardTitle}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
          }}
        >
          {cardDesc}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
          }}
        >
          {cardDesc2}
        </Typography>
        {/* {shortDesc.map((el) => {
          return (
            <Typography
              variant="body2"
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
              }}
            >
              {el}
            </Typography>
          );
        })} */}
      </Box>
    </Box>
  );
}

function SectionCmp({ heading, children, headingExtra }) {
  return (
    <Box>
      <Box display="flex" alignItems="center" columnGap={2}>
        <Typography variant="h5Alt" fontWeight={600}>
          {heading}
        </Typography>
        {headingExtra}
      </Box>
      <Box pt={2} />
      {children}
    </Box>
  );
}

const data = [
  "https://img.freepik.com/premium-photo/buffet-table-with-variety-food-including-sandwiches-sandwiches-other-foods_521733-908.jpg",
  "https://assets.architecturaldigest.in/photos/60083ed208ae763b9ae8542d/16:9/w_2560%2Cc_limit/fermented-coffee-beverage-brew-1366x768.jpg",
  "https://www.mashed.com/img/gallery/the-truth-about-hotel-breakfast-buffets/l-intro-1609346085.jpg",
];
