import axios from "axios";

export const useUserSetCompanyProfileApi = () => {
  const userSetCompanyProfileApi = async () => {
    try {
      await axios.post("https://back.eira.ai/api/company-profiles/", {
        user_id: window.localStorage.getItem("user_id"),
        company_business: "",
        is_past_company_retreat: false,
        company_name: "",
        company_size: "",
        company_location: "",
        company_url: "",
        company_business_desc: "",
        company_logo_url: "",
        company_culture_deck_url: "",
      });
      const response = await axios.get("https://back.eira.ai/api/company-profiles/");
      window.localStorage.setItem(
        "company_id",
        response.data.companies.find(
          (el) => el.user_id === Number(window.localStorage.getItem("user_id"))
        ).company_id
      );
    } catch (error) {
      console.log(error);
    }
  };

  return { userSetCompanyProfileApi };
};

export const usePutCompanyProfile = (data) => {
  const putCompanyProfile = async () => {
    try {
      await axios.put(
        "https://back.eira.ai/api/company-profiles/" + window.localStorage.getItem("company_id"),
        {
          ...data,
          company_logo_url: "wfwfa",
          company_culture_deck_url: "wdfd",
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return { putCompanyProfile };
};

export const useUserGetCompanyById = () => {
  const getUserCompanyById = async () => {
    try {
      const userCompanyData = await axios.get(
        "https://back.eira.ai/api/company-profiles/" + window.localStorage.getItem("company_id")
      );
      // setUserProfileDataById(userProfileData.data);
      return userCompanyData.data;
    } catch (error) {
      console.log(error);
    }
  };
  return { getUserCompanyById };
};
