import axios from "axios";
import React from "react";

export const useUserParticipantsApi = () => {
  const userParticipantsApi = async (data) => {
    try {
      await axios.post("https://back.eira.ai/api/participants/", {
        ...data,
      });
    } catch (error) {
      console.log(error);
    }
  };
  return { userParticipantsApi };
};

export const useUserParticipantsGetApi = () => {
  const [data, setData] = React.useState([]);
  const userParticipantsGetApi = async () => {
    try {
      const response = await axios.get("https://back.eira.ai/api/participants/");
      // response.data.collaborators.find((el) =>
      //   el.collaborator_profile_id === id ? setEditData(response.data.collaborators[id - 1]) : ""
      // );
      setData(response.data.participants);
    } catch (error) {
      console.log(error);
    }
  };

  return { userParticipantsGetApi, data };
};
