import { Avatar, Box, LinearProgress, Link, Rating, Typography } from "@mui/material";
import React from "react";
import { HeartIconCmp, LeafIconCmp, LocationIconCmp } from "../../../components/Icons";
import ItineraryBodyRightCmp from "../ItineraryBodyRightCmp";
import { ItineraryPageContext } from "../ItineraryPageContext";

export default function ItineraryTabOverviewCmp() {
  return (
    <Box display="flex" columnGap={8}>
      <Box flex={3} flexShrink={0}>
        <LeftCmp />
      </Box>
      <Box flex={1.3} display={{ xs: "none", md: "block" }}>
        <ItineraryBodyRightCmp />
      </Box>
    </Box>
  );
}

function LeftCmp() {
  const [openAboutDialog, setOpenAboutDialog] = React.useState(false);
  const [openOfferingsDialog, setOpenOfferingsDialog] = React.useState(false);
  const { retreat } = React.useContext(ItineraryPageContext);

  return (
    <Box display="flex" flexDirection="column" rowGap={5}>
      <SectionCmp heading="About this location">
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="body1" lineHeight={1.8}>
            {retreat.short_description}
          </Typography>
          {/* <Box mt={1} sx={{ cursor: "pointer" }} onClick={() => setOpenAboutDialog(true)}>
            <IconWithTextSimpleChipCmp
              icon={ArrowHorizontalIconCmp}
              label="Show More"
              reverse
              color="primary.main"  
              fontWeight={700}
            />
          </Box> */}
        </Box>
      </SectionCmp>
      <Box display="flex" flexDirection="column" rowGap={1}>
        <Box display="flex" columnGap={1}>
          <Typography fontSize="16px" fontWeight={700} fontFamily="Inter">
            Distance from Airport:
          </Typography>
          <Typography fontSize="16px" fontFamily="Inter">
            {retreat.distance_from_airport}
          </Typography>
        </Box>
        <Box display="flex" columnGap={1}>
          <Typography fontSize="16px" fontWeight={700} fontFamily="Inter" flexShrink={0}>
            Rooms and Occupancy:
          </Typography>
          <Typography fontSize="16px" fontFamily="Inter">
            {retreat.room_occupancy}
          </Typography>
        </Box>
        <Box display="flex" columnGap={1}>
          <Typography fontSize="16px" fontWeight={700} fontFamily="Inter" flexShrink={0}>
            Capacity:
          </Typography>
          <Typography fontSize="16px" fontFamily="Inter">
            {retreat.capacity}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" columnGap={2}>
        <CardCmp
          icon={<HeartIconCmp />}
          label="What You Will Love"
          content={retreat.what_do_you_love}
        />
        <CardCmp
          icon={<LeafIconCmp />}
          label="Sustainability At This Location"
          content={retreat.sustainability_text}
        />
      </Box>
    </Box>
  );
}

function CardCmp({ icon, label, content }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap={1}
      p={2}
      borderRadius={4}
      border="1px solid rgba(33, 33, 33, 0.3)"
      width="100%"
    >
      <Box
        width={32}
        height={32}
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="#fff"
        bgcolor="primary.main"
        borderRadius={20}
      >
        {icon}
      </Box>
      <Box display="flex" flexDirection="column" rowGap={1}>
        <Typography variant="body1" color="primary" fontWeight={700}>
          {label}
        </Typography>
        <Typography variant="body2" color="text.light">
          {content}
        </Typography>
      </Box>
    </Box>
  );
}

function SectionCmp({ heading, children, headingExtra }) {
  return (
    <Box>
      <Box display="flex" alignItems="center" columnGap={2}>
        <Typography variant="h5Alt" fontWeight={600}>
          {heading}
        </Typography>
        {headingExtra}
      </Box>
      <Box pt={2} />
      {children}
    </Box>
  );
}

function RatingOnParametersCmp({ label, rating }) {
  return (
    <Box display="flex" flexDirection="column" rowGap={1}>
      <Box
        width={32}
        height={32}
        borderRadius={20}
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="#fff"
        bgcolor="primary.main"
      >
        <LocationIconCmp fontSize="small" />
      </Box>
      <Typography fontWeight={700} color="primary.main">
        {label}
      </Typography>
      <Typography variant="body2">{rating}</Typography>
    </Box>
  );
}

function OverallRatingsCmp() {
  return (
    <Box
      bgcolor="secondary.main"
      height="100%"
      width="100%"
      borderRadius={4}
      p={2}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Typography fontWeight={700} color="primary">
          Overall Ratings
        </Typography>
        <Typography fontWeight={700} color="primary">
          4.2
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" width="100%" rowGap={0.5} mt={1}>
        {[...new Array(5)].map((_, idx) => (
          <Box key={idx} display="flex" alignItems="center" width="100%">
            <Box width={24}>
              <Typography variant="body2">{5 - idx}</Typography>
            </Box>
            <LinearProgress
              sx={{
                height: 8,
                width: "100%",
                color: "primary.main",
                borderRadius: 16,
                backgroundColor: "rgba(255, 255, 255, 0.5)",
              }}
              variant="determinate"
              value={80}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

function ReviewCmp() {
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" columnGap={1} alignItems="center">
        <Avatar
          src="https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
          sx={{ width: 32, height: 32 }}
        />
        <Typography fontWeight={700} color="primary.main">
          Genellia
        </Typography>
      </Box>
      <Box my={1} display="flex" alignItems="center" columnGap={1}>
        <Rating name="size-small" defaultValue={2} size="small" sx={{ color: "primary.main" }} />
        <Typography variant="caption" fontWeight={700}>
          September 22, 2023
        </Typography>
      </Box>
      <Typography variant="body2">
        I recently stayed at a charming guesthouse in Chandigarh, and it was an absolute delight.
        The ambiance was cozy, and the attention to detail in the room decor was impressive.
      </Typography>
      <Link>
        <Typography variant="body2" fontWeight={700}>
          Show More
        </Typography>
      </Link>
    </Box>
  );
}
