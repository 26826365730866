import { Box, Button, Divider, IconButton, TextField, Typography } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import { LinkedInIconCmp } from "../../components/Icons";
import { PageMainContentsLayoutCmp, PageSectionLayoutCmp } from "../../components/Layouts";
import { useGetUser } from "../../services/apis/userApis";
import { useUserQueryApi } from "../../services/apis/userQueryApi";
import ConfirmQuerySubmitDialog from "./ConfirmQuerySubmitDialog";

export default function SupportPage() {
  const [query_message, setQuery] = React.useState("");
  const [user_email, setEmail] = React.useState("");
  const [openQuerySubmit, setOpenQuerySubmit] = React.useState(false);
  const { getUser } = useGetUser();
  const fetchInitialData = React.useCallback(async () => {
    const emailData = await getUser();
    setEmail(emailData.user_email);
  }, []);
  React.useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);
  const { userQueryApi } = useUserQueryApi({ query_message, user_email });
  return (
    <PageMainContentsLayoutCmp>
      <PageSectionLayoutCmp title="Customer Support">
        <Outlet />
        <Box
          pt={10}
          display="flex"
          flexDirection="column"
          alignItems="center"
          width={{ xs: "100%", md: "50%" }}
          margin="0 auto"
          rowGap={6}
        >
          <Box textAlign="center">
            <Typography variant="h2Alt" color="text.light" fontWeight={500}>
              You still have a question?
            </Typography>
            <Box pt={1} />
            <Typography variant="body2" color="text.light">
              If you cannot find answer to your question in our FAQ, you can always contact us. We
              will answer to you shortly!
            </Typography>
          </Box>
          <Box display="flex" width="100%" columnGap={{ xs: 2, md: 8 }}>
            <Box
              flex={1}
              borderRadius={4}
              border="1px solid"
              borderColor="text.light"
              p={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
              rowGap={1}
              color="text.light"
            >
              {/* <PhoneIconCmp /> */}
              <Box width="80px" height="80px" borderRadius={100}>
                <img
                  style={{
                    width: "80px",
                    height: "80px",
                    objectFit: "cover",
                    borderRadius: "100%",
                  }}
                  src="/images/Cristina.png"
                />
              </Box>
              <Typography fontWeight={500} color="inherit">
                Cristina Geisler
              </Typography>
              <Typography fontWeight={500} color="inherit">
                +49 177 1432047
              </Typography>
            </Box>
            <Box
              flex={1}
              borderRadius={4}
              border="1px solid"
              borderColor="text.light"
              p={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
              rowGap={1}
              color="text.light"
            >
              {/* <PhoneIconCmp /> */}
              <Box width="80px" height="80px" borderRadius={100}>
                <img
                  style={{
                    width: "80px",
                    height: "80px",
                    objectFit: "cover",
                    borderRadius: "100%",
                  }}
                  src="/images/Ella.png"
                />
              </Box>
              <Typography fontWeight={500} color="inherit">
                Ella Brockhaus
              </Typography>
              <Typography fontWeight={500} color="inherit">
                +49 15906189692
              </Typography>
            </Box>
          </Box>
          <Box width="100%" position="relative" display="flex" justifyContent="center">
            <Box flex={1}>
              <Divider />
            </Box>
            <Box position="absolute" bgcolor="#fff" px={2} top={-11}>
              <Typography variant="body2" color="text.light">
                or fill out the contact form
              </Typography>
            </Box>
          </Box>
          <Box width="100%" display="flex" flexDirection="column" alignItems="center" rowGap={3}>
            <TextField
              placeholder="Your Issue"
              inputProps={{ style: { textAlign: "center" } }}
              fullWidth
              multiline
              rows={3}
              value={query_message}
              onChange={(e) => setQuery(e.target.value)}
            />
            <Button
              variant="contained"
              onClick={async () => {
                setOpenQuerySubmit(true);
              }}
            >
              Submit
            </Button>
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton size="large" href="https://www.linkedin.com/company/eira-ai/">
              <LinkedInIconCmp fontSize="large" />
            </IconButton>
            <a
              href="https://www.eira.ai/"
              style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <img
                style={{ marginLeft: "16px", cursor: "pointer" }}
                width="140px"
                height="48px"
                src="https://framerusercontent.com/images/M5fnwZ34DWLWbygQgeD2z1lPsY.png"
              />
            </a>
          </Box>
          <ConfirmQuerySubmitDialog
            open={openQuerySubmit}
            handleClose={() => setOpenQuerySubmit(false)}
            setOpenQuerySubmit={setOpenQuerySubmit}
            userQueryApi={userQueryApi}
            setQuery={setQuery}
          />
        </Box>
      </PageSectionLayoutCmp>
    </PageMainContentsLayoutCmp>
  );
}
