import React from "react";
import { createRetreatProposalDBTemp } from "../../temp/createRetreatProposalDBTemp";

export const HomePageContext = React.createContext(""); // null is not working here

export default function HomePageContextProvider({ children }) {
  const [open, setOpen] = React.useState(false);
  const [bookedId, setBookedId] = React.useState([]);
  const [cardData, setCardData] = React.useState([]);
  const [bookedCardPopularData, setBookedCardPopularData] = React.useState([]);

  const handleBookedCardData = (id) => {
    const idx = bookedId.findIndex((el) => el === id);
    if (idx === -1) {
      setBookedCardPopularData([
        ...bookedCardPopularData,
        { ...createRetreatProposalDBTemp.find((el) => el.id === id) },
      ]);
    }
  };

  const handleBooking = (id) => {
    const idx = bookedId.findIndex((el) => el === id);
    if (idx === -1) {
      setBookedId([...bookedId, id]);
    } else {
      setBookedId((prev) => prev.filter((el) => el !== id));
    }
  };

  const handleRemoveCardData = (id) => {
    const idx = bookedId.findIndex((el) => el === id);
    if (idx !== -1) {
      setBookedCardPopularData((prev) =>
        prev.filter((el) => {
          return el.id !== id;
        })
      );
    }
  };

  const handleCardData = (id) => {
    setCardData({ ...createRetreatProposalDBTemp.find((el) => el.id === id) });
    setOpen(true);
  };
  return (
    <HomePageContext.Provider
      value={{
        handleCardData,
        open,
        cardData,
        setOpen,
        handleBookedCardData,
        bookedCardPopularData,
        handleBooking,
      }}
    >
      {children}
    </HomePageContext.Provider>
  );
}
