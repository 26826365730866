import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { CheckIconCmp, SaveIconSvg } from "../../components/Icons";
import { PageMainContentsLayoutCmp, PageSectionLayoutCmp } from "../../components/Layouts";
import { UserContext } from "../../providers/UserProvider";
import { useUserBookingCreateRetreatApi } from "../../services/apis/userBookingApi";
import { useUserRetreatPreferencesApi } from "../../services/apis/userRetreatPreferences";
import CreateRetreatContextProvider, { CreateRetreatContext } from "./CreateRetreatContext";
import CreateRetreatStep1Cmp from "./CreateRetreatStep1Cmp";
import CreateRetreatStep2Cmp from "./CreateRetreatStep2Cmp";
import CreateRetreatStep4Cmp from "./CreateRetreatStep4Cmp";
import FinalStepCmp from "./FinalStepCmp";

export default function CreateRetreatPage() {
  return (
    <CreateRetreatContextProvider>
      <CreateRetreatPageMain />
    </CreateRetreatContextProvider>
  );
}

function CreateRetreatPageMain() {
  const { stepNo } = React.useContext(CreateRetreatContext);

  return (
    <PageMainContentsLayoutCmp>
      <PageSectionLayoutCmp
        title="Create New Retreat"
        footer={stepNo !== 0 && stepNo !== 3 && <FooterCmp />}
      >
        {
          [
            <CreateRetreatStep1Cmp />,
            <CreateRetreatStep2Cmp />,
            // <CreateRetreatStep3Cmp />,
            <CreateRetreatStep4Cmp />,
            <FinalStepCmp />,
          ][stepNo]
        }
      </PageSectionLayoutCmp>
    </PageMainContentsLayoutCmp>
  );
}

function FooterCmp() {
  const { stepNo, handleNext, handleSubmit } = React.useContext(CreateRetreatContext);
  const { formData, updateFormValue, countriesData, name } = React.useContext(CreateRetreatContext);
  const { userEmail } = React.useContext(UserContext);

  const { userSetRetreatPreferences } = useUserRetreatPreferencesApi();
  const { userBookingCreateRetreatApi } = useUserBookingCreateRetreatApi();

  if (stepNo === 3)
    return (
      <Box
        display="flex"
        width="100%"
        alignItems={{ sm: "center" }}
        justifyContent="space-between"
        flexDirection={{ xs: "column", sm: "row" }}
        rowGap={{ xs: 2, md: 0 }}
      >
        <Typography variant="body1" color="text.light">
          Don't worry - you are not commiting to anything else yet. Please click "submit" to book a
          meeting with us.
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            handleNext();
          }}
        >
          <CheckIconCmp /> <Box ml={1.5}>Submit</Box>
        </Button>
      </Box>
    );
  return (
    <Button
      variant="contained"
      onClick={async () => {
        if (stepNo === 1) {
          await userBookingCreateRetreatApi(formData, userEmail);
          await userSetRetreatPreferences(formData);
        } else if (stepNo === 2) {
          // await userBookingApi({ userEmail });
        }
        handleNext();
      }}
    >
      <SaveIconSvg /> <Box ml={1.5}>Next</Box>
    </Button>
  );
}
