import axios from "axios";
import React from "react";

export const useUserSetActivityApi = (data) => {
  const userSetActivityApi = async () => {
    try {
      await axios.post("https://back.eira.ai/api/activity/", {
        ...data,
        activity_image: "https://image.com",
        check_in_date: null,
        check_out_date: null,
        available_time_slots: "4 PM",
      });
    } catch (error) {
      console.log(error);
    }
  };

  return { userSetActivityApi };
};

export const useUserGetActivityApi = () => {
  const [activityData, setActivityData] = React.useState([]);
  const userGetActivityApi = async () => {
    try {
      const response = await axios.get("https://back.eira.ai/api/activity/");
      setActivityData(response.data.activities);
    } catch (error) {
      console.log(error);
    }
  };
  return { userGetActivityApi, activityData };
};
