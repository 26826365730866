import axios from "axios";
import React from "react";

export const useSignUpApi = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const postSignUp = React.useCallback(
    async ({ user_email, password_hash, confirm_password_hash }) => {
      try {
        setError(false);
        setLoading(true);
        if (password_hash === confirm_password_hash) {
          await axios.post("https://back.eira.ai/api/user-credentials/", {
            user_email,
            password_hash,
            user_status: "Active",
          });
          const response = await axios.get("https://back.eira.ai/api/user-credentials/");
          window.localStorage.setItem(
            "user_id",
            response.data.users.find((el) => el.user_email === user_email).user_id
          );
          window.localStorage.setItem("isLoggedIn", "true");
        } else {
          setError(true);
          alert("Password does not match");
        }
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    loading,
    error,
    postSignUp,
  };
};

export const useGetUser = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [user, setUser] = React.useState({});
  const [userDataById, setUserDataById] = React.useState({});

  const getUser = async () => {
    try {
      setError(false);
      setLoading(true);
      // const response = await axios.get("https://back.eira.ai/api/user-profiles/
      // const userId = Number(window.localStorage.getItem("user_id"));

      //  setUser(response.data.user_profiles.find((el) => el.user_id === userId));
      // here also the user-profiles data is coming as get api in user profile apis file
      // console.log(user);
      const userData = await axios.get(
        "https://back.eira.ai/api/user-credentials/" + window.localStorage.getItem("user_id")
      );
      return userData.data;
      // setUserDataById(userData.data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    user,
    getUser,
    userDataById,
  };
};

export const useGetUserData = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const getUserData = async () => {
    try {
      setError(false);
      setLoading(true);
      const userData = await axios.get("https://back.eira.ai/api/user-credentials/");
      return userData.data;
      // setUserDataById(userData.data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    getUserData,
  };
};

export const usePutUser = (data) => {
  const putUser = async () => {
    try {
      await axios.put(
        "https://back.eira.ai/api/user-credentials/" + window.localStorage.getItem("user_id"),
        {
          ...data,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return { putUser };
};

// export const useDeleteUser = () => {
//   const deleteUser = async () => {
//     try {
//       await axios.delete(
//         "https://back.eira.ai/api/user-credentials/" + window.localStorage.getItem("user_id")
//       );
//       localStorage.removeItem("user_id");
//       localStorage.removeItem("company_id");
//       localStorage.removeItem("user_email");
//       localStorage.removeItem("user_profile_id");
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   return { deleteUser };
// };
