import React from "react";

export const UserCollaboratorContext = React.createContext(null);

export default function UserCollaboratorContextProvider({ children }) {
  const [userCollaborator, setUserCollaborator] = React.useState({
    collaborator_email_id: "",
    collaborator_first_name: "",
    collaborator_last_name: "",
    collaborator_status: "",
  });
  return (
    <UserCollaboratorContext.Provider value={{ userCollaborator, setUserCollaborator }}>
      {children}
    </UserCollaboratorContext.Provider>
  );
}
