import axios from "axios";

export const useUserManageTripsApi = () => {
  const userManageTripsApi = async (data, id) => {
    try {
      await axios.post("https://back.eira.ai/api/manage-trips/", {
        ...data,
        trip_id: id,
      });
    } catch (error) {
      console.log(error);
    }
  };
  return { userManageTripsApi };
};

export const useUserManageTripsGetApi = () => {
  const userManageTripsGetApi = async () => {
    try {
      const manageProposalData = await axios.get("https://back.eira.ai/api/manage-trips/");
      return manageProposalData.data;
    } catch (error) {
      console.log(error);
    }
  };
  return { userManageTripsGetApi };
};

export const useUserManageTripsDeleteApi = () => {
  const userManageTripsDeleteApi = async (id) => {
    try {
      await axios.delete("https://back.eira.ai/api/manage-trips/" + id);
    } catch (error) {
      console.log(error);
    }
  };
  return { userManageTripsDeleteApi };
};
