import React, { useState } from "react";
import { useGetUser, useGetUserData } from "../services/apis/userApis";
import { useUserGetTripsApi } from "../services/apis/userMyTripsApi";
import { useUserGetPriceProposalApi } from "../services/apis/userPriceProposalApi";
import { useUserGetProfileById } from "../services/apis/userSetProfileApi";
import { useUserGetPropsalApi, useUserPropsalGetApi } from "../services/apis/userSetProposal";

export const UserContext = React.createContext(null);

export default function UserProvider({ children }) {
  const { loading, getUserProfileById } = useUserGetProfileById();
  const [userProfile, setUserProfile] = React.useState({});

  const [proposalData, setProposalData] = useState([]);
  const [tripsData, setTripsData] = useState([]);
  const [proposalFullData, setProposalFullData] = useState([]);
  const [manage, setManage] = React.useState([]);

  const [userEmail, setUserEmail] = React.useState();

  const { getUser } = useGetUser();
  const fetchInitialData2 = React.useCallback(async () => {
    if (localStorage.getItem("isLoggedIn") !== null) {
      const emailData = await getUser();
      setUserEmail(emailData.user_email);
    }
  }, []);

  // console.log(manage);

  const { userGetProposalApi } = useUserGetPropsalApi();
  const { userProposalGetApi } = useUserPropsalGetApi();

  const fetchProposalData = React.useCallback(async () => {
    if (!userEmail) return;
    const data = await userGetProposalApi(userEmail);
    setProposalData(data);
  }, [userEmail]);

  // fetching my trips data

  const { userGetTripsApi } = useUserGetTripsApi();

  const fetchTripsData = React.useCallback(async () => {
    if (!userEmail) return;
    const data = await userGetTripsApi(userEmail);
    setTripsData(data);
  }, [userEmail]);

  // fetching Full my proposal data
  const fetchProposalFullData = React.useCallback(async () => {
    const data = await userProposalGetApi();
    setProposalFullData(data.myproposals);
  }, []);

  //price proposal
  const [priceProposalData, setPriceProposalData] = useState([]);

  const { userGetPriceProposalApi } = useUserGetPriceProposalApi();

  const fetchPriceProposalData = React.useCallback(async () => {
    const data = await userGetPriceProposalApi();
    setPriceProposalData(data.price_budgets);
  }, []);

  // user data
  const [userData, setUserData] = useState([]);

  const { getUserData } = useGetUserData();

  const fetchUserData = React.useCallback(async () => {
    const data = await getUserData();
    setUserData(data.users);
  }, []);

  //

  const fetchInitialData = React.useCallback(async () => {
    const data = await getUserProfileById();
    setUserProfile(data);
  }, []);

  React.useEffect(() => {
    fetchInitialData();
    fetchInitialData2();
    fetchProposalData();
    fetchProposalFullData();
    fetchPriceProposalData();
    fetchTripsData();
    fetchUserData();
  }, [
    fetchInitialData2,
    fetchProposalData,
    fetchProposalFullData,
    fetchPriceProposalData,
    fetchTripsData,
    fetchUserData,
  ]);

  return (
    <UserContext.Provider
      value={{
        userProfile,
        fetchInitialData,
        proposalData,
        priceProposalData,
        userData,
        proposalFullData,
        tripsData,
        userEmail,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
