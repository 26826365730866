import { Box } from "@mui/material";
import React from "react";
import ShopPageCardCmp from "../../components/ShopPageCardCmp";
import AboutPlaceDialogCmp from "../CreateRetreat/AboutPlaceDialogCmp";
import { ShopPageContext } from "./ShopPageContext";

export default function ShopPageActivityCmp({ activityData }) {
  const { setOpenTimeDialog } = React.useContext(ShopPageContext);
  const [open, setOpen] = React.useState(false);
  return (
    <Box display="flex" flexDirection="column" rowGap={4}>
      {activityData.map((el) => {
        return (
          <ShopPageCardCmp
            onClick={() => setOpen(true)}
            title="Ericeira Surf Experience and Team Building"
            content="Elevate team bonding with an exhilarating surf experience in beautiful Ericeira."
            image="https://assets-global.website-files.com/605baba32d94435376625d33/623b51260da8005767fc5ed3_invision-ericeira-cover.jpg"
            timeFromHotel={"0.5km from hotel"}
            hours={"3 hours"}
            time={"5:00PM"}
            onOpenTimeDialog={() => setOpenTimeDialog(true)}
          />
        );
      })}
      {/* <ShopPageCardCmp
        onClick={() => setOpen(true)}
        title="Spa and Wellness Experience"
        content="Treat your team to a relaxing Spa and Wellness Experience, fostering well-being in a luxurious setting."
        image="https://media.tacdn.com/media/attractions-splice-spp-674x446/0a/2a/ac/db.jpg"
        timeFromHotel={"0km from hotel"}
        hours={"24 hour access"}
        time={"n/a"}
        onOpenTimeDialog={() => setOpenTimeDialog(true)}
        added
        tag
      /> */}
      <AboutPlaceDialogCmp open={open} handleClose={() => setOpen(false)} />
    </Box>
  );
}
