import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { SearchIconCmp } from "../../components/Icons";
import { TextFieldWithDropdownCmp, TextFieldWithLabelCmp } from "../../components/Inputs";
import { useMultipleSelectHook } from "../../hooks/useMultipleSelectHook";
import { UserContext } from "../../providers/UserProvider";

export default function AdminPagePriceProposalCmp({
  setPriceProposalFormData,
  priceProposalFormData,
}) {
  const { state: activities, handleChange: handleChangeActivities } = useMultipleSelectHook();
  const { state: workshop, handleChange: handleChangeWorkshop } = useMultipleSelectHook();
  const { state: guide, handleChange: handleChangeGuide } = useMultipleSelectHook();

  const { userData, proposalData, proposalFullData } = React.useContext(UserContext);

  return (
    <Box>
      <Grid container columnSpacing={8} rowSpacing={4}>
        <Grid item xs={12} sm={6}>
          <TextFieldWithDropdownCmp
            placeholder="Select The Email ID"
            // actionText="See all results for "
            resultsHeading="Suggestions"
            value={priceProposalFormData.email_id}
            name={priceProposalFormData.email_id}
            onChange={(e) =>
              setPriceProposalFormData((prev) => ({ ...prev, email_id: e.target.value }))
            }
            results={userData.map((el) => ({
              icon: <SearchIconCmp fontSize="small" sx={{ color: "text.light" }} />,
              text: el.user_email,
            }))}
            onClickAction={() => {}}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWithDropdownCmp
            placeholder="Select The Proposal Name"
            // actionText="See all results for "
            value={priceProposalFormData.proposal_name}
            name={priceProposalFormData.proposal_name}
            resultsHeading="Suggestions"
            // defaultValue={search}
            onChange={(e) =>
              setPriceProposalFormData((prev) => ({ ...prev, proposal_name: e.target.value }))
            }
            results={proposalFullData
              .filter((el) => el.title !== "")
              .map((el) => ({
                icon: <SearchIconCmp fontSize="small" sx={{ color: "text.light" }} />,
                text: el.title,
              }))}
            onClickAction={() => {}}
          />
        </Grid>
      </Grid>
      <Box>
        <Box mt={4} />
        <Typography fontSize="18px" fontWeight={600}>
          Section 4: Options
        </Typography>
        <Box mt={4} />
      </Box>
      <Grid container columnSpacing={8} rowSpacing={4}>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Option Title"
            placeholder="Option Title"
            value={priceProposalFormData.option_title}
            onChange={(e) =>
              setPriceProposalFormData((prev) => ({ ...prev, option_title: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Option Date Range"
            placeholder="Option Date Range"
            value={priceProposalFormData.option_date_range}
            onChange={(e) =>
              setPriceProposalFormData((prev) => ({ ...prev, option_date_range: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Option No. of Particitants"
            placeholder="Option No. of Particitants"
            value={priceProposalFormData.option_participants}
            onChange={(e) =>
              setPriceProposalFormData((prev) => ({ ...prev, option_participants: e.target.value }))
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Option Days/Nights"
            placeholder="Option Days/Nights"
            value={priceProposalFormData.option_days_nights}
            onChange={(e) =>
              setPriceProposalFormData((prev) => ({ ...prev, option_days_nights: e.target.value }))
            }
          />
        </Grid>
      </Grid>
      <Box mt={4} />
      <Box>
        <Typography fontSize="18px" fontWeight={600}>
          Section 4: Option Budget
        </Typography>
      </Box>
      <Box mt={4} />
      <Grid container columnSpacing={8} rowSpacing={4}>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Accommodation"
            placeholder="Accommodation"
            value={priceProposalFormData.accommodation}
            onChange={(e) =>
              setPriceProposalFormData((prev) => ({ ...prev, accommodation: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Food & Bev"
            placeholder="Food & Bev"
            value={priceProposalFormData.food_bev}
            onChange={(e) =>
              setPriceProposalFormData((prev) => ({ ...prev, food_bev: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Worskpaces"
            placeholder="Worskpaces"
            value={priceProposalFormData.workspaces}
            onChange={(e) =>
              setPriceProposalFormData((prev) => ({ ...prev, workspaces: e.target.value }))
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Activities"
            placeholder="Activities"
            value={priceProposalFormData.activities}
            onChange={(e) =>
              setPriceProposalFormData((prev) => ({ ...prev, activities: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="EIRA Service Fees"
            placeholder="EIRA Service Fees"
            value={priceProposalFormData.eira_service_fees}
            onChange={(e) =>
              setPriceProposalFormData((prev) => ({ ...prev, eira_service_fees: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Total Price (inc. Sevice Fees)"
            placeholder="Total Price (inc. Sevice Fees)"
            value={priceProposalFormData.total_price_with_service_fees}
            onChange={(e) =>
              setPriceProposalFormData((prev) => ({
                ...prev,
                total_price_with_service_fees: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Total Price (inc. VAT)"
            placeholder="Total Price (inc. VAT)"
            value={priceProposalFormData.total_price_with_vat}
            onChange={(e) =>
              setPriceProposalFormData((prev) => ({
                ...prev,
                total_price_with_vat: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Total Price Per Participant"
            placeholder="Total Price Per Participant"
            value={priceProposalFormData.total_price_per_participant}
            onChange={(e) =>
              setPriceProposalFormData((prev) => ({
                ...prev,
                total_price_per_participant: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}

const activitiesOptions = [
  { id: 0, cmp: "Dedicated Workshop", value: "Dedicated Workshop" },
  { id: 1, cmp: "Great Location", value: "Great Location" },
  { id: 2, cmp: "Dedicated Workshop", value: "Dedicated Workshop" },
  { id: 3, cmp: "Great Location", value: "Great Location" },
  { id: 4, cmp: "Dedicated Workshop", value: "Dedicated Workshop" },
];

const WorkshopOptions = [
  { id: 0, cmp: "Dedicated Workshop", value: "Dedicated Workshop" },
  { id: 1, cmp: "Great Location", value: "Great Location" },
  { id: 2, cmp: "Dedicated Workshop", value: "Dedicated Workshop" },
  { id: 3, cmp: "Great Location", value: "Great Location" },
  { id: 4, cmp: "Dedicated Workshop", value: "Dedicated Workshop" },
];

const guideOptions = [
  { id: 0, cmp: "Dedicated Workshop", value: "Dedicated Workshop" },
  { id: 1, cmp: "Great Location", value: "Great Location" },
  { id: 2, cmp: "Dedicated Workshop", value: "Dedicated Workshop" },
  { id: 3, cmp: "Great Location", value: "Great Location" },
  { id: 4, cmp: "Dedicated Workshop", value: "Dedicated Workshop" },
];
