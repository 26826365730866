import { Box, Button, Tab, Tabs } from "@mui/material";
import React from "react";
import { CheckIconCmp, SettingsGearIconCmp } from "../../components/Icons";
import { PageMainContentsLayoutCmp, PageSectionLayoutCmp } from "../../components/Layouts";
import { usePageStepHook } from "../../hooks/usePageStepHook";
import { useUserPriceProposalApi } from "../../services/apis/userPriceProposalApi";
import { useUserSetProposalApi } from "../../services/apis/userSetProposal";
import AdminPageBookingsCmp from "./adminPageBookingsCmp";
import AdminPageManageProposalsTabCmp from "./adminPageManageProposalsTabCmp";
import AdminPageManageTripsTabCmp from "./adminPageManageTripsTabCmp";
import AdminPagePriceProposalCmp from "./adminPagePriceProposalCmp";
import AdminpageProposalCmp from "./adminPageProposalCmp";

export default function AdminPage() {
  const { stepNo, handleJump } = usePageStepHook();
  //Price Proposal
  const [priceProposalFormData, setPriceProposalFormData] = React.useState({
    email_id: "",
    proposal_name: "",
    option_title: "",
    option_date_range: "",
    option_participants: "",
    option_days_nights: "",
    accommodation: "",
    food_bev: "",
    workspaces: "",
    activities: "",
    eira_service_fees: "",
    total_price_with_service_fees: "",
    total_price_with_vat: "",
    total_price_per_participant: "",
    is_booked: "",
  });

  //Proposal
  const [proposalFormData, setProposalFormData] = React.useState({
    //Overview
    title: "",
    location_type: "",
    images: "overview.com",
    short_description: "",
    distance_from_airport: "",
    room_occupancy: "",
    capacity: "",
    what_do_you_love: "",
    sustainability_text: "",
    //Food & Bev
    fb_short_description: "",
    fb_card_title: "",
    fb_card_desc: "",
    fb_extra_short_desc: "",
    fb_extra_card_title: "",
    fb_extra_card_desc_p1: "",
    fb_extra_card_desc_p2: "",
    fb_images: "food.com",
    //Workspace
    workspace_short_desc: "",
    workspace_card_image: "workspace.com",
    workspace_card_title: "",
    workspace_card_room_size: "",
    workspace_card_capacity: "",
    workspace_extra_short_desc: "",
    //Activities
    activity_short_desc: "",
    outdoor_activity_title: "",
    indoor_activity_title: "",
    activity_images: "activities.com",
    //Price Proposal
    price_short_desc: "",
    proposal_component_title: "",
    proposal_component_desc: "",
    short_description_2: "",
    //policies
    policy_short_desc: "",
    policy_dos: "",
    policy_donts: "",
  });

  console.log(proposalFormData.title);

  const { userPriceProposalApi } = useUserPriceProposalApi(priceProposalFormData);

  const { userSetProposalApi } = useUserSetProposalApi(proposalFormData);

  // const { userSetGuideApi } = useUserSetGuideApi(guideFormData);

  // const { userSetActivityApi } = useUserSetActivityApi(activityFormData);

  // const { userSetWorkshopApi } = useUserSetWorkshopApi(workshopFormData);

  // const { userSetAccomodationApi } = useUserSetAccomodationApi(accomodationFormData);

  return (
    <PageMainContentsLayoutCmp>
      <PageSectionLayoutCmp
        title="Admin"
        footer={
          <Footer
            stepNo={stepNo}
            userSetProposalApi={userSetProposalApi}
            userPriceProposalApi={userPriceProposalApi}
          />
        }
      >
        <TabsCmp value={stepNo} handleChange={(_, newVal) => handleJump(newVal)} />
        <Box mt={4}>
          {
            [
              // <AdminPageActivityCmp
              //   activityFormData={activityFormData}
              //   setActivityFormData={setActivityFormData}
              // />,
              // <AdminPageWorkshopCmp
              //   workshopFormData={workshopFormData}
              //   setWorkshopFormData={setWorkshopFormData}
              // />,
              // <AdminPageAccommodationCmp
              //   accomodationFormData={accomodationFormData}
              //   setAccomodationFormData={setAccomodationFormData}
              // />,
              // <AdminPageConnoisseurCmp
              //   guideFormData={guideFormData}
              //   setGuideFormData={setGuideFormData}
              // />,
              <AdminpageProposalCmp
                setProposalFormData={setProposalFormData}
                proposalFormData={proposalFormData}
              />,
              <AdminPagePriceProposalCmp
                setPriceProposalFormData={setPriceProposalFormData}
                priceProposalFormData={priceProposalFormData}
              />,
              <AdminPageManageProposalsTabCmp />,
              <AdminPageManageTripsTabCmp />,
              <AdminPageBookingsCmp />,
            ][stepNo]
          }
        </Box>
      </PageSectionLayoutCmp>
    </PageMainContentsLayoutCmp>
  );
}

function TabsCmp({ value, handleChange }) {
  return (
    <Box borderBottom="0.5px solid rgba(33, 33, 33, 0.32)" sx={{ maxWidth: { md: "84vw" } }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="icon position tabs example"
        variant="scrollable"
        scrollButtons="auto"
      >
        {/* <Tab icon={<ActivityIconCmp />} iconPosition="start" label="Activity" />
        <Tab icon={<WorkshopIconCmp />} iconPosition="start" label="Workshop" />
        <Tab icon={<SettingsGearIconCmp />} iconPosition="start" label="Accommodation" />
        <Tab icon={<SettingsGearIconCmp />} iconPosition="start" label="Connoisseur" /> */}
        <Tab icon={<SettingsGearIconCmp />} iconPosition="start" label="Proposal" />
        <Tab icon={<SettingsGearIconCmp />} iconPosition="start" label="Price Proposal Section" />
        <Tab icon={<SettingsGearIconCmp />} iconPosition="start" label="Manage Proposals" />
        <Tab icon={<SettingsGearIconCmp />} iconPosition="start" label="Manage Trips" />
        <Tab icon={<SettingsGearIconCmp />} iconPosition="start" label="Bookings" />
      </Tabs>
    </Box>
  );
}

function Footer({ stepNo, userSetProposalApi, userPriceProposalApi }) {
  return (
    <>
      {stepNo !== 4 && stepNo !== 3 && stepNo !== 2 && (
        <Button
          variant="contained"
          startIcon={<CheckIconCmp />}
          onClick={async () => {
            if (stepNo === 0) {
              await userSetProposalApi();
            } else if (stepNo === 1) {
              await userPriceProposalApi();
            } else {
              console.log("Not Proposal");
            }
          }}
        >
          Create
        </Button>
      )}
    </>
  );
}
