import axios from "axios";
import React from "react";

export const useUserSetWorkshopApi = (data) => {
  const userSetWorkshopApi = async () => {
    try {
      await axios.post("https://back.eira.ai/api/workshop/", {
        ...data,
        workshop_image: "https://image.com",
        check_in_date: null,
        check_out_date: null,
        time_slots: "5 PM",
      });
    } catch (error) {
      console.log(error);
    }
  };

  return { userSetWorkshopApi };
};

export const useUserGetWorkshopApi = () => {
  const [workshopData, setWorkshopData] = React.useState([]);
  const userGetWorkshopApi = async () => {
    try {
      const response = await axios.get("https://back.eira.ai/api/workshop/");
      setWorkshopData(response.data.workshops);
    } catch (error) {
      console.log(error);
    }
  };
  return { userGetWorkshopApi, workshopData };
};
