import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import * as React from "react";
import StandardDialogCmp from "../../components/StandardDialogCmp";
import { useUserBookingApi } from "../../services/apis/userBookingApi";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmBookingSubmitDialog({ handleClose, open }) {
  const { userBookingApi } = useUserBookingApi();
  return (
    <StandardDialogCmp open={open} handleClose={handleClose} maxWidth="xs">
      <Box textAlign="center" px={6}>
        <Typography variant="h5Alt" fontWeight={500}>
          Are you sure you want to Book ?
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" columnGap={2} mt={4}>
        <Button variant="outlined" fullWidth size="small" onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          fullWidth
          size="small"
          onClick={async () => {
            handleClose();
            await userBookingApi();
          }}
        >
          Book
        </Button>
      </Box>
    </StandardDialogCmp>
  );
}
