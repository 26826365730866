import axios from "axios";
import React from "react";

export const useUserSetCollaboratorApi = (data) => {
  const userSetCollaboratorApi = async () => {
    try {
      await axios.post("https://back.eira.ai/api/collaborator-profiles/", {
        ...data,
        user_profile_id: localStorage.getItem("user_profile_id"),
        collaborator_user_profile_id: 1,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return { userSetCollaboratorApi };
};

export const useUserGetCollaboratorApi = () => {
  const [data, setData] = React.useState([]);
  const [editData, setEditData] = React.useState([]);
  const userGetCollaboratorApi = async (id) => {
    try {
      const response = await axios.get("https://back.eira.ai/api/collaborator-profiles/");
      // response.data.collaborators.find((el) =>
      //   el.collaborator_profile_id === id ? setEditData(response.data.collaborators[id - 1]) : ""
      // );
      setData(response.data.collaborators);
    } catch (error) {
      console.log(error);
    }
  };

  return { userGetCollaboratorApi, data };
};

export const useUserDeleteCollaboratorApi = () => {
  const userDeleteCollaboratorApi = async (id) => {
    try {
      await axios.delete("https://back.eira.ai/api/collaborator-profiles/" + id);
    } catch (error) {
      console.log(error);
    }
  };

  return { userDeleteCollaboratorApi };
};

export const useUserPutCollaboratorApi = (data, id) => {
  const userPutCollaboratorApi = async () => {
    try {
      await axios.put("https://back.eira.ai/api/collaborator-profiles/" + id, {
        ...data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return { userPutCollaboratorApi };
};
