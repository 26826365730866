import { Box, Typography } from "@mui/material";
import React from "react";
import { SearchIconCmp } from "../../components/Icons";
import { TextFieldWithDropdownCmp } from "../../components/Inputs";
import { useUserGetRetreatPreferences } from "../../services/apis/userRetreatPreferences";
import { CreateRetreatContext } from "./CreateRetreatContext";

export default function LeftCmp({ retreat_name }) {
  const { handleNext, formData, updateFormValue, setSelectedResult, selectedResult } =
    React.useContext(CreateRetreatContext);
  const { userGetRetreatPreferences, data } = useUserGetRetreatPreferences();
  React.useEffect(() => {
    userGetRetreatPreferences();
  }, []);
  return (
    <Box display="flex" flexDirection="column" bgcolor="secondary.main" p={3} borderRadius={3}>
      <TextFieldWithDropdownCmp
        onChange={(e) => updateFormValue("retreat_name", e.target.value)}
        name={formData.retreat_name}
        value={formData.retreat_name}
        selectedResult={selectedResult}
        setSelectedResult={setSelectedResult}
        placeholder="Create or Search Retreat"
        resultsHeading="Your Retreats"
        border="none"
        results={data
          .filter((el) => el.user_id == localStorage.getItem("user_id"))
          .reduce((acc, curr) => {
            if (acc.findIndex((el) => el.retreat_name === curr.retreat_name) === -1) {
              acc.push(curr);
            }
            return acc;
          }, [])
          .map((el) => ({
            icon: <SearchIconCmp fontSize="small" sx={{ color: "text.light" }} />,
            text: el.retreat_name,
          }))}
      />
      <Box pt={2} />
      <Typography variant="h4" color="primary" fontWeight={700}>
        {retreat_name}
      </Typography>
      <Typography variant="body1">To get started, fill out new information</Typography>
      <Box pt={2} />
      <Box p={2}>
        <img src="/images/create-retreat-2.png" alt="create reatreat" style={{ width: "100%" }} />
      </Box>
    </Box>
  );
}
