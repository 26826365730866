import { Box, Button, Grid, Link, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { IconWithTextSimpleChipCmp } from "../../components/Chips";
import {
  CreateRetreatIconCmp,
  LaunchIconCmp,
  LocationIconCmp,
  MembersIconCmp,
  TrainingIconCmp,
} from "../../components/Icons";
import { PageSectionLayoutCmp } from "../../components/Layouts";
import { useGetUser } from "../../services/apis/userApis";
import { createRetreatProposalDBTemp } from "../../temp/createRetreatProposalDBTemp";
import { toolBoxCardData } from "../../temp/toolBoxDBTemp";
import AboutPlaceDialogCmp from "../CreateRetreat/AboutPlaceDialogCmp";
import { HomePageContext } from "./HomePageContext";

export default function HomeBodyRightCmp() {
  const navigate = useNavigate();

  return (
    <>
      <PopularRetreatsCmp />
      <Box pt={2} />
      <Button
        variant="contained"
        startIcon={<CreateRetreatIconCmp />}
        fullWidth
        onClick={() => navigate("/create-retreat")}
      >
        Create New Retreat
      </Button>
      <Box pt={6} />
      <CultureToolboxCmp />
    </>
  );
}

function PopularRetreatsCmp() {
  const {
    handleCardData,
    open,
    cardData,
    setOpen,
    handleBookedCardData,
    handleBooking,
    handleRemoveCardData,
  } = React.useContext(HomePageContext);

  const [userEmail, setUserEmail] = React.useState({
    user_email: "",
  });

  const { getUser } = useGetUser();
  const fetchInitialData = React.useCallback(async () => {
    const emailData = await getUser();
    setUserEmail(emailData);
  }, []);
  React.useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  return (
    <Box pt={{ xs: 6, md: 0 }}>
      <PageSectionLayoutCmp title="Popular Retreats">
        <Box display="flex" flexDirection="column" rowGap={2}>
          {createRetreatProposalDBTemp.slice(0, 3).map((el, idx) => (
            <PopularRetreatsCardCmp
              key={idx}
              id={el.id}
              imgUrl={el.image[0]}
              title={el.title}
              location={el.location}
              handleCardData={handleCardData}
              handleBookedCardData={handleBookedCardData}
              handleBooking={handleBooking}
              chips={
                <>
                  <IconWithTextSimpleChipCmp icon={TrainingIconCmp} label={el.purpose} />
                  <IconWithTextSimpleChipCmp icon={MembersIconCmp} label={el.participants} />
                </>
              }
            />
          ))}
          <AboutPlaceDialogCmp
            open={open}
            handleClose={() => setOpen(false)}
            images={cardData.image}
            desc={cardData.longDesc}
            locationHighlights={cardData.locationHighlights}
            facilities={cardData.facilities}
            data={cardData.facilities}
            userEmail={userEmail.user_email}
            isDisplay="block"
          />
        </Box>
      </PageSectionLayoutCmp>
    </Box>
  );
}

function PopularRetreatsCardCmp({
  imgUrl,
  title,
  location,
  chips,
  id,
  handleCardData,
  handleBookedCardData,
  handleBooking,
}) {
  const imgSize = 90;

  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      alignItems="center"
      columnGap={1}
      onClick={async () => {
        await handleBooking(id);
        await handleCardData(id);
        await handleBookedCardData(id);
      }}
      sx={{ cursor: "pointer" }}
    >
      <Box width={imgSize} height={imgSize} borderRadius={4} overflow="hidden" flexShrink={0}>
        <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={imgUrl} />
      </Box>
      <Box display="flex" flexDirection="column" rowGap={1}>
        <Box>
          <IconWithTextSimpleChipCmp icon={LocationIconCmp} label={location} color="text.light" />
          <Box pt={0.5} />
          <Typography
            variant="body1"
            fontWeight={600}
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box
          display="flex"
          columnGap={1}
          flexWrap={{ xs: "wrap", md: "none" }}
          rowGap={{ xs: 1, md: 1 }}
        >
          {chips}
        </Box>
      </Box>
    </Box>
  );
}

// const tempData = [
//   { label: "Purpose", icon: <CheckIconCmp />, text: "What to bring" },
//   { label: "Team Dynamics", icon: <RemoteNetworkIconCmp />, text: "Leadership" },
//   { label: "People and Culture", icon: <AdminIconCmp />, text: "Branding" },
//   { label: "Values", icon: <PersonalBrandingCmp />, text: "Personal Branding" },
// ];

function CultureToolboxCmp() {
  return (
    <Box>
      <PageSectionLayoutCmp
        title="Culture Toolbox"
        action={
          <Link variant="body2" color="primary" fontWeight={700}>
            view all
          </Link>
        }
        bgcolor="transparent"
        p={0}
      >
        <Grid container spacing={2}>
          {toolBoxCardData.map((el, idx) => {
            return (
              <Grid item sm={6} key={idx} sx={{ cursor: "pointer" }}>
                <CultureToolboxCardCmp
                  key={idx}
                  label={el.section}
                  icon={el.icon}
                  text={el.text}
                  shortDesc={el.shortDesc}
                  id={el.id}
                />
              </Grid>
            );
          })}
        </Grid>
      </PageSectionLayoutCmp>
    </Box>
  );
}

function CultureToolboxCardCmp({ icon, label, text, shortDesc, id }) {
  const navigate = useNavigate();
  return (
    <Box bgcolor="#fff" borderRadius={4} onClick={() => navigate("/toolbox/postID/" + id)}>
      <Box px={1} pt={1} pb={3} display="flex" flexDirection="column" rowGap={1}>
        <Box display="flex" justifyContent="space-between">
          <Box
            color="#fff"
            bgcolor="primary.main"
            borderRadius="50%"
            height={32}
            width={32}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {icon}
          </Box>
          <LaunchIconCmp color="primary" />
        </Box>
        <Typography
          variant="body1"
          fontWeight={700}
          color="primary"
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
          }}
        >
          {label}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
          }}
        >
          {shortDesc}
        </Typography>
      </Box>
      <Box bgcolor="secondary.main" borderRadius={4} p={1} columnGap={0.5} display="flex">
        <Box display={{ md: "none", lg: "block" }}>
          <CultureToolboxCardChipCmp label={text} />
        </Box>
        <CultureToolboxCardChipCmp label="+2" />
      </Box>
    </Box>
  );
}

function CultureToolboxCardChipCmp({ label }) {
  return (
    <Box
      bgcolor="rgba(255,255,255,0.72)"
      borderRadius={4}
      py={0.5}
      px={1.5}
      display="flex"
      alignItems="center"
    >
      <Typography
        variant="caption"
        color="primary"
        sx={{
          display: "-webkit-box",
          overflow: "hidden",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 1,
        }}
      >
        {label}
      </Typography>
    </Box>
  );
}
