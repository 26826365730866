import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { MultiChipsCmp } from "../../components/Chips";
import { SearchIconCmp } from "../../components/Icons";
import { TextFieldWithDropdownCmp, TextFieldWithLabelCmp } from "../../components/Inputs";
import { useMultipleSelectHook } from "../../hooks/useMultipleSelectHook";
import { UserContext } from "../../providers/UserProvider";
import {
  useUserManageTripsApi,
  useUserManageTripsDeleteApi,
  useUserManageTripsGetApi,
} from "../../services/apis/userManageTripsApi";

export default function AdminPageManageTripsTabCmp() {
  const { state: activities, handleChange: handleChangeActivities } = useMultipleSelectHook();
  const { state: workshop, handleChange: handleChangeWorkshop } = useMultipleSelectHook();
  const { state: guide, handleChange: handleChangeGuide } = useMultipleSelectHook();

  const { userData, proposalData, proposalFullData } = React.useContext(UserContext);
  const [myTrip, setMyTrip] = React.useState([]);
  const [tripRemove, setTripRemove] = React.useState({
    title: "",
  });

  const { userManageTripsApi } = useUserManageTripsApi();
  const { userManageTripsGetApi } = useUserManageTripsGetApi();
  const { userManageTripsDeleteApi } = useUserManageTripsDeleteApi();

  const fetchMyTripData = React.useCallback(async () => {
    const data = await userManageTripsGetApi();
    setMyTrip(data.trips);
  }, []);

  const [id, setId] = useState("");
  const [removeTripId, setRemoveTripId] = React.useState("");

  const [manageTrips, setManageTrips] = React.useState({
    email_id: "",
    trip_title: "",
    trip_id: "",
    checkin_date: "",
    checkout_date: "",
    no_of_participants: "",
    days_and_nights: "",
    retreat_name: "",
  });

  function handleId() {
    proposalData.find((el) => el.title === manageTrips.proposal_title && setId(el.id));
    myTrip.find((el) => el.trip_title === tripRemove.title && setRemoveTripId(el.id));
  }

  console.log(removeTripId);

  React.useEffect(() => {
    handleId();
  }, [handleId]);

  const arr = [];

  for (let i = 0; i < myTrip.length; i++) {
    if (myTrip[i].email_id == manageTrips.email_id) {
      arr.push({ id: i, value: myTrip[i].trip_title, text: myTrip[i].trip_title });
    }
  }

  return (
    <Box>
      <Grid container columnSpacing={8} rowSpacing={4}>
        <Grid item xs={12} sm={6} display="flex" columnGap={2} alignItems="center">
          <Box flex={3}>
            <TextFieldWithDropdownCmp
              placeholder="Select The Email ID"
              // actionText="See all results for "
              resultsHeading="Suggestions"
              // defaultValue={search}
              onChange={(e) => setManageTrips((prev) => ({ ...prev, email_id: e.target.value }))}
              results={userData.map((el) => ({
                icon: <SearchIconCmp fontSize="small" sx={{ color: "text.light" }} />,
                text: el.user_email,
              }))}
              onClickAction={() => {}}
            />
          </Box>
          <Box flex={0.5}>
            <Button variant="contained" onClick={() => fetchMyTripData()}>
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box mt={5}>
        <Typography fontWeight={500} color="text.light" pb={1.3}>
          List of Proposals
        </Typography>
        <MultiChipsCmp data={arr} xs={8} md={3} height={80} maxSelectItems={0} />
      </Box>
      <Grid container columnSpacing={8} rowSpacing={4} mt={4}>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Retreat Name "
            placeholder="Retreat Name"
            value={manageTrips.retreat_name}
            onChange={(e) => setManageTrips((prev) => ({ ...prev, retreat_name: e.target.value }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Check In Date"
            placeholder="YYYY-MM-DD"
            value={manageTrips.checkin_date}
            onChange={(e) => setManageTrips((prev) => ({ ...prev, checkin_date: e.target.value }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Check Out Date"
            placeholder="YYYY-MM-DD"
            value={manageTrips.checkout_date}
            onChange={(e) => setManageTrips((prev) => ({ ...prev, checkout_date: e.target.value }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Number Of Participants"
            placeholder="Number Of Participants"
            value={manageTrips.no_of_participants}
            onChange={(e) =>
              setManageTrips((prev) => ({ ...prev, no_of_participants: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWithLabelCmp
            label="Days And Night"
            placeholder="Days And Night"
            value={manageTrips.days_and_nights}
            onChange={(e) =>
              setManageTrips((prev) => ({ ...prev, days_and_nights: e.target.value }))
            }
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={8} rowSpacing={4} mt={2}>
        <Grid item xs={12} sm={6}>
          <Typography fontWeight={500} color="text.light" pb={1.3}>
            Add A Trips
          </Typography>
          <Box display="flex" columnGap={1} alignItems="center">
            <Box flex={3}>
              <TextFieldWithDropdownCmp
                placeholder="Add A Trip"
                // actionText="See all results for "
                resultsHeading="Suggestions"
                // defaultValue={search}
                onChange={(e) =>
                  setManageTrips((prev) => ({ ...prev, trip_title: e.target.value }))
                }
                results={
                  proposalFullData
                    .filter((el1) => el1.title !== "")
                    .map((el) => ({
                      icon: <SearchIconCmp fontSize="small" sx={{ color: "text.light" }} />,
                      text: el.title,
                    }))
                  //   [
                  //   {
                  //     icon: <SearchIconCmp fontSize="small" sx={{ color: "text.light" }} />,
                  //     text: "Horse Riding",
                  //   },
                  //   {
                  //     icon: <SearchIconCmp fontSize="small" sx={{ color: "text.light" }} />,
                  //     text: "Skiing",
                  //   },
                  // ]
                }
                onClickAction={() => {}}
              />
            </Box>
            <Box flex={0.5}>
              <Button
                variant="contained"
                onClick={async () => {
                  await userManageTripsApi(manageTrips, id);
                }}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography fontWeight={500} color="text.light" pb={1.3}>
            Remove A Trips
          </Typography>
          <Box display="flex" columnGap={1} alignItems="center">
            <Box flex={3}>
              <TextFieldWithDropdownCmp
                placeholder="Remove A Trip"
                // actionText="See all results for "
                resultsHeading="Suggestions"
                // defaultValue={search}
                onChange={(e) => setTripRemove((prev) => ({ ...prev, title: e.target.value }))}
                results={arr.map((el) => ({
                  icon: <SearchIconCmp fontSize="small" sx={{ color: "text.light" }} />,
                  text: el.text,
                }))}
                onClickAction={() => {}}
              />
            </Box>
            <Box flex={0.5}>
              <Button variant="contained" onClick={() => userManageTripsDeleteApi(removeTripId)}>
                Remove
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
