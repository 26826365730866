import { Box, Button, Slide, Tab, Tabs } from "@mui/material";
import React from "react";
import {
  CheckIconCmp,
  ChevronArrowIconCmp,
  ProfileIconCmp,
  SettingsGearIconCmp,
} from "../../components/Icons";
import { PageMainContentsLayoutCmp, PageSectionLayoutCmp } from "../../components/Layouts";
import { usePageStepHook } from "../../hooks/usePageStepHook";
import { useGetUser, usePutUser } from "../../services/apis/userApis";
import {
  usePutCompanyProfile,
  useUserGetCompanyById,
} from "../../services/apis/userSetCompanyProfileApi";
import { usePutUserProfile, useUserGetProfileById } from "../../services/apis/userSetProfileApi";
import CollaborationSettingsCmp from "./CollaborationSettingsCmp";
import MyProfileSettingsCmp from "./MyProfileSettingsCmp";
import OrganisationSettingsCmp from "./OrganisationSettingsCmp";
import SnackBarSuccess from "./SnackBarSuccess";
import UserCollaboratorContextProvider from "./userCollaboratorContext";

export default function SettingsPage() {
  return (
    <UserCollaboratorContextProvider>
      <SettingsPageMain />
    </UserCollaboratorContextProvider>
  );
}

function SettingsPageMain() {
  const { getUserProfileById, loading } = useUserGetProfileById();
  const { getUserCompanyById } = useUserGetCompanyById();
  const { getUser } = useGetUser();
  const fetchInitialData = React.useCallback(async () => {
    const data = await getUserProfileById();
    const companyData = await getUserCompanyById();
    const emailData = await getUser();
    setUserCompanyProfile(companyData);
    setUserEmail(emailData);
    setUserProfileData(data);
  }, []);
  React.useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  const [userProfileData, setUserProfileData] = React.useState({
    user_first_name: "",
    user_last_name: "",
    user_linkedin_profile_url: "",
    user_designation: "",
  });
  const [userEmail, setUserEmail] = React.useState({
    user_email: "",
  });
  // company_profile
  const [userCompanyProfile, setUserCompanyProfile] = React.useState({
    company_name: "",
    company_size: "",
    company_location: "",
    company_url: "",
    company_business: "",
    company_business_desc: "",
    is_past_company_retreat: "",
    company_logo_url: "",
    company_logo_url: "",
    company_culture_deck_url: "",
  });

  //snackbar
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;

  function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }

  const handleClick = (newState) => () => {
    setState({ ...newState, open: true });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const { putUserProfile } = usePutUserProfile(userProfileData);

  const { putCompanyProfile } = usePutCompanyProfile(userCompanyProfile);

  const { putUser } = usePutUser(userEmail);

  const { stepNo, handleJump } = usePageStepHook();

  return (
    <PageMainContentsLayoutCmp>
      <PageSectionLayoutCmp
        title="Settings"
        footer={
          <Footer
            stepNo={stepNo}
            putUserProfile={putUserProfile}
            putUser={putUser}
            putCompanyProfile={putCompanyProfile}
            handleClick={handleClick({ vertical: "top", horizontal: "center", SlideTransition })}
          />
        }
      >
        <TabsCmp value={stepNo} handleChange={(_, newVal) => handleJump(newVal)} />
        <Box mt={4}>
          {
            [
              loading ? (
                <p>Loading...</p>
              ) : (
                <MyProfileSettingsCmp
                  setUserProfileData={setUserProfileData}
                  userProfileData={userProfileData}
                  setUserEmail={setUserEmail}
                  userEmail={userEmail}
                />
              ),

              <OrganisationSettingsCmp
                setUserCompanyProfile={setUserCompanyProfile}
                userCompanyProfile={userCompanyProfile}
              />,
              // <SubscriptionSettingsCmp />,
              <CollaborationSettingsCmp />,
            ][stepNo]
          }
        </Box>
        <SnackBarSuccess
          open={state.open}
          state={state}
          handleClose={handleClose}
          vertical={vertical}
          horizontal={horizontal}
        />
      </PageSectionLayoutCmp>
    </PageMainContentsLayoutCmp>
  );
}

function TabsCmp({ value, handleChange }) {
  return (
    <Box borderBottom="0.5px solid rgba(33, 33, 33, 0.32)" sx={{ maxWidth: { md: "84vw" } }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="icon position tabs example"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab icon={<ProfileIconCmp />} iconPosition="start" label="My Profile" />
        <Tab icon={<SettingsGearIconCmp />} iconPosition="start" label="Organization" />
        {/* <Tab icon={<OfferingsIconCmp />} iconPosition="start" label="Subscription" /> */}
        <Tab icon={<SettingsGearIconCmp />} iconPosition="start" label="Collaboration" />
      </Tabs>
    </Box>
  );
}

function Footer({ stepNo, putUserProfile, putUser, putCompanyProfile, handleClick }) {
  if (stepNo === 3)
    return (
      <Button
        variant="contained"
        startIcon={<ChevronArrowIconCmp />}
        onClick={() => alert("to implement")}
      >
        Import
      </Button>
    );

  return (
    <Button
      variant="contained"
      startIcon={<CheckIconCmp />}
      onClick={async () => {
        if (stepNo === 0) {
          await putUserProfile();
          await putUser();
        } else if (stepNo === 1) {
          await putCompanyProfile();
        }
        // window.location.reload(true);
        handleClick();
      }}
    >
      Update
    </Button>
  );
}
