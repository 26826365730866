import axios from "axios";
import React from "react";

export const useUserSetProfileApi = () => {
  const userSetProfileApi = async () => {
    try {
      await axios.post("https://back.eira.ai/api/user-profiles/", {
        user_id: window.localStorage.getItem("user_id"),
        user_first_name: "",
        user_last_name: "",
        user_designation: "",
        user_profile_status: "Active",
        company_id: window.localStorage.getItem("company_id"),
      });
      const response = await axios.get("https://back.eira.ai/api/user-profiles/");
      const { user_profile_id } = response.data.user_profiles.find(
        (el) => el.user_id === Number(window.localStorage.getItem("user_id"))
      );
      window.localStorage.setItem("user_profile_id", user_profile_id);
    } catch (error) {
      console.error(error);
    }
  };

  return { userSetProfileApi };
};

export const usePutUserProfile = (data) => {
  const putUserProfile = async () => {
    try {
      await axios.put(
        "https://back.eira.ai/api/user-profiles/" + window.localStorage.getItem("user_profile_id"),
        {
          ...data,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return { putUserProfile };
};

export const useUserGetProfileById = () => {
  const [loading, setLoading] = React.useState(false);
  const getUserProfileById = async () => {
    try {
      setLoading(true);
      const userProfileData = await axios.get(
        "https://back.eira.ai/api/user-profiles/" + window.localStorage.getItem("user_profile_id")
      );
      setLoading(false);
      // setUserProfileDataById(userProfileData.data);
      return userProfileData.data;
    } catch (error) {
      console.log(error);
    }
  };
  return { getUserProfileById, loading };
};
