import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { IconWithTextSimpleChipCmp } from "../../components/Chips";
import { ArrowHorizontalIconCmp, CalendarIconCmp } from "../../components/Icons";
import { useIsSm } from "../../hooks/useIsSmHook";
import { UserContext } from "../../providers/UserProvider";
import ConfirmBookingSubmitDialog from "./ConfirmBookingDialog";
import { MyProposalContext } from "./MyProposalContext";

export default function UpcomingPopularRetreatsCmp() {
  const [open, setOpen] = React.useState(false);
  const isSm = useIsSm();
  const { handleBookedCardData, handleBooking } = React.useContext(MyProposalContext);
  const { proposalData } = React.useContext(UserContext);
  console.log(proposalData.id);

  return (
    <Box>
      <Typography variant="h5">List Of Proposals</Typography>
      {proposalData.length > 0 ? (
        <>
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row", md: "column" }}
            flexWrap={{ sm: "wrap", md: "none" }}
            columnGap={{ sm: 2, md: 0 }}
            rowGap={2}
            mt={2}
          >
            {proposalData.map((el, idx) =>
              !isSm ? (
                <CardCmp
                  onClickInfo={() => setOpen(true)}
                  key={idx}
                  id={el.proposal_id}
                  image={el.activity_images}
                  title={el.proposal_title}
                  handleBooking={handleBooking}
                  handleBookedCardData={handleBookedCardData}
                  // team={el.team}
                  // day={el.day}
                  // month={el.month}
                  // duration={el.duration}
                  // dates={el.dates}
                  desc={el.activity_short_desc}
                />
              ) : (
                <CardMobileCmp
                  onClickInfo={() => setOpen(true)}
                  key={idx}
                  id={el.id}
                  image={el.images[0]}
                  title={el.name}
                  team={el.team}
                  day={el.day}
                  month={el.month}
                  duration={el.duration}
                  dates={el.dates}
                  desc={el.desc}
                />
              )
            )}
          </Box>
          <ConfirmBookingSubmitDialog open={open} handleClose={() => setOpen(false)} />
        </>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <img src="/images/homeLeftImg.png" />
          <Typography mt={2} variant="h5Alt" fontWeight={700}>
            There Are No Proposals
          </Typography>
        </Box>
      )}
    </Box>
  );
}

function CardCmp({
  image,
  title,
  team,
  duration,
  month,
  day,
  dates,
  id,
  desc,
  handleBooking,
  handleBookedCardData,
  onClickInfo,
}) {
  const navigate = useNavigate();

  return (
    <Box display="flex" alignItems="center" py={2} columnGap={4} justifyContent="space-between">
      {/* <Box display="flex" alignItems="center" columnGap={2} width={{ lg: 200 }} flexShrink={0}>
        <Box textAlign="center">
          <Typography variant="body2" color="text.light">
            {month}
          </Typography>
          <Typography variant="h2Alt" fontWeight={700}>
            {day}
          </Typography>
        </Box>
        <Box bgcolor="text.light" width="1px" height={28} borderRadius={2} />
        <Box>
          <Typography variant="body2">Retreat with {team}</Typography>
          <Typography variant="body2" color="text.light">
            {duration}
          </Typography>
        </Box>
      </Box> */}
      {/* <Box bgcolor="text.light" width={3} height={64} borderRadius={2} flexShrink={0} /> */}
      <Box display="flex" alignItems="center">
        <Box height={120} width={120} borderRadius={4} overflow="hidden" flexShrink={0}>
          <img
            src={image}
            alt="hey"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Box>
        <Box display="flex" flexDirection="column" mx={3}>
          <Typography variant="h5Alt" fontWeight={500}>
            {title}
          </Typography>
          <Box py={0.4} />
          <Typography variant="body2">{desc}</Typography>
          <Box py={0.4} />
          {/* <IconWithTextSimpleChipCmp icon={CalendarIconCmp} label={dates} /> */}
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={{ md: "column", lg: "row" }}
        rowGap={{ md: 2, lg: 0 }}
        alignItems="center"
        columnGap={3}
        flexShrink={0}
      >
        <Button
          variant="contained"
          onClick={async () => {
            // handleBooking(id);
            // handleBookedCardData(id);
            // onClickInfo();
            navigate("itinerary/" + id + "?tab=4");
          }}
        >
          Request to Book
        </Button>
        <Button
          variant="outlined"
          endIcon={<ArrowHorizontalIconCmp />}
          onClick={() => navigate("itinerary/" + id)}
        >
          See More
        </Button>
      </Box>
    </Box>
  );
}

function CardMobileCmp({ image, title, team, duration, month, day, dates, id, onClickInfo, desc }) {
  const navigate = useNavigate();

  return (
    <Box
      border="1px solid #C4DFB6"
      borderRadius={4}
      p={0.5}
      flex={1}
      width={{ sm: "325px", md: "100%" }}
    >
      <Box
        height={{ xs: 150, sm: 250 }}
        width="100%"
        borderRadius={4}
        overflow="hidden"
        flexShrink={0}
        onClick={onClickInfo}
      >
        <img
          style={{
            width: "100%",
            minHeight: "100%",
            objectFit: "cover",
          }}
          src={image}
          alt="hey"
        />
      </Box>
      <Box p={{ xs: 1, md: 2 }}>
        <Box display="flex" flexDirection="column">
          <Box py={0.4} />
          <Typography variant="h5Alt" fontWeight={500}>
            {title}
          </Typography>
          <Box py={0.4} />
          <Typography variant="body2">{desc}</Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Typography variant="body2">Retreat with {team}</Typography>
        <Box py={0.4} />
        <IconWithTextSimpleChipCmp icon={CalendarIconCmp} label={dates} />
        <Box py={2} />
        <Box display="flex" justifyContent="space-between" alignItems="center" flexShrink={0}>
          <Button variant="contained">Pay Now</Button>
          <Button
            variant="outlined"
            endIcon={<ArrowHorizontalIconCmp />}
            onClick={() => navigate("itinerary/" + id)}
          >
            itinerary
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
