import { Box, Button, Divider, IconButton, Menu, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { IconWithTextSimpleChipCmp } from "../../components/Chips";
import {
  ArrowHorizontalIconCmp,
  CalendarIconCmp,
  ChevronArrowIconCmp,
} from "../../components/Icons";
import { PageSectionLayoutCmp } from "../../components/Layouts";
import { UserContext } from "../../providers/UserProvider";

export default function HomeBodyLeftCmp() {
  return (
    <>
      <HeroSectionCmp />
      <Box pt={4} />
      <RetreatsCmp />
    </>
  );
}

function HeroSectionCmp() {
  const navigate = useNavigate();
  return (
    <PageSectionLayoutCmp title="Hi There, Step Into Serinity" bgcolor="secondary.main">
      <Box position="relative">
        <Box display="flex" flexDirection="column" rowGap={2}>
          <Typography variant="h4Alt" color="primary">
            Embark on a Journey of <br />
            Connection & Growth with EIRA!
          </Typography>
          <Typography variant="body2Alt">
            Book a free retreat planning
            <br /> call now!
          </Typography>
          <Box bgcolor="#fff" borderRadius={3} alignSelf="flex-start">
            <Button
              endIcon={<ArrowHorizontalIconCmp />}
              onClick={() => navigate("/create-retreat")}
            >
              Get Started
            </Button>
          </Box>
        </Box>
        <Box
          position="absolute"
          bottom="-2.5em"
          right={8}
          height="16em"
          display={{ xs: "none", lg: "flex" }}
        >
          <img src="/images/home-page-1.png" style={{ height: "100%" }} />
        </Box>
      </Box>
    </PageSectionLayoutCmp>
  );
}

function RetreatsCmp({ openMenu }) {
  const { tripsData } = React.useContext(UserContext);
  const [selectedItem, setSelectedItem] = React.useState(0);
  const [cardData, setCardData] = React.useState({ ...tripsData[0] });

  const handleChangeItem = (id) => {
    setSelectedItem(id);
    const selectedTrip = tripsData.find((el) => el.proposal_id === id);
    setCardData(selectedTrip);
  };

  return (
    <PageSectionLayoutCmp title="Upcoming Retreats">
      <CalendarCmp openMenu={openMenu} />
      {tripsData.length > 0 ? (
        <Box display={{ sm: "flex" }} mt={4} columnGap={{ xs: 2, md: 4 }}>
          <Box display={{ xs: "none", md: "flex" }} flexDirection="column" rowGap={2} flex={1}>
            {tripsData.map((el) => {
              return (
                <RetreatListItemCmp
                  teamName={el.retreat_name}
                  date="06"
                  month="April"
                  dayNight={el.days_and_nights}
                  id={el.proposal_id}
                  handleChangeItem={handleChangeItem}
                  selectedItem={selectedItem}
                />
              );
            })}
          </Box>
          <Box flex={1}>
            <RetreatCardCmp cardData={cardData} id={cardData.proposal_id} />
          </Box>
        </Box>
      ) : (
        <Box>
          <PlanRetreatCmp />
          {/* <RetreatCardCmp cardData={cardData} id={cardData.id} /> */}
        </Box>
      )}
    </PageSectionLayoutCmp>
  );
}

function CalendarCmp() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [year, setYear] = React.useState(2024);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  function nextYear() {
    setYear(year + 1);
  }

  function previousYear() {
    setYear(year - 1);
  }

  return (
    <Box display="flex" color="text.light">
      {/* <Box
        bgcolor="#F6F6F6"
        display="flex"
        alignItems="center"
        p={2}
        borderRadius={3}
        sx={{ cursor: "pointer" }}
      >
        <ChevronArrowIconCmp direction="left" />
      </Box> */}
      <Box
        display="flex"
        // mx={2}
        bgcolor="#F6F6F6"
        width="100%"
        height={56}
        flex={1}
        borderRadius={3}
        textAlign="center"
        justifyContent="center"
        pt={2}
      >
        <Box sx={{ cursor: "pointer" }} display="flex" columnGap={1} onClick={handleOpenMenu}>
          <CalendarIconCmp />
          <Typography variant="body1" fontWeight={500} color="inherit">
            {year}
          </Typography>
          <ChevronArrowIconCmp />
        </Box>
      </Box>
      {/* <Box
        bgcolor="#F6F6F6"
        display="flex"
        alignItems="center"
        p={2}
        borderRadius={3}
        sx={{ cursor: "pointer" }}
      >
        <ChevronArrowIconCmp direction="right" />
      </Box> */}
      <CalendarDialogCmp
        open={open}
        anchorEl={anchorEl}
        handleClose={handleCloseMenu}
        year={year}
        nextYear={nextYear}
        previousYear={previousYear}
      />
    </Box>
  );
}

function CalendarDialogCmp({ open, anchorEl, handleClose, year, nextYear, previousYear }) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      elevation={3}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&::before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 50,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <Box p={2}>
        <Box
          onClick={previousYear}
          bgcolor="#F6F6F6"
          display="flex"
          justifyContent="center"
          p={1}
          alignItems="center"
          borderRadius={3}
          sx={{ cursor: "pointer" }}
        >
          <ChevronArrowIconCmp direction="up" />
        </Box>
        <Box mt={2} mb={2}>
          <Typography
            fontWeight={400}
            fontSize="16px"
            fontFamily="Inter"
            textAlign="center"
            sx={{ opacity: "48%" }}
          >
            {year - 1}
          </Typography>
          <Box
            bgcolor="rgba(225, 236, 200, 0.6)"
            borderRadius={100}
            mt={1}
            mb={1}
            pl={2}
            pr={2}
            pt={1}
            pb={1}
          >
            <Typography fontWeight={700} fontSize="20px" fontFamily="Inter" color="primary.main">
              {year}
            </Typography>
          </Box>
          <Typography
            fontWeight={400}
            fontSize="16px"
            fontFamily="Inter"
            textAlign="center"
            sx={{ opacity: "48%" }}
          >
            {year + 1}
          </Typography>
        </Box>
        <Box
          onClick={nextYear}
          bgcolor="#F6F6F6"
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={1}
          borderRadius={3}
          sx={{ cursor: "pointer" }}
        >
          <ChevronArrowIconCmp direction="below" />
        </Box>
      </Box>
    </Menu>
  );
}

function PlanRetreatCmp() {
  const navigate = useNavigate();
  return (
    <Box display="flex" flexDirection="column" alignItems="center" rowGap={2}>
      <img src="/images/homeLeftImg.png" />
      <Typography variant="h5Alt" fontWeight={700}>
        The Corporate Escape Board is Empty
      </Typography>
      <Typography
        textAlign="center"
        fontFamily="Inter"
        fontWeight={400}
        fontSize="16px"
        sx={{ opacity: "48%" }}
      >
        Ready to Plan Your Team’s Next Retreat?
        <br /> Let the strategic bonding begin!
      </Typography>
      <Button variant="outlined" onClick={() => navigate("/create-retreat")}>
        Plan a Retreat
      </Button>
    </Box>
  );
}

function RetreatListItemCmp({
  teamName,
  date,
  month,
  dayNight,
  handleChangeItem,
  id,
  selectedItem,
}) {
  return (
    <Box
      onClick={() => {
        handleChangeItem(id);
      }}
      sx={{ cursor: "pointer" }}
      display="flex"
      alignItems="center"
      bgcolor={selectedItem === id && "#f6f6f6"}
      px={3}
      py={2}
      borderRadius="32px 100px 100px 32px"
      columnGap={3}
    >
      <Box textAlign="center">
        <Typography variant="body2" color={selectedItem === id ? "primary" : "text.light"}>
          {month}
        </Typography>
        <Typography variant="h2Alt" fontWeight={700} color={selectedItem === id && "primary"}>
          {date}
        </Typography>
      </Box>
      <Box bgcolor="text.light" width="1px" height="50%" borderRadius={2} />
      <Box>
        <Typography variant="body2">{teamName}</Typography>
        <Typography variant="body2" color="text.light">
          {dayNight}
        </Typography>
      </Box>
    </Box>
  );
}

function RetreatCardCmp({ cardData, id }) {
  const navigate = useNavigate();

  return (
    <Box border="1px solid #C4DFB6" borderRadius={4} p={0.5} flex={1}>
      <Box width="100%" borderRadius={4} overflow="hidden" height={250}>
        <img
          style={{
            width: "100%",
            minHeight: "100%",
            objectFit: "cover",
          }}
          src=""
        />
      </Box>
      <Box p={2} display="flex" flexDirection="column">
        <Typography variant="h5Alt" fontWeight={600}>
          {/* change it */}
          {cardData.title}
        </Typography>
        <Box display="flex" alignItems="center" mt={1} columnGap={0.5}>
          {/* <IconWithTextSimpleChipCmp icon={LocationIconCmp} label="Portugal" color="text.light" /> */}
        </Box>
        <Divider sx={{ my: 2 }} />
        <Typography variant="body2">{cardData.short_desc}</Typography>
        <Box display="flex" mt={4} columnGap={3} flexWrap="wrap" rowGap={2}>
          <Box display={{ md: "none" }}>
            <IconWithTextSimpleChipCmp icon={CalendarIconCmp} label="19 Feb 2024" />
          </Box>
          {/* <IconWithTextSimpleChipCmp icon={GolfFlagIconCmp} label="Strategy Offsite" />
          <IconWithTextSimpleChipCmp icon={MembersIconCmp} label="25 Participants" /> */}
        </Box>
        <IconButton
          onClick={() => navigate("/my-trips/itinerary/" + id)}
          sx={{
            alignSelf: "flex-end",
            float: "right",
            marginTop: 2,
            display: { xs: "none", md: "flex" },
          }}
          variant="rounded"
        >
          <ArrowHorizontalIconCmp fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
}

const retreatListItemData = [
  {
    id: 0,
    date: "10",
    month: "Apr",
    team: "Retreat with Design Team Offsite",
    dayNight: "7 Days, 6 Nights",
  },
  {
    id: 1,
    date: "12",
    month: "May",
    team: "Retreat with Sales Team Offsite",
    dayNight: "2 Days, 1 Nights",
  },
  {
    id: 2,
    date: "16",
    month: "Jun",
    team: "Retreat with Product Team Offsite",
    dayNight: "4 Days, 3 Nights",
  },
];
