import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import * as React from "react";
import { DropdownCmp, TextFieldWithLabelCmp } from "../../components/Inputs";
import StandardDialogCmp from "../../components/StandardDialogCmp";
import {
  useUserGetCollaboratorApi,
  useUserSetCollaboratorApi,
} from "../../services/apis/userSetCollaborationApi";
import { UserCollaboratorContext } from "./userCollaboratorContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddMemberDialog({ handleClose, open }) {
  const { userCollaborator, setUserCollaborator } = React.useContext(UserCollaboratorContext);
  const { userSetCollaboratorApi } = useUserSetCollaboratorApi(userCollaborator);
  const { userGetCollaboratorApi } = useUserGetCollaboratorApi();

  function refreshPage() {
    window.location.reload(true);
  }

  return (
    <StandardDialogCmp
      title="Add members to your organisation"
      open={open}
      handleClose={handleClose}
    >
      <Box display="flex" flexDirection="column" rowGap={2} mt={4}>
        <Box display="flex" justifyContent="space-between" columnGap={4}>
          <TextFieldWithLabelCmp
            label="First Name"
            value={userCollaborator.collaborator_first_name}
            onChange={(e) =>
              setUserCollaborator((prev) => ({ ...prev, collaborator_first_name: e.target.value }))
            }
          />
          <TextFieldWithLabelCmp
            label="Last Name"
            value={userCollaborator.collaborator_last_name}
            onChange={(e) =>
              setUserCollaborator((prev) => ({ ...prev, collaborator_last_name: e.target.value }))
            }
          />
        </Box>
        <TextFieldWithLabelCmp
          label="Email"
          value={userCollaborator.collaborator_email_id}
          onChange={(e) =>
            setUserCollaborator((prev) => ({ ...prev, collaborator_email_id: e.target.value }))
          }
        />
        <DropdownCmp
          label="Role"
          options={options}
          value={userCollaborator.collaborator_status}
          onChange={(e) =>
            setUserCollaborator((prev) => ({ ...prev, collaborator_status: e.target.value }))
          }
        />
        <Box mt={2}>
          <Button
            variant="contained"
            fullWidth
            size="small"
            onClick={async () => {
              await userSetCollaboratorApi();
              refreshPage();
            }}
          >
            Invite
          </Button>
        </Box>
      </Box>
    </StandardDialogCmp>
  );
}

const options = [
  { id: 0, cmp: "Planner", value: "Planner" },
  { id: 1, cmp: "Participant", value: "Participant" },
];
