import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import * as React from "react";
import StandardDialogCmp from "../../components/StandardDialogCmp";
import { useUserPutPriceProposalApi } from "../../services/apis/userPriceProposalApi";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PriceProposalBookDialog({ handleClose, open, id }) {
  const { userPutPriceProposalApi } = useUserPutPriceProposalApi();

  return (
    <StandardDialogCmp open={open} handleClose={handleClose} maxWidth="xs">
      <Box textAlign="center" px={2}>
        <Typography variant="h5Alt" fontWeight={500}>
          Thank you for requesting to book with EIRA. We will get back to you as soon as possible to
          finalize your booking.
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" columnGap={2} mt={4}>
        <Button variant="outlined" fullWidth size="small" onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button
          fullWidth
          variant="contained"
          onClick={async () => {
            handleClose();
            userPutPriceProposalApi(id);
          }}
        >
          Request a Book
        </Button>
      </Box>
    </StandardDialogCmp>
  );
}
