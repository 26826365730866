import { AddCircleOutline } from "@mui/icons-material";
import { Avatar, Box, Button, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import { useUserParticipantsGetApi } from "../../services/apis/userParticipantsApi";
import AddParticipantsDialog from "./AddParticipantsDialog";
import ItineraryBodyRightCmp from "./ItineraryBodyRightCmp";
import { ItineraryPageContext } from "./ItineraryPageContext";

export default function ItineraryTabParticipantsCmp() {
  return (
    <Box display="flex" columnGap={8}>
      <Box flex={3}>
        <LeftCmp />
      </Box>
      <Box flex={1.3} display={{ xs: "none", md: "block" }}>
        <ItineraryBodyRightCmp />
      </Box>
    </Box>
  );
}

function LeftCmp() {
  const [open, setOpen] = useState(false);
  const { retreat } = React.useContext(ItineraryPageContext);

  const { data, userParticipantsGetApi } = useUserParticipantsGetApi();
  React.useEffect(() => {
    userParticipantsGetApi();
  }, []);

  return (
    <Box display="flex" flexDirection="column">
      <Box mt={4}>
        <Box display="flex" flexDirection="column" rowGap={2}>
          <Box display="flex">
            <Box flex={1}>
              <Typography fontWeight={500} color="text.light">
                Name
              </Typography>
              <Box display="flex" alignItems="center"></Box>
            </Box>
            <Box flex={1}>
              <Typography fontWeight={500} color="text.light">
                Email
              </Typography>
            </Box>
          </Box>
          <Divider />
          {data
            .filter(
              (el) => el.user_id == localStorage.getItem("user_id") && el.trip_name == retreat.title
            )
            .map((el) => (
              <RowCmp name={el.participant_name} email={el.email_id} />
            ))}
        </Box>
        <Box display="flex" justifyContent="center" mt={{ xs: 2, sm: 4 }}>
          <Button color="primary" onClick={() => setOpen(true)}>
            <AddCircleOutline /> <Box mx={0.5} /> Invite More
          </Button>
          {/* <Button variant="outlined">Notify All</Button> */}
        </Box>
        <AddParticipantsDialog handleClose={() => setOpen(false)} open={open} />
      </Box>
    </Box>
  );
}

function RowCmp({ name, email }) {
  return (
    <>
      <Box display="flex" alignItems="center">
        <Box flex={1} display="flex" alignItems="center" columnGap={2}>
          <Avatar
            src="https://img.freepik.com/premium-vector/avatar-profile-colorful-illustration-2_549209-82.jpg"
            alt="profile"
          />
          <Typography>{name}</Typography>
        </Box>
        <Box flex={1} display="flex" alignItems="center">
          <Typography>{email}</Typography>
        </Box>
        {/* <Box>
          <Button variant="outlined" sx={{ padding: 0 }}>
            Notify
          </Button>
        </Box> */}
      </Box>
      <Divider />
    </>
  );
}
