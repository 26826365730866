import { Box, Typography } from "@mui/material";
import { MultiChipsCmp } from "../../components/Chips";
import { TextFieldWithLabelCmp } from "../../components/Inputs";

export default function OnboardingFormStep1Cmp({ userProfileDataOnb, setUserProfileDataOnb }) {
  return (
    <Box display="flex" flexDirection="column" rowGap={4}>
      <Box>
        <Typography variant="h5Alt" fontWeight={700}>
          Let's Get to Know You
        </Typography>
        <Box pt={1} />
        <Typography variant="body2Alt" color="text.light">
          This information will be used to personalize your retreat dashboard. You can adjust these
          settings now or change them later directly on your profile.
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems="center"
        columnGap={3}
        rowGap={{ xs: 2, sm: 0 }}
      >
        <TextFieldWithLabelCmp
          label="First Name"
          value={userProfileDataOnb.user_first_name}
          onChange={(e) =>
            setUserProfileDataOnb((prev) => ({ ...prev, user_first_name: e.target.value }))
          }
        />
        <TextFieldWithLabelCmp
          label="Last Name"
          value={userProfileDataOnb.user_last_name}
          onChange={(e) =>
            setUserProfileDataOnb((prev) => ({ ...prev, user_last_name: e.target.value }))
          }
        />
      </Box>
      <Box>
        <Typography fontWeight={500} color="text.light">
          What Describes you the best?
        </Typography>
        <Box pt={1} />
        <MultiChipsCmp
          // correct onChange if not working
          onChange={(sel) =>
            setUserProfileDataOnb((prev) => ({ ...prev, user_designation: sel.toString() }))
          }
          // onChange={(sel) => setUserDesignation(sel.toString())}
          data={data}
          height={48}
          xs={12}
          sm={6}
          spacing={2}
          typographyVariant="body2"
        />
      </Box>
    </Box>
  );
}

const data = [
  {
    id: 1,
    text: "CEO or Founder",
    value: "CEO or Founder",
  },
  {
    id: 2,
    text: "People & HR",
    value: "People & HR",
  },
  {
    id: 3,
    text: "Team Lead",
    value: "Team Lead",
  },
  {
    id: 4,
    text: "Team Member",
    value: "Team Member",
  },
  {
    id: 5,
    text: "Admin / Operations",
    value: "Admin / Operations",
  },
  {
    id: 6,
    text: "Other",
    value: "Other",
  },
  // {
  //   id: 7,
  //   text: (
  //     <>
  //       Operations/
  //       <br />
  //       Administration
  //     </>
  //   ),
  //   value: "Operations/Administration",
  // },
  // {
  //   id: 8,
  //   text: "Sales/Account Management",
  //   value: "Sales/Account Management",
  // },
  // {
  //   id: 9,
  //   text: "Legal",
  //   value: "Legal",
  // },
  // {
  //   id: 10,
  //   text: "Communication",
  //   value: "Communication",
  // },
  // {
  //   id: 11,
  //   text: "Other",
  //   value: "Other",
  // },
];
