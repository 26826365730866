import { Box, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { InlineWidget } from "react-calendly";
import { MultiChipsCmp } from "../../components/Chips";
import { ChevronArrowIconCmp } from "../../components/Icons";
// import image from "../images/create-retreat-s4.svg";

export default function CreateRetreatStep4Cmp() {
  return (
    <>
      <Grid container display="flex" justifyContent="space-between" alignItems="center">
        <Grid item xs={6} display={{ xs: "none", lg: "block" }}>
          <Box height={600} display="flex" justifyContent="center" alignItems="center" padding={8}>
            <img
              src="/images/create-retreat-3.png"
              alt="create reatreat"
              style={{ height: "100%" }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box>
            <FormCmp />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

function FormCmp() {
  return (
    <Box bgcolor="secondary.main" borderRadius={4} padding={4} ml={{ xs: 0.2, lg: 3 }}>
      <Box>
        <Typography variant="h4Alt" color="primary">
          Let's get planning
        </Typography>
        <Box pt={1} />
        <Typography variant="body2Alt">
          Hop on an exploration call with EIRA expert ot recieve <br />
          tailored recommendtion for your retreat
        </Typography>
        <InlineWidget
          url="https://calendly.com/eira-work/first-call-with-eira?month=2024-02"
          // try to hide the scrollbar - imp
          styles={{ width: "100%", height: "790px", marginTop: "24px" }}
        />
        {/* <Grid item xs={16} sm={8}>
              <CalendarContainerCmp />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TimeCmp />
            </Grid> */}
      </Box>
    </Box>
  );
}

function CalendarContainerCmp() {
  return (
    <Box mt={4}>
      <Typography variant="body1" fontWeight={500} color="text.light">
        Pick a Date
      </Typography>
      <CalendarCmp />
    </Box>
  );
}

const cellSize = 48;
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function CalendarCmp() {
  const todaysDate = new Date("Sun Feb 07 2024 04:17:48 GMT+0530");
  const firstDay = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), 1).getDay();
  const prevMonthDays = new Date(
    todaysDate.getFullYear(),
    (12 + todaysDate.getMonth() - 1) % 12,
    0
  ).getDate();
  const currMonthDays = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), 0).getDate();

  return (
    <Box mx={{ xs: 0, md: 4 }} width={{ xs: cellSize * 5, sm: cellSize * 7 }} mt={4}>
      <Box display="flex" alignItems="center" justifyContent="space-between" color="text.main">
        <IconButton>
          <ChevronArrowIconCmp direction="left" />
        </IconButton>
        <Typography variant="body1" fontWeight={700}>
          {months[todaysDate.getMonth()]}
        </Typography>
        <IconButton>
          <ChevronArrowIconCmp direction="right" />
        </IconButton>
      </Box>
      <Box mt={2}>
        <Box display="flex">
          {["S", "M", "T", "W", "T", "F", "S"].map((el, idx) => (
            <DateCmp key={idx} date={el} color="primary.main" />
          ))}
        </Box>
        {[...new Array(5)].map((_, week) => {
          const startDate = week * 7;

          return (
            <Box key={week} display="flex">
              {[...new Array(7)].map((_, day) => {
                if (startDate + day < firstDay)
                  return (
                    <DateCmp
                      key={day}
                      date={prevMonthDays - firstDay + day + 1}
                      color="text.light"
                      fontWeight={400}
                    />
                  );
                else if (startDate + day > currMonthDays)
                  return (
                    <DateCmp
                      key={day}
                      date={startDate + day - currMonthDays}
                      color="text.light"
                      fontWeight={400}
                    />
                  );
                else
                  return (
                    <DateCmp
                      key={day}
                      date={startDate + day + 1 - firstDay}
                      selected={startDate + day + 1 - firstDay === 7}
                    />
                  );
              })}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

function DateCmp({ date, color, selected, fontWeight = 500 }) {
  return (
    <Box
      height={{ xs: cellSize - 16, sm: cellSize - 8 }}
      width={{ xs: cellSize - 16, sm: cellSize - 8 }}
      m={{ xs: 0.2, md: 0.5 }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      color={color}
      sx={{
        cursor: "pointer",
        ...(selected && { borderRadius: "50%", backgroundColor: "primary.main", color: "white" }),
      }}
    >
      <Typography variant="body1" color="inherit" fontWeight={fontWeight}>
        {date}
      </Typography>
    </Box>
  );
}

function TimeCmp() {
  return (
    <Box mt={4}>
      <Typography variant="body1" fontWeight={500} color="text.light">
        Pick a Time
      </Typography>
      <Box mt={4}>
        <MultiChipsCmp
          data={[
            { id: 1, text: "1 PM" },
            { id: 2, text: "2 PM" },
            { id: 3, text: "3 PM" },
            { id: 4, text: "4 PM" },
            { id: 5, text: "5 PM" },
            { id: 6, text: "6 PM" },
          ]}
          xs={6}
          spacing={{ xs: 2, md: 1 }}
          height={48}
          maxSelectItems={1}
        />
      </Box>
    </Box>
  );
}
