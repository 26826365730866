import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

export default function AuthCmp() {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (
      localStorage.getItem("isLoggedIn") == "false" ||
      localStorage.getItem("isLoggedIn") === null
    ) {
      navigate("/login");
    } else {
      navigate("/home");
    }
  }, []);
  return <Outlet />;
}
