import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { TextWithLinkCmp } from "../../components/Chips";
import { TextFieldWithLabelCmp } from "../../components/Inputs";
import { LoginSignupPagesLayoutCmp } from "../../components/Layouts";
import { useLogInApi } from "../../services/apis/userApisLoggedIn";

export default function LoginPage() {
  const [user_email, setEmailLogin] = React.useState();
  const [password_hash, setPasswordLogin] = React.useState();

  const navigate = useNavigate();

  const { getLogIn } = useLogInApi();

  return (
    <LoginSignupPagesLayoutCmp>
      <Typography variant="h4Alt" fontWeight={700}>
        Welcome to EIRA
      </Typography>
      {/* <Box
        bgcolor="secondary.main"
        p={2}
        borderRadius={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <IconWithTextSimpleChipCmp
          icon={GoogleIconCmp}
          label="Sign in with Google"
          large
          fontWeight={700}
          columnGap={1}
        />
      </Box> */}
      {/* <Box
        position="relative"
        display="flex"
        justifyContent="center"
        width="100%"
        borderBottom="1px solid rgba(230, 230, 230, 1)"
      >
        <Box position="absolute" top={-12} bgcolor="#fff" px={1}>
          <Typography fontWeight={600} color="text.light">
            OR
          </Typography>
        </Box>
      </Box> */}
      <Box display="flex" flexDirection="column" rowGap={2}>
        <TextFieldWithLabelCmp
          label="Email Address"
          onChange={(e) => setEmailLogin(e.target.value)}
          name={user_email}
          type={user_email}
        />
        <Box display="flex" flexDirection="column">
          <TextFieldWithLabelCmp
            label="Password"
            type="password"
            onChange={(e) => setPasswordLogin(e.target.value)}
            name={password_hash}
          />
          <Box alignSelf="flex-end" mt={1} sx={{ cursor: "pointer" }}>
            <Box sx={{ cursor: "pointer" }} onClick={() => navigate("/forgot-password")}>
              <Typography color="text.light">Forgot Password?</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Button
          variant="contained"
          fullWidth
          onClick={async () => {
            const userFound = await getLogIn({ user_email, password_hash });
            if (userFound === true) {
              localStorage.setItem("isLoggedIn", "true");
              navigate("/home");
              window.location.reload(true);
            } else {
              alert("Not Logged IN!!!");
            }
          }}
        >
          Login
        </Button>
      </Box>
      <TextWithLinkCmp
        label="Don’t have an account?"
        linkText="Signup for free."
        onClick={() => navigate("/signup")}
      />
    </LoginSignupPagesLayoutCmp>
  );
}
