import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { ItineraryProposalPageContext } from "./ItineraryProposalPageContext";

export default function ItineraryProposalTabWorkspaceCmp() {
  return (
    <Box display="flex" columnGap={8}>
      <LeftCmp />
    </Box>
  );
}

function LeftCmp() {
  const [openAboutDialog, setOpenAboutDialog] = React.useState(false);
  const [openOfferingsDialog, setOpenOfferingsDialog] = React.useState(false);
  const { proposal } = React.useContext(ItineraryProposalPageContext);

  return (
    <Box display="flex" flexDirection="column" rowGap={5}>
      <SectionCmp heading="About the Workspace">
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="body1" lineHeight={1.8}>
            {proposal.workspace_short_desc}
          </Typography>
          {/* <Box mt={1} sx={{ cursor: "pointer" }} onClick={() => setOpenAboutDialog(true)}>
            <IconWithTextSimpleChipCmp
              icon={ArrowHorizontalIconCmp}
              label="Show More"
              reverse
              color="primary.main"
              fontWeight={700}
            />
          </Box> */}
        </Box>
      </SectionCmp>
      {/* <Grid xs={12} container columnGap={2} rowGap={2}>
        {proposal.workspace_items.map((el) => {
          return (
            <Grid bgcolor="secondary.main" width="48%" padding={2} borderRadius={2}>
              <Typography variant="body1" color="primary" fontWeight={700}>
                {el.title}
              </Typography>
              <Box mt={1} />
              {el.items.map((item) => {
                return (
                  <li style={{ fontSize: "14px", fontFamily: "Inter", fontWeight: 500 }}>{item}</li>
                );
              })}
            </Grid>
          );
        })}
      </Grid> */}
      <RetreatCardMainCmp
        cardTitle={proposal.workspace_card_title.split("; ")}
        cardTitleLength={proposal.workspace_card_title.split("; ").length}
        cardRoomSize={proposal.workspace_card_room_size.split("; ")}
        cardCapacity={proposal.workspace_card_capacity.split("; ")}
      />
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Typography variant="body1" lineHeight={1.8}>
          {proposal.workspace_extra_short_desc}
        </Typography>
      </Box>
    </Box>
  );
}

function RetreatCardMainCmp({ cardTitle, cardRoomSize, cardCapacity, cardTitleLength }) {
  const arr = [];
  for (let i = 0; i < cardTitleLength; i++) {
    arr.push(i);
  }
  return (
    <Box>
      <Box display="flex" flexWrap="wrap" columnGap={4} rowGap={4}>
        {arr.map((el) => {
          return (
            <Box sm={4}>
              <RetreatCardCmp
                cardTitle={cardTitle[el]}
                cardRoomSize={cardRoomSize[el]}
                cardCapacity={cardCapacity[el]}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

function RetreatCardCmp({ cardTitle, cardRoomSize, cardCapacity }) {
  return (
    <Box border="1px solid #C4DFB6" borderRadius={4} p={0.5} flex={1} width={340} height="100%">
      <Box width="100%" borderRadius={4} overflow="hidden" height={250}>
        <img
          style={{
            width: "100%",
            minHeight: "100%",
            objectFit: "cover",
          }}
          src=""
          alt="hey"
        />
      </Box>
      <Box p={2} display="flex" flexDirection="column">
        <Typography variant="h5Alt" fontWeight={600}>
          {/* change it */}
          {cardTitle}
        </Typography>
        {/* <Box display="flex" alignItems="center" mt={1} columnGap={0.5}>
          <IconWithTextSimpleChipCmp icon={LocationIconCmp} label="Portugal" color="text.light" />
        </Box> */}
        <Divider sx={{ my: 2 }} />
        <Box display="flex" columnGap={1}>
          <Typography variant="body2">Room Size: </Typography>
          <Typography
            variant="body2"
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
            }}
          >
            {cardRoomSize} sqm
          </Typography>
        </Box>
        <Box display="flex" columnGap={1}>
          <Typography variant="body2">Capacity: </Typography>
          <Typography
            variant="body2"
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
            }}
          >
            {cardCapacity}
          </Typography>
        </Box>
        {/* <Box display="flex" mt={4} columnGap={3} flexWrap="wrap" rowGap={2}>
          <Box display={{ md: "none" }}>
            <IconWithTextSimpleChipCmp icon={CalendarIconCmp} label="19 Feb 2024" />
          </Box>
          <IconWithTextSimpleChipCmp icon={GolfFlagIconCmp} label="Strategy Offsite" />
          <IconWithTextSimpleChipCmp icon={MembersIconCmp} label="25 Participants" />
        </Box> */}
      </Box>
    </Box>
  );
}

function SectionCmp({ heading, children, headingExtra }) {
  return (
    <Box>
      <Box display="flex" alignItems="center" columnGap={2}>
        <Typography variant="h5Alt" fontWeight={600}>
          {heading}
        </Typography>
        {headingExtra}
      </Box>
      <Box pt={2} />
      {children}
    </Box>
  );
}
