import { Box, Grid, Typography } from "@mui/material";
import { MultiChipsCmp } from "../../components/Chips";
import {
  AchievementsIconCmp,
  EllipsisIconCmp,
  EmployeeEngagementIconSvg,
  TeamBuildingIconSvg,
  TrainingIconCmp,
  WellBeingIconCmp,
} from "../../components/Icons";
import { DropdownCmp, TextFieldWithLabelCmp } from "../../components/Inputs";

export default function OrganisationSettingsCmp({ setUserCompanyProfile, userCompanyProfile }) {
  return (
    <Box>
      <Grid container columnSpacing={8} rowSpacing={4}>
        <Grid item xs={12} md={6}>
          <TextFieldWithLabelCmp
            label="Company Name"
            value={userCompanyProfile.company_name}
            onChange={(e) =>
              setUserCompanyProfile((prev) => ({ ...prev, company_name: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DropdownCmp
            label="Company Size"
            options={companySizeOptions}
            value={userCompanyProfile.company_size}
            onChange={(e) =>
              setUserCompanyProfile((prev) => ({ ...prev, company_size: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldWithLabelCmp
            label="Workspace URL"
            value={userCompanyProfile.company_url}
            onChange={(e) =>
              setUserCompanyProfile((prev) => ({ ...prev, company_url: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldWithLabelCmp
            label="Company Location"
            value={userCompanyProfile.company_location}
            onChange={(e) =>
              setUserCompanyProfile((prev) => ({ ...prev, company_location: e.target.value }))
            }
          />
          {/* <DropdownCmp
            label="Company Location"
            options={companyLocationOptions}
            value={userCompanyProfile.company_location}
            onChange={(e) =>
              setUserCompanyProfile((prev) => ({ ...prev, company_location: e.target.value }))
            }
          /> */}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography fontWeight={500} color="text.light">
            Pick one that describes your business
          </Typography>
          <Box pt={1} />
          <MultiChipsCmp
            // onChange={(sel) => setCompanyBuisnessDesc(sel.toString())}
            onChange={(sel) =>
              setUserCompanyProfile((prev) => ({ ...prev, company_business_desc: sel.toString() }))
            }
            defaultValue={businessDescData
              .filter((el) => el.text === userCompanyProfile.company_business_desc)
              .map((el) => el.id)}
            data={businessDescData}
            xs={12}
            spacing={2}
            typographyVariant="body2"
            maxSelectItems={1}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography fontWeight={500} color="text.light">
            Company Domain
          </Typography>
          <Box pt={1} />
          <MultiChipsCmp
            // onChange={(sel) => setCompanyBuisness(sel.toString())}
            onChange={(sel) =>
              setUserCompanyProfile((prev) => ({ ...prev, company_business: sel.toString() }))
            }
            defaultValue={companyDomainData
              .filter((el) => el.text === userCompanyProfile.company_business)
              .map((el) => el.id)}
            data={companyDomainData}
            height={48}
            xs={12}
            spacing={2}
            typographyVariant="body2"
            maxSelectItems={1}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" alignItems="center" justifyContent="space-between" columnGap={4}>
            <Box flex={1}>
              <Typography fontWeight={500} color="text.light">
                Planned a retreat before?
              </Typography>
              <Box pt={1} />
              <MultiChipsCmp
                // onChange={(sel) => setIsPastRetreat(Boolean(sel))}
                onChange={(sel) =>
                  setUserCompanyProfile((prev) => ({
                    ...prev,
                    is_past_company_retreat: sel[0],
                  }))
                }
                defaultValue={data
                  .filter((el) => el.value == userCompanyProfile.is_past_company_retreat)
                  .map((el) => el.id)}
                data={data}
                spacing={2}
                maxSelectItems={1}
                height={40}
                xs={6}
                sm={4}
              />
            </Box>
            {/* <Box flex={1}>
              <Typography fontWeight={500} color="text.light">
                Upload logo (if any)
              </Typography>
              <Box pt={{ xs: 3.8, sm: 1 }} />
              <Button
                variant="outlined"
                startIcon={<UploadIconCmp />}
                fullWidth
                sx={{ color: "text.light", fontWeight: 500 }}
              >
                Upload
              </Button>
            </Box> */}
          </Box>
        </Grid>
        {/* <Grid item xs={6}>
          <Box flex={1}>
            <Typography fontWeight={500} color="text.light">
              Upload culture deck (if any)
            </Typography>
            <Box pt={1} />
            <Button
              variant="outlined"
              startIcon={<UploadIconCmp />}
              sx={{ color: "text.light", fontWeight: 500 }}
            >
              Upload
            </Button>
          </Box>
        </Grid> */}
      </Grid>
    </Box>
  );
}

const companyLocationOptions = [
  { id: 0, cmp: "Portugal", value: "Portugal" },
  { id: 1, cmp: "Lisbon, Portugal", value: "Lisbon, Portugal" },
  { id: 2, cmp: "Madrid, Spain", value: "Madrid, Spain" },
  { id: 3, cmp: "Gibralter", value: "Gibralter" },
  { id: 4, cmp: "Other", value: "Other" },
];

const companySizeOptions = [
  { id: 0, cmp: "1-10", value: "1-10" },
  { id: 1, cmp: "11-50", value: "11-50" },
  { id: 2, cmp: "51-100", value: "51-100" },
  { id: 3, cmp: "101-200", value: "101-200" },
  { id: 4, cmp: "More than 200", value: "More than 200" },
];

const companyDomainData = [
  { id: 0, text: "E-Commerce", value: "E-Commerce" },
  { id: 1, text: "Finance", value: "Finance" },
  { id: 2, text: "Health Care", value: "Health Care" },
  { id: 3, text: "Education", value: "Education" },
  { id: 4, text: "Travel", value: "Travel" },
  { id: 5, text: "Food & Beverage", value: "Food & Beverage" },
  { id: 6, text: "Deep Tech", value: "Deep Tech" },
  { id: 7, text: "SAAS", value: "SAAS" },
  { id: 8, text: "Climate Change", value: "Climate Change" },
  { id: 9, text: "Consumer Tech", value: "Consumer Tech" },
  { id: 10, text: "Entertainment", value: "Entertainment" },
  { id: 11, text: "Social Media", value: "Social Media" },
];

const businessDescData = [
  {
    id: 1,
    text: "Strategy Offsites",
    icon: TeamBuildingIconSvg,
    value: "Strategy Offsites",
  },
  {
    id: 2,
    text: "Executive Retreats",
    icon: EmployeeEngagementIconSvg,
    value: "Executive Retreats",
  },
  {
    id: 3,
    text: "Celebration Retreats",
    icon: AchievementsIconCmp,
    value: "Celebration Retreats",
  },
  {
    id: 4,
    text: "Training & Development",
    icon: TrainingIconCmp,
    value: "Training & Development",
  },
  {
    id: 5,
    text: "Morale & Wellbeing",
    icon: WellBeingIconCmp,
    value: "Morale & Wellbeing",
  },
  {
    id: 6,
    text: "Other",
    icon: EllipsisIconCmp,
    value: "Other",
  },
];

const data = [
  { id: 0, text: "Yes", value: true },
  { id: 1, text: "No", value: false },
];
